@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&family=Roboto:wght@100;300;400;500;700;900&display=swap);
html {
  scroll-behavior: smooth !important; }

body {
  margin: 0;
  font-family: "Roboto", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  overflow: hidden;
  position: relative; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  color: rgba(255, 0, 221, 0.863);
  font-size: 16px; }

/* Custom Name */

.slightly-bold {
    font-weight: 500 !important;
}

.bold {
    font-weight: 600 !important;
}

.bolder {
    font-weight: 700 !important;
}

.input.black_placeholder::-webkit-input-placeholder {
    color: #000 !important;
}

.input.black_placeholder::placeholder {
    color: #000 !important;
}

a.hover_underline:hover {
    text-decoration: underline !important;
}

/* **************************************************************** */

/* Cursor */
.cursor-pointer {
    cursor: pointer !important;
}

.cursor-default {
    cursor: default !important;
}

.cursor-text {
    cursor: text !important;
}

.cursor-not-allowed {
    cursor: not-allowed !important;
}

/* Color */
.color-primary {
    color: var(--primary-color) !important;
}

.color-bold-red {
    color: #E60000 !important;
}

/* Position */
.position-relative {
    position: relative !important;
}

.position-absolute {
    position: absolute !important;
}

.t-0 {
    top: 0 !important;
}

.b-0 {
    bottom: 0 !important;
}

.r-0 {
    right: 0 !important;
}

.l-0 {
    left: 0 !important;
}

/* Width & Height */
.w-100 {
    width: 100% !important;
}

.h-100 {
    height: 100% !important;
}

.max-w-120 {
    max-width: 120px !important;
}

.min-w-150 {
    min-width: 150px !important;
}

.line-height-1 {
    line-height: 1 !important;
}

.line-height-1-5 {
    line-height: 1.5 !important;
}

.line-height-3 {
    line-height: 3 !important;
}

/* Border & Radius */
.border-none {
    border: none !important;
}

.radius-2 {
    border-radius: 2px !important;
}

.radius-3 {
    border-radius: 3px !important;
}

.radius-5 {
    border-radius: 5px !important;
}

.radius-p-50 {
    border-radius: 50% !important;
}

/* Text */
.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.text-lowercase {
    text-transform: lowercase !important;
}

.text-capitalize {
    text-transform: capitalize !important;
}

/* Font */
.fz-10 {
    font-size: 10px !important;
}

.fz-12 {
    font-size: 12px !important;
}

.fz-15 {
    font-size: 15px !important;
}

.fz-16 {
    font-size: 16px !important;
}

.fz-18 {
    font-size: 18px !important;
}

.fz-20 {
    font-size: 20px !important;
}

.fz-24 {
    font-size: 24px !important;
}

.fz-25 {
    font-size: 25px !important;
}

.fz-30 {
    font-size: 30px !important;
}

/* Display */
.none {
    display: none !important;
}

.block {
    display: block !important;
}

.inline-block {
    display: inline-block !important;
}

.flex {
    display: flex !important;
}

.inline-flex {
    display: inline-flex !important;
}

.align-center {
    align-items: center !important;
}

.align-start {
    align-items: flex-start !important;
}

.align-end {
    align-items: flex-end !important;
}

.justify-center {
    justify-content: center !important;
}

.justify-between {
    justify-content: space-between !important;
}

.justify-around {
    justify-content: space-around !important;
}

.justify-end {
    justify-content: flex-end !important;
}

.justify-start {
    justify-content: flex-start !important;
}

.flex-direct-col {
    flex-direction: column !important;
}

.flex-direct-row {
    flex-direction: row !important;
}

.flex-direct-col-rv {
    flex-direction: column-reverse !important;
}

.flex-direct-row-rv {
    flex-direction: row-reverse !important;
}

.flex-1 {
    flex: 1 1 !important;
}

.flex-2 {
    flex: 2 1 !important;
}

.flex-3 {
    flex: 3 1 !important;
}

/* Float */
.float-unset {
    float: unset !important;
}

.float-left {
    float: left !important;
}

.float-right {
    float: right !important;
}

/* Background */
.bgc-white {
    background-color: var(--white) !important;
}

/* Transform */
.translate-0 {
    -webkit-transform: translate(0) !important;
            transform: translate(0) !important;
}

.translateX-0 {
    -webkit-transform: translateX(0) !important;
            transform: translateX(0) !important;
}

.translateY-0 {
    -webkit-transform: translateY(0) !important;
            transform: translateY(0) !important;
}


/* Margin & Padding */
.m-0 {
    margin: 0 !important;
}

.mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
}

.mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.mx-10 {
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.mx-15 {
    margin-left: 15px !important;
    margin-right: 15px !important;
}

.my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.my-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.my-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.mt-0 {
    margin-top: 0 !important; 
}

.mt-10 {
    margin-top: 10px !important; 
}

.mt-15 {
    margin-top: 15px !important; 
}

.mt-16 {
    margin-top: 16px !important; 
}

.mt-20 {
    margin-top: 20px !important; 
}

.mt-30 {
    margin-top: 30px !important; 
}

.mt-50 {
    margin-top: 50px !important; 
}

.mt-170 {
    margin-top: 170px !important; 
}

.mb-0 {
    margin-bottom: 0 !important; 
}

.mb-10 {
    margin-bottom: 10px !important; 
}

.mb-15 {
    margin-bottom: 15px !important; 
}

.mb-30 {
    margin-bottom: 30px !important; 
}

.mb-50 {
    margin-bottom: 50px !important; 
}

.mr-0 {
    margin-right: 0 !important;
}

.mr-5 {
    margin-right: 5px !important;
}

.mr-10 {
    margin-right: 10px !important;
}

.mr-15 {
    margin-right: 15px !important;
}

.mr-20 {
    margin-right: 20px !important;
}

.mr-25 {
    margin-right: 25px !important;
}

.mr-40 {
    margin-right: 40px !important;
}

.ml-0 {
    margin-left: 0 !important;
}

.ml-10 {
    margin-left: 10px !important;
}

.ml-15 {
    margin-left: 15px !important;
}

.ml-20 {
    margin-left: 20px !important;
}

.ml-55 {
    margin-left: 55px !important;
}

.ml-100 {
    margin-left: 100px !important;
}

.p-0 {
    padding: 0 !important;
}

.p-20 {
    padding: 20px !important;
}

.px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.pt-0 {
    padding-top: 0 !important; 
}

.pt-10 {
    padding-top: 10px !important;
}

.pb-0 {
    padding-bottom: 0 !important; 
}

.pr-0 {
    padding-right: 0 !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.p-10 {
    padding: 10px !important;
}

.p-20 {
    padding: 20px !important;
}

.px-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.rounded {
    border-radius: 5px;
}
.card {
    padding: 5%;
    background-color: bisque;
    margin-bottom: 12px;
    padding: 0px;
    /* background-color: blanchedalmond !important; */
}
.head {
    font-size: 20px;
}
li {
    font-size: 20px;
}
h1 {
    color: blanchedalmond;
}

/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #29d;

  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #29d, 0 0 5px #29d;
  opacity: 1.0;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
          transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 2000;
  top: 50%;
  right: 50%;
}

#nprogress .spinner-icon {
  width: 72px;
  height: 72px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: rgb(3, 83, 129);
  border-left-color: rgb(3, 83, 129);
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
          animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0%   { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}
@keyframes nprogress-spinner {
  0%   { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}


#root,
body,
html {
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative; }

html {
  font-size: 16px; }

body {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.5;
  font-family: "Open Sans", sans-serif; }

div,
a {
  box-sizing: border-box; }

img {
  max-width: 100%; }

h1,
h2,
h3,
h4,
h5,
h6,
.card-title {
  font-weight: 500; }

.layout1,
.layout2,
.MuiPaper-root,
.MuiTableCell-body,
.egret-customizer {
  color: var(--text-body) !important; }

.m-0 {
  margin: 0px !important; }

.mt-0 {
  margin-top: 0px !important; }

.mr-0 {
  margin-right: 0px !important; }

.mb-0 {
  margin-bottom: 0px !important; }

.ml-0 {
  margin-left: 0px !important; }

.mx-0 {
  margin-left: 0px !important;
  margin-right: 0px !important; }

.my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important; }

.p-0 {
  padding: 0px !important; }

.pt-0 {
  padding-top: 0px !important; }

.pr-0 {
  padding-right: 0px !important; }

.pb-0 {
  padding-bottom: 0px !important; }

.pl-0 {
  padding-left: 0px !important; }

.px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important; }

.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important; }

.m-4 {
  margin: 4px !important; }

.mt-4 {
  margin-top: 4px !important; }

.mr-4 {
  margin-right: 4px !important; }

.mb-4 {
  margin-bottom: 4px !important; }

.ml-4 {
  margin-left: 4px !important; }

.mx-4 {
  margin-left: 4px !important;
  margin-right: 4px !important; }

.my-4 {
  margin-top: 4px !important;
  margin-bottom: 4px !important; }

.p-4 {
  padding: 4px !important; }

.pt-4 {
  padding-top: 4px !important; }

.pr-4 {
  padding-right: 4px !important; }

.pb-4 {
  padding-bottom: 4px !important; }

.pl-4 {
  padding-left: 4px !important; }

.px-4 {
  padding-left: 4px !important;
  padding-right: 4px !important; }

.py-4 {
  padding-top: 4px !important;
  padding-bottom: 4px !important; }

.m-8 {
  margin: 8px !important; }

.mt-8 {
  margin-top: 8px !important; }

.mr-8 {
  margin-right: 8px !important; }

.mb-8 {
  margin-bottom: 8px !important; }

.ml-8 {
  margin-left: 8px !important; }

.mx-8 {
  margin-left: 8px !important;
  margin-right: 8px !important; }

.my-8 {
  margin-top: 8px !important;
  margin-bottom: 8px !important; }

.p-8 {
  padding: 8px !important; }

.pt-8 {
  padding-top: 8px !important; }

.pr-8 {
  padding-right: 8px !important; }

.pb-8 {
  padding-bottom: 8px !important; }

.pl-8 {
  padding-left: 8px !important; }

.px-8 {
  padding-left: 8px !important;
  padding-right: 8px !important; }

.py-8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important; }

.m-10 {
  margin: 10px !important; }

.mt-10 {
  margin-top: 10px !important; }

.mr-10 {
  margin-right: 10px !important; }

.mb-10 {
  margin-bottom: 10px !important; }

.ml-10 {
  margin-left: 10px !important; }

.mx-10 {
  margin-left: 10px !important;
  margin-right: 10px !important; }

.my-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important; }

.p-10 {
  padding: 10px !important; }

.pt-10 {
  padding-top: 10px !important; }

.pr-10 {
  padding-right: 10px !important; }

.pb-10 {
  padding-bottom: 10px !important; }

.pl-10 {
  padding-left: 10px !important; }

.px-10 {
  padding-left: 10px !important;
  padding-right: 10px !important; }

.py-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important; }

.m-12 {
  margin: 12px !important; }

.mt-12 {
  margin-top: 12px !important; }

.mr-12 {
  margin-right: 12px !important; }

.mb-12 {
  margin-bottom: 12px !important; }

.ml-12 {
  margin-left: 12px !important; }

.mx-12 {
  margin-left: 12px !important;
  margin-right: 12px !important; }

.my-12 {
  margin-top: 12px !important;
  margin-bottom: 12px !important; }

.p-12 {
  padding: 12px !important; }

.pt-12 {
  padding-top: 12px !important; }

.pr-12 {
  padding-right: 12px !important; }

.pb-12 {
  padding-bottom: 12px !important; }

.pl-12 {
  padding-left: 12px !important; }

.px-12 {
  padding-left: 12px !important;
  padding-right: 12px !important; }

.py-12 {
  padding-top: 12px !important;
  padding-bottom: 12px !important; }

.m-16 {
  margin: 16px !important; }

.mt-16 {
  margin-top: 16px !important; }

.mr-16 {
  margin-right: 16px !important; }

.mb-16 {
  margin-bottom: 16px !important; }

.ml-16 {
  margin-left: 16px !important; }

.mx-16 {
  margin-left: 16px !important;
  margin-right: 16px !important; }

.my-16 {
  margin-top: 16px !important;
  margin-bottom: 16px !important; }

.p-16 {
  padding: 16px !important; }

.pt-16 {
  padding-top: 16px !important; }

.pr-16 {
  padding-right: 16px !important; }

.pb-16 {
  padding-bottom: 16px !important; }

.pl-16 {
  padding-left: 16px !important; }

.px-16 {
  padding-left: 16px !important;
  padding-right: 16px !important; }

.py-16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important; }

.m-20 {
  margin: 20px !important; }

.mt-20 {
  margin-top: 20px !important; }

.mr-20 {
  margin-right: 20px !important; }

.mb-20 {
  margin-bottom: 20px !important; }

.ml-20 {
  margin-left: 20px !important; }

.mx-20 {
  margin-left: 20px !important;
  margin-right: 20px !important; }

.my-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important; }

.p-20 {
  padding: 20px !important; }

.pt-20 {
  padding-top: 20px !important; }

.pr-20 {
  padding-right: 20px !important; }

.pb-20 {
  padding-bottom: 20px !important; }

.pl-20 {
  padding-left: 20px !important; }

.px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important; }

.py-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important; }

.m-24 {
  margin: 24px !important; }

.mt-24 {
  margin-top: 24px !important; }

.mr-24 {
  margin-right: 24px !important; }

.mb-24 {
  margin-bottom: 24px !important; }

.ml-24 {
  margin-left: 24px !important; }

.mx-24 {
  margin-left: 24px !important;
  margin-right: 24px !important; }

.my-24 {
  margin-top: 24px !important;
  margin-bottom: 24px !important; }

.p-24 {
  padding: 24px !important; }

.pt-24 {
  padding-top: 24px !important; }

.pr-24 {
  padding-right: 24px !important; }

.pb-24 {
  padding-bottom: 24px !important; }

.pl-24 {
  padding-left: 24px !important; }

.px-24 {
  padding-left: 24px !important;
  padding-right: 24px !important; }

.py-24 {
  padding-top: 24px !important;
  padding-bottom: 24px !important; }

.m-28 {
  margin: 28px !important; }

.mt-28 {
  margin-top: 28px !important; }

.mr-28 {
  margin-right: 28px !important; }

.mb-28 {
  margin-bottom: 28px !important; }

.ml-28 {
  margin-left: 28px !important; }

.mx-28 {
  margin-left: 28px !important;
  margin-right: 28px !important; }

.my-28 {
  margin-top: 28px !important;
  margin-bottom: 28px !important; }

.p-28 {
  padding: 28px !important; }

.pt-28 {
  padding-top: 28px !important; }

.pr-28 {
  padding-right: 28px !important; }

.pb-28 {
  padding-bottom: 28px !important; }

.pl-28 {
  padding-left: 28px !important; }

.px-28 {
  padding-left: 28px !important;
  padding-right: 28px !important; }

.py-28 {
  padding-top: 28px !important;
  padding-bottom: 28px !important; }

.m-30 {
  margin: 30px !important; }

.mt-30 {
  margin-top: 30px !important; }

.mr-30 {
  margin-right: 30px !important; }

.mb-30 {
  margin-bottom: 30px !important; }

.ml-30 {
  margin-left: 30px !important; }

.mx-30 {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.my-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.p-30 {
  padding: 30px !important; }

.pt-30 {
  padding-top: 30px !important; }

.pr-30 {
  padding-right: 30px !important; }

.pb-30 {
  padding-bottom: 30px !important; }

.pl-30 {
  padding-left: 30px !important; }

.px-30 {
  padding-left: 30px !important;
  padding-right: 30px !important; }

.py-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.m-32 {
  margin: 32px !important; }

.mt-32 {
  margin-top: 32px !important; }

.mr-32 {
  margin-right: 32px !important; }

.mb-32 {
  margin-bottom: 32px !important; }

.ml-32 {
  margin-left: 32px !important; }

.mx-32 {
  margin-left: 32px !important;
  margin-right: 32px !important; }

.my-32 {
  margin-top: 32px !important;
  margin-bottom: 32px !important; }

.p-32 {
  padding: 32px !important; }

.pt-32 {
  padding-top: 32px !important; }

.pr-32 {
  padding-right: 32px !important; }

.pb-32 {
  padding-bottom: 32px !important; }

.pl-32 {
  padding-left: 32px !important; }

.px-32 {
  padding-left: 32px !important;
  padding-right: 32px !important; }

.py-32 {
  padding-top: 32px !important;
  padding-bottom: 32px !important; }

.m-36 {
  margin: 36px !important; }

.mt-36 {
  margin-top: 36px !important; }

.mr-36 {
  margin-right: 36px !important; }

.mb-36 {
  margin-bottom: 36px !important; }

.ml-36 {
  margin-left: 36px !important; }

.mx-36 {
  margin-left: 36px !important;
  margin-right: 36px !important; }

.my-36 {
  margin-top: 36px !important;
  margin-bottom: 36px !important; }

.p-36 {
  padding: 36px !important; }

.pt-36 {
  padding-top: 36px !important; }

.pr-36 {
  padding-right: 36px !important; }

.pb-36 {
  padding-bottom: 36px !important; }

.pl-36 {
  padding-left: 36px !important; }

.px-36 {
  padding-left: 36px !important;
  padding-right: 36px !important; }

.py-36 {
  padding-top: 36px !important;
  padding-bottom: 36px !important; }

.m-40 {
  margin: 40px !important; }

.mt-40 {
  margin-top: 40px !important; }

.mr-40 {
  margin-right: 40px !important; }

.mb-40 {
  margin-bottom: 40px !important; }

.ml-40 {
  margin-left: 40px !important; }

.mx-40 {
  margin-left: 40px !important;
  margin-right: 40px !important; }

.my-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important; }

.p-40 {
  padding: 40px !important; }

.pt-40 {
  padding-top: 40px !important; }

.pr-40 {
  padding-right: 40px !important; }

.pb-40 {
  padding-bottom: 40px !important; }

.pl-40 {
  padding-left: 40px !important; }

.px-40 {
  padding-left: 40px !important;
  padding-right: 40px !important; }

.py-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important; }

.m-44 {
  margin: 44px !important; }

.mt-44 {
  margin-top: 44px !important; }

.mr-44 {
  margin-right: 44px !important; }

.mb-44 {
  margin-bottom: 44px !important; }

.ml-44 {
  margin-left: 44px !important; }

.mx-44 {
  margin-left: 44px !important;
  margin-right: 44px !important; }

.my-44 {
  margin-top: 44px !important;
  margin-bottom: 44px !important; }

.p-44 {
  padding: 44px !important; }

.pt-44 {
  padding-top: 44px !important; }

.pr-44 {
  padding-right: 44px !important; }

.pb-44 {
  padding-bottom: 44px !important; }

.pl-44 {
  padding-left: 44px !important; }

.px-44 {
  padding-left: 44px !important;
  padding-right: 44px !important; }

.py-44 {
  padding-top: 44px !important;
  padding-bottom: 44px !important; }

.m-48 {
  margin: 48px !important; }

.mt-48 {
  margin-top: 48px !important; }

.mr-48 {
  margin-right: 48px !important; }

.mb-48 {
  margin-bottom: 48px !important; }

.ml-48 {
  margin-left: 48px !important; }

.mx-48 {
  margin-left: 48px !important;
  margin-right: 48px !important; }

.my-48 {
  margin-top: 48px !important;
  margin-bottom: 48px !important; }

.p-48 {
  padding: 48px !important; }

.pt-48 {
  padding-top: 48px !important; }

.pr-48 {
  padding-right: 48px !important; }

.pb-48 {
  padding-bottom: 48px !important; }

.pl-48 {
  padding-left: 48px !important; }

.px-48 {
  padding-left: 48px !important;
  padding-right: 48px !important; }

.py-48 {
  padding-top: 48px !important;
  padding-bottom: 48px !important; }

.m-60 {
  margin: 60px !important; }

.mt-60 {
  margin-top: 60px !important; }

.mr-60 {
  margin-right: 60px !important; }

.mb-60 {
  margin-bottom: 60px !important; }

.ml-60 {
  margin-left: 60px !important; }

.mx-60 {
  margin-left: 60px !important;
  margin-right: 60px !important; }

.my-60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important; }

.p-60 {
  padding: 60px !important; }

.pt-60 {
  padding-top: 60px !important; }

.pr-60 {
  padding-right: 60px !important; }

.pb-60 {
  padding-bottom: 60px !important; }

.pl-60 {
  padding-left: 60px !important; }

.px-60 {
  padding-left: 60px !important;
  padding-right: 60px !important; }

.py-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important; }

.m-64 {
  margin: 64px !important; }

.mt-64 {
  margin-top: 64px !important; }

.mr-64 {
  margin-right: 64px !important; }

.mb-64 {
  margin-bottom: 64px !important; }

.ml-64 {
  margin-left: 64px !important; }

.mx-64 {
  margin-left: 64px !important;
  margin-right: 64px !important; }

.my-64 {
  margin-top: 64px !important;
  margin-bottom: 64px !important; }

.p-64 {
  padding: 64px !important; }

.pt-64 {
  padding-top: 64px !important; }

.pr-64 {
  padding-right: 64px !important; }

.pb-64 {
  padding-bottom: 64px !important; }

.pl-64 {
  padding-left: 64px !important; }

.px-64 {
  padding-left: 64px !important;
  padding-right: 64px !important; }

.py-64 {
  padding-top: 64px !important;
  padding-bottom: 64px !important; }

.m-80 {
  margin: 80px !important; }

.mt-80 {
  margin-top: 80px !important; }

.mr-80 {
  margin-right: 80px !important; }

.mb-80 {
  margin-bottom: 80px !important; }

.ml-80 {
  margin-left: 80px !important; }

.mx-80 {
  margin-left: 80px !important;
  margin-right: 80px !important; }

.my-80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important; }

.p-80 {
  padding: 80px !important; }

.pt-80 {
  padding-top: 80px !important; }

.pr-80 {
  padding-right: 80px !important; }

.pb-80 {
  padding-bottom: 80px !important; }

.pl-80 {
  padding-left: 80px !important; }

.px-80 {
  padding-left: 80px !important;
  padding-right: 80px !important; }

.py-80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important; }

.m-92 {
  margin: 92px !important; }

.mt-92 {
  margin-top: 92px !important; }

.mr-92 {
  margin-right: 92px !important; }

.mb-92 {
  margin-bottom: 92px !important; }

.ml-92 {
  margin-left: 92px !important; }

.mx-92 {
  margin-left: 92px !important;
  margin-right: 92px !important; }

.my-92 {
  margin-top: 92px !important;
  margin-bottom: 92px !important; }

.p-92 {
  padding: 92px !important; }

.pt-92 {
  padding-top: 92px !important; }

.pr-92 {
  padding-right: 92px !important; }

.pb-92 {
  padding-bottom: 92px !important; }

.pl-92 {
  padding-left: 92px !important; }

.px-92 {
  padding-left: 92px !important;
  padding-right: 92px !important; }

.py-92 {
  padding-top: 92px !important;
  padding-bottom: 92px !important; }

.m-120 {
  margin: 120px !important; }

.mt-120 {
  margin-top: 120px !important; }

.mr-120 {
  margin-right: 120px !important; }

.mb-120 {
  margin-bottom: 120px !important; }

.ml-120 {
  margin-left: 120px !important; }

.mx-120 {
  margin-left: 120px !important;
  margin-right: 120px !important; }

.my-120 {
  margin-top: 120px !important;
  margin-bottom: 120px !important; }

.p-120 {
  padding: 120px !important; }

.pt-120 {
  padding-top: 120px !important; }

.pr-120 {
  padding-right: 120px !important; }

.pb-120 {
  padding-bottom: 120px !important; }

.pl-120 {
  padding-left: 120px !important; }

.px-120 {
  padding-left: 120px !important;
  padding-right: 120px !important; }

.py-120 {
  padding-top: 120px !important;
  padding-bottom: 120px !important; }

@media screen and (max-width: 767px) {
  .pb-30 {
    padding-bottom: 16px !important; } }

.pb-2 {
  padding-bottom: 2px; }

.pb-3 {
  padding-bottom: 3px; }

.px-80 {
  padding-right: 80px;
  padding-left: 80px; }
  @media screen and (max-width: 460px) {
    .px-80 {
      padding-right: 16px;
      padding-left: 16px; } }

.py-80 {
  padding-top: 80px;
  padding-bottom: 80px; }

.py-2 {
  padding-top: 2px;
  padding-bottom: 2px; }

.p-sm-30 {
  padding: 30px; }
  @media screen and (max-width: 767px) {
    .p-sm-30 {
      padding: 16px; } }

.px-sm-30 {
  padding: 0px 30px; }
  @media screen and (max-width: 767px) {
    .px-sm-30 {
      padding: 0px 16px; } }

.pr-sm-30 {
  padding-right: 30px !important; }
  @media screen and (max-width: 767px) {
    .pr-sm-30 {
      padding-right: 16px !important; } }

.p-sm-24 {
  padding: 24px !important; }
  @media screen and (max-width: 767px) {
    .p-sm-24 {
      padding: 16px !important; } }

.px-sm-24 {
  padding: 0px 24px !important; }
  @media screen and (max-width: 767px) {
    .px-sm-24 {
      padding: 0px 16px !important; } }

.pt-sm-24 {
  padding-top: 24px !important; }
  @media screen and (max-width: 767px) {
    .pt-sm-24 {
      padding-top: 16px !important; } }

.pl-sm-24 {
  padding-left: 24px !important; }
  @media screen and (max-width: 767px) {
    .pl-sm-24 {
      padding: 12px !important; } }

.m-auto {
  margin: auto !important; }

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mb-38 {
  margin-bottom: 38px; }

.mb-14 {
  margin-bottom: 14px !important; }

.mb-1 {
  margin-bottom: 1px; }

.mb-2 {
  margin-bottom: 2px; }

.ml--12 {
  margin-left: -12px; }

.m-sm-30 {
  margin: 30px; }
  @media screen and (max-width: 767px) {
    .m-sm-30 {
      margin: 16px; } }

.mb-sm-30 {
  margin-bottom: 30px; }
  @media screen and (max-width: 767px) {
    .mb-sm-30 {
      margin-bottom: 16px; } }

.mx-sm-30 {
  margin-right: 30px;
  margin-left: 30px; }
  @media screen and (max-width: 767px) {
    .mx-sm-30 {
      margin-right: 16px;
      margin-left: 16px; } }

.w-50 {
  width: 50%; }

.w-80 {
  width: 80%; }

.w-100 {
  width: 100%; }

.w-220 {
  width: 220px; }

.h-100 {
  height: 100% !important; }

.h-150 {
  height: 150px !important; }

.h-320 {
  height: 320px; }

.h-100vh {
  height: 100vh; }

.h-100vh-80 {
  height: calc(100vh - 80px); }

.h-500 {
  height: 500px !important; }

.h-700 {
  height: 700px; }

.h-800 {
  height: 800px; }

.h-48 {
  height: 48px; }

.h-70 {
  height: 70px; }

.h-32 {
  height: 32px; }

.h-56 {
  height: 56px; }

.size-36 {
  height: 36px !important;
  width: 36px !important; }

.size-24 {
  height: 24px !important;
  width: 24px !important; }

.align-bottom {
  vertical-align: bottom !important; }

.bg-primary {
  background: var(--primary) !important; }

.bg-secondary {
  background: var(--secondary) !important; }

.bg-error {
  background: var(--error) !important; }

.bg-white {
  background: #ffffff !important;
  color: inherit; }

.bg-default {
  background: var(--bg-default) !important; }

.bg-paper {
  background: var(--bg-paper); }

.bg-light-gray {
  background: rgba(0, 0, 0, 0.08) !important; }

.bg-dark {
  background: var(--text-black);
  color: #ffffff; }

.bg-light-dark {
  background: #212121;
  color: white; }

.bg-error {
  background: var(--error) !important;
  color: white !important; }

[class^="bg-light-"],
[class*=" bg-light-"] {
  position: relative;
  z-index: 0; }
  [class^="bg-light-"]::after,
  [class*=" bg-light-"]::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.15;
    z-index: -1;
    border-radius: 8px; }

.bg-light-primary::after {
  background: var(--primary); }

.bg-light-secondary {
  position: relative;
  z-index: 0; }
  .bg-light-secondary::after {
    background: var(--secondary); }

.bg-light-error {
  position: relative;
  z-index: 0; }
  .bg-light-error::after {
    background: var(--error); }

.bg-green {
  background: #08ad6c !important; }

.bg-light-green {
  background: rgba(8, 173, 108, 0.5) !important; }

.bg-transperant {
  background: transparent !important; }

.text-white {
  color: #ffffff !important; }

.text-muted-white {
  color: rgba(255, 255, 255, 0.54) !important; }

.text-light-white {
  color: rgba(255, 255, 255, 0.54) !important; }

.text-muted {
  color: var(--text-muted) !important; }

.text-hint {
  color: var(--text-hint) !important; }

.text-gray {
  color: rgba(0, 0, 0, 0.74) !important; }

.text-brand {
  color: var(--primary) !important; }

.text-primary {
  color: var(--primary) !important; }

.text-secondary {
  color: var(--secondary) !important; }

.text-green {
  color: #08ad6c !important; }

.text-error {
  color: var(--error) !important; }

.gray-on-hover {
  transition: background 250ms ease; }
  .gray-on-hover:hover {
    background: rgba(0, 0, 0, 0.054); }

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 0.5rem;
  line-height: 1.1;
  color: inherit; }

.h1,
h1 {
  font-size: 2rem; }

.h2,
h2 {
  font-size: 1.75rem; }

.h3,
h3 {
  font-size: 1.5rem; }

.h4,
h4 {
  font-size: 1.25rem; }

.h5,
h5 {
  font-size: 1rem; }

.h6,
h6 {
  font-size: 0.875rem; }

a {
  text-decoration: none;
  color: inherit; }

.caption {
  font: var(--font-caption); }

.subheading-1 {
  font: var(--font-subheading-1); }

.subheading-2 {
  font: var(--font-subheading-2); }

.heading {
  font: var(--font-heading); }

.title {
  font: var(--font-title); }

.display-1 {
  font: var(--font-display-1); }

.display-2 {
  font: var(--font-display-2); }

.display-3 {
  font: var(--font-display-3); }

.display-4 {
  font: var(--font-display-4); }

.capitalize {
  text-transform: capitalize !important; }

.uppercase {
  text-transform: uppercase !important; }

.lowercase {
  text-transform: lowercase !important; }

.font-weight-normal {
  font-weight: normal !important; }

.font-weight-300,
.font-weight-light {
  font-weight: 300 !important; }

.font-weight-500,
.font-weight-semi-bold {
  font-weight: 500 !important; }

.font-weight-600,
.font-weight-bold {
  font-weight: 600 !important; }

.font-size-13 {
  font-size: 13px; }

.font-size-14 {
  font-size: 14px; }

.font-size-16 {
  font-size: 16px; }

.font-size-18 {
  font-size: 18px; }

.font-size-20 {
  font-size: 20px; }

.font-size-22 {
  font-size: 22px; }

.font-size-24 {
  font-size: 24px; }

.font-size-30 {
  font-size: 30px !important; }

.font-size-32 {
  font-size: 32px; }

.text-small {
  font-size: 0.8125rem !important; }

.white-space-pre-line {
  white-space: pre-wrap;
  word-break: break-word; }

.white-space-pre {
  white-space: pre; }

.fade-in {
  -webkit-animation: fade-in 1s cubic-bezier(0.17, 0.67, 0.83, 0.67);
          animation: fade-in 1s cubic-bezier(0.17, 0.67, 0.83, 0.67); }

@keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.spin {
  -webkit-animation: spin 3s infinite linear;
          animation: spin 3s infinite linear; }

.display-none {
  display: none; }

.display-block {
  display: block; }

.flex {
  display: flex; }

.flex-column {
  display: flex !important;
  flex-direction: column; }

.flex-wrap {
  flex-wrap: wrap; }

.flex-start {
  justify-content: flex-start !important; }

.flex-center {
  justify-content: center; }

.flex-end {
  justify-content: flex-end; }

.flex-space-between {
  justify-content: space-between !important; }

.flex-space-around {
  justify-content: space-around; }

.flex-middle {
  align-items: center; }

.flex-top {
  align-items: flex-start; }

.flex-stretch {
  align-items: stretch; }

.flex-1 {
  flex: 1 1; }

.flex-2 {
  flex: 2 1; }

.flex-grow-1 {
  flex-grow: 1; }

.flex-grow-2 {
  flex-grow: 2; }

.flex-grow-3 {
  flex-grow: 3; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden; }

.scroll-y {
  overflow-x: hidden;
  overflow-y: scroll; }

.position-relative {
  position: relative; }

.position-bottom {
  position: absolute;
  bottom: 0; }

.text-center {
  text-align: center; }

.text-middle {
  vertical-align: middle; }

.text-align-right {
  text-align: right; }

.text-align-left {
  text-align: left; }

.x-center {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }

.y-center {
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.cursor-pointer {
  cursor: pointer; }

.cursor-move {
  cursor: move; }

.border-radius-0 {
  border-radius: 0px !important;
  overflow: hidden; }

.border-radius-4 {
  border-radius: 4px !important;
  overflow: hidden; }

.border-radius-8 {
  border-radius: 8px !important;
  overflow: hidden; }

.border-radius-circle {
  border-radius: 50% !important;
  overflow: hidden; }

.border-none {
  border: none !important; }

.elevation-z0 {
  box-shadow: var(--elevation-z0); }

.elevation-z1 {
  box-shadow: var(--elevation-z1); }

.elevation-z2 {
  box-shadow: var(--elevation-z2); }

.elevation-z3 {
  box-shadow: var(--elevation-z3); }

.elevation-z4 {
  box-shadow: var(--elevation-z4); }

.elevation-z5 {
  box-shadow: var(--elevation-z5); }

.elevation-z6 {
  box-shadow: var(--elevation-z6); }

.elevation-z7 {
  box-shadow: var(--elevation-z7); }

.elevation-z8 {
  box-shadow: var(--elevation-z8); }

.elevation-z9 {
  box-shadow: var(--elevation-z9); }

.elevation-z10 {
  box-shadow: var(--elevation-z10); }

.elevation-z11 {
  box-shadow: var(--elevation-z11); }

.elevation-z12 {
  box-shadow: var(--elevation-z12); }

.elevation-z13 {
  box-shadow: var(--elevation-z13); }

.elevation-z14 {
  box-shadow: var(--elevation-z14); }

.elevation-z15 {
  box-shadow: var(--elevation-z15); }

.elevation-z16 {
  box-shadow: var(--elevation-z16); }

.elevation-z17 {
  box-shadow: var(--elevation-z17); }

.elevation-z18 {
  box-shadow: var(--elevation-z18); }

.elevation-z19 {
  box-shadow: var(--elevation-z19); }

.elevation-z20 {
  box-shadow: var(--elevation-z20); }

.elevation-z21 {
  box-shadow: var(--elevation-z21); }

.elevation-z22 {
  box-shadow: var(--elevation-z22); }

.elevation-z23 {
  box-shadow: var(--elevation-z23); }

.elevation-z24 {
  box-shadow: var(--elevation-z24); }

.bg-dotted {
  background: url("/assets/images/dots.png"), linear-gradient(90deg, #7467ef -19.83%, #ada5f6 189.85%);
  background-repeat: no-repeat;
  background-size: 100%; }

.circular-image-small {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  overflow: hidden; }

.card {
  transition: all 0.3s ease; }
  .card:hover {
    box-shadow: var(--elevation-z12); }

.card-title {
  font-size: 1rem;
  text-transform: capitalize;
  font-weight: 500; }

.card-subtitle {
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.54); }
  .theme-dark .card-subtitle {
    color: rgba(255, 255, 255, 0.54); }

@media screen and (max-width: 767px) {
  .hide-on-mobile {
    display: none !important; } }

.VictoryContainer svg {
  height: 100% !important; }

.box-shadow-none {
  box-shadow: none !important; }

.circle-44 {
  height: 44px !important;
  width: 44px !important; }

.circle-32 {
  height: 32px !important;
  min-height: 32px !important;
  width: 32px !important; }
  .circle-32 .MuiFab-root {
    min-height: 32px !important; }
  .circle-32 .MuiIcon-root {
    font-size: 13px !important; }

.show-on-mobile {
  display: none !important; }
  @media screen and (max-width: 767px) {
    .show-on-mobile {
      display: inherit !important; } }

.hide-on-mobile {
  display: inherit; }
  @media screen and (max-width: 767px) {
    .hide-on-mobile {
      display: none; } }

.invisible-on-pc {
  visibility: hidden; }
  @media screen and (max-width: 767px) {
    .invisible-on-pc {
      visibility: visible; } }

.highlight-js pre {
  white-space: pre-line; }

.react-vis-magic-css-import-rule {
  display: inherit; }

.rv-treemap {
  font-size: 12px;
  position: relative; }

.rv-treemap__leaf {
  overflow: hidden;
  position: absolute; }

.rv-treemap__leaf--circle {
  align-items: center;
  border-radius: 100%;
  display: flex;
  justify-content: center; }

.rv-treemap__leaf__content {
  overflow: hidden;
  padding: 10px;
  text-overflow: ellipsis; }

.rv-xy-plot {
  color: #c3c3c3;
  position: relative; }

.rv-xy-plot canvas {
  pointer-events: none; }

.rv-xy-plot .rv-xy-canvas {
  pointer-events: none;
  position: absolute; }

.rv-xy-plot__inner {
  display: block; }

.rv-xy-plot__axis__line {
  fill: none;
  stroke-width: 2px;
  stroke: #e6e6e9; }

.rv-xy-plot__axis__tick__line {
  stroke: #e6e6e9; }

.rv-xy-plot__axis__tick__text {
  fill: #6b6b76;
  font-size: 11px; }

.rv-xy-plot__axis__title text {
  fill: #6b6b76;
  font-size: 11px; }

.rv-xy-plot__grid-lines__line {
  stroke: #e6e6e9; }

.rv-xy-plot__circular-grid-lines__line {
  fill-opacity: 0;
  stroke: #e6e6e9; }

.rv-xy-plot__series, .rv-xy-plot__series path {
  pointer-events: all; }

.rv-xy-plot__series--line {
  fill: none;
  stroke: #000;
  stroke-width: 2px; }

.rv-crosshair {
  position: absolute;
  font-size: 11px;
  pointer-events: none; }

.rv-crosshair__line {
  background: #47d3d9;
  width: 1px; }

.rv-crosshair__inner {
  position: absolute;
  text-align: left;
  top: 0; }

.rv-crosshair__inner__content {
  border-radius: 4px;
  background: #3a3a48;
  color: #fff;
  font-size: 12px;
  padding: 7px 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); }

.rv-crosshair__inner--left {
  right: 4px; }

.rv-crosshair__inner--right {
  left: 4px; }

.rv-crosshair__title {
  font-weight: bold;
  white-space: nowrap; }

.rv-crosshair__item {
  white-space: nowrap; }

.rv-hint {
  position: absolute;
  pointer-events: none; }

.rv-hint__content {
  border-radius: 4px;
  padding: 7px 10px;
  font-size: 12px;
  background: #3a3a48;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  color: #fff;
  text-align: left;
  white-space: nowrap; }

.rv-discrete-color-legend {
  box-sizing: border-box;
  overflow-y: auto;
  font-size: 12px; }

.rv-discrete-color-legend.horizontal {
  white-space: nowrap; }

.rv-discrete-color-legend-item {
  color: #3a3a48;
  border-radius: 1px;
  padding: 9px 10px; }

.rv-discrete-color-legend-item.horizontal {
  display: inline-block; }

.rv-discrete-color-legend-item.horizontal .rv-discrete-color-legend-item__title {
  margin-left: 0;
  display: block; }

.rv-discrete-color-legend-item__color {
  display: inline-block;
  vertical-align: middle;
  overflow: visible; }

.rv-discrete-color-legend-item__color__path {
  stroke: #dcdcdc;
  stroke-width: 2px; }

.rv-discrete-color-legend-item__title {
  margin-left: 10px; }

.rv-discrete-color-legend-item.disabled {
  color: #b8b8b8; }

.rv-discrete-color-legend-item.clickable {
  cursor: pointer; }

.rv-discrete-color-legend-item.clickable:hover {
  background: #f9f9f9; }

.rv-search-wrapper {
  display: flex;
  flex-direction: column; }

.rv-search-wrapper__form {
  flex: 0 1; }

.rv-search-wrapper__form__input {
  width: 100%;
  color: #a6a6a5;
  border: 1px solid #e5e5e4;
  padding: 7px 10px;
  font-size: 12px;
  box-sizing: border-box;
  border-radius: 2px;
  margin: 0 0 9px;
  outline: 0; }

.rv-search-wrapper__contents {
  flex: 1 1;
  overflow: auto; }

.rv-continuous-color-legend {
  font-size: 12px; }

.rv-continuous-color-legend .rv-gradient {
  height: 4px;
  border-radius: 2px;
  margin-bottom: 5px; }

.rv-continuous-size-legend {
  font-size: 12px; }

.rv-continuous-size-legend .rv-bubbles {
  text-align: justify;
  overflow: hidden;
  margin-bottom: 5px;
  width: 100%; }

.rv-continuous-size-legend .rv-bubble {
  background: #d8d9dc;
  display: inline-block;
  vertical-align: bottom; }

.rv-continuous-size-legend .rv-spacer {
  display: inline-block;
  font-size: 0;
  line-height: 0;
  width: 100%; }

.rv-legend-titles {
  height: 16px;
  position: relative; }

.rv-legend-titles__left, .rv-legend-titles__right, .rv-legend-titles__center {
  position: absolute;
  white-space: nowrap;
  overflow: hidden; }

.rv-legend-titles__center {
  display: block;
  text-align: center;
  width: 100%; }

.rv-legend-titles__right {
  right: 0; }

.rv-radial-chart .rv-xy-plot__series--label {
  pointer-events: none; }

.egret-customizer {
  display: flex;
  flex-direction: column;
  width: 320px;
  position: fixed;
  right: 0;
  box-shadow: var(--elevation-z12);
  z-index: 50;
  top: 0;
  height: 100vh; }
  .egret-customizer .customizer-close {
    position: absolute;
    right: 8px;
    top: 8px; }
  .egret-customizer .layout-boxes {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px; }
    .egret-customizer .layout-boxes .layout-box {
      width: calc(50% - 16px);
      margin: 12px 8px;
      cursor: pointer; }
      .egret-customizer .layout-boxes .layout-box > div {
        overflow: hidden;
        display: flex;
        position: relative;
        height: 76px;
        width: 100%; }
        .egret-customizer .layout-boxes .layout-box > div img {
          position: absolute;
          top: 0;
          left: 0; }
  .egret-customizer .colors {
    display: flex;
    flex-wrap: wrap; }
    .egret-customizer .colors .color {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 40px;
      margin-top: 4px;
      margin-right: 12px;
      margin-bottom: 12px;
      cursor: pointer;
      border-radius: 4px;
      overflow: hidden;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }
      .egret-customizer .colors .color .light, .egret-customizer .colors .color .dark {
        position: absolute;
        border: 12px solid transparent;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
        bottom: -12px;
        left: -12px;
        border-radius: 50%; }
      .egret-customizer .colors .color .light {
        border-top-color: rgba(215, 215, 215, 0.6); }
      .egret-customizer .colors .color .dark {
        border-top-color: rgba(0, 0, 0, 0.5); }

.loader-bounce {
  height: 100vh !important;
  width: 100%;
  display: flex;
  align-items: center; }

.spinner {
  width: 40px;
  height: 40px;
  position: relative;
  margin: auto; }

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out; }

.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s; }

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0); }
  50% {
    -webkit-transform: scale(1); } }

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0); }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1); } }

.egret-sidenav-container {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 100%; }
  .egret-sidenav-container .egret-sidenav {
    position: relative;
    transition: width 250ms ease;
    overflow: hidden;
    z-index: 91; }
    @media screen and (max-width: 767px) {
      .egret-sidenav-container .egret-sidenav {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0; } }
  .egret-sidenav-container .egret-sidenav-content {
    position: relative;
    flex: 1 1;
    height: 100%; }
  .egret-sidenav-container .egret-sidenav-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.74);
    z-index: 90; }

.egret-search-box {
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 9; }
  .egret-search-box .search-box {
    outline: none;
    border: none;
    font-size: 1rem;
    height: calc(100% - 5px); }

.toolbar-menu-wrap {
  position: relative; }
  @media screen and (max-width: 959px) {
    .toolbar-menu-wrap .menu-area {
      position: fixed;
      background: #1a2038;
      height: 60px;
      width: 100%;
      left: 0;
      z-index: -10;
      opacity: 0;
      display: none;
      transition: all 0.15s ease;
      justify-content: flex-end; } }
  .toolbar-menu-wrap.open .menu-area {
    z-index: 9;
    opacity: 1;
    display: flex; }

.notification {
  width: 230px; }
  .notification .notification__topbar {
    height: 64px;
    box-shadow: var(--elevation-z6); }
  .notification .notification__card:hover .delete-button {
    cursor: pointer;
    display: unset;
    right: 0;
    margin-top: 6px;
    top: 0;
    z-index: 2; }
  .notification .notification__card:hover .card__topbar__time {
    display: none; }
  .notification .notification__card .delete-button {
    display: none;
    position: absolute;
    right: 0;
    margin-top: 9px; }
  .notification .notification__card .card__topbar__button {
    height: 24px;
    width: 24px;
    border-radius: 15px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.9; }

.rectangle-box {
  height: 40px;
  width: 40px;
  min-width: 40px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  overflow: hidden; }
  .rectangle-box .MuiIcon-root {
    font-size: 18px; }

.mini-cart {
  width: 230px; }
  .mini-cart .cart__topbar {
    height: 64px;
    box-shadow: var(--elevation-z6); }
  .mini-cart .mini-cart__item {
    transition: background 300ms ease; }
    .mini-cart .mini-cart__item:hover {
      background: rgba(0, 0, 0, 0.08); }
    .mini-cart .mini-cart__item img {
      width: 80px; }

.layout-full .container {
  padding-left: 30px;
  padding-right: 30px; }

.layout-contained .container, .layout-boxed .container {
  padding-left: 30px;
  padding-right: 30px; }

.layout-contained .container {
  max-width: 1200px;
  margin: auto;
  width: 100%; }
  @media screen and (max-width: 767px) {
    .layout-contained .container {
      max-width: 100%; } }

.layout-boxed {
  max-width: 1200px;
  margin: auto;
  box-shadow: var(--elevation-z12);
  background: #ffffff; }
  @media screen and (max-width: 767px) {
    .layout-boxed {
      max-width: 100%;
      box-shadow: none; } }

.sidenav {
  position: fixed;
  top: 0;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  overflow: hidden;
  z-index: 99;
  box-shadow: var(--elevation-z8);
  width: 230px; }
  .sidenav .sidenav__hold {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    z-index: 3; }
    .sidenav .sidenav__hold .scrollable {
      padding-bottom: 120px; }
    .sidenav .sidenav__hold::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: -1; }
    .sidenav .sidenav__hold .brand-area {
      width: 230px;
      padding: 13px 18px; }
      .sidenav .sidenav__hold .brand-area .brand img {
        height: 24px;
        margin-right: 12px; }
      .sidenav .sidenav__hold .brand-area .brand .brand__text {
        font-weight: bold;
        font-size: 1.125rem; }
    .sidenav .sidenav__hold .sidenav__user {
      width: 230px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 1.5rem 1rem 1rem; }
      .sidenav .sidenav__hold .sidenav__user .username-photo {
        display: flex;
        flex-direction: column;
        align-items: center; }
      .sidenav .sidenav__hold .sidenav__user img {
        height: 4rem;
        width: 4rem;
        border-radius: 50%;
        margin-bottom: 8px; }
      .sidenav .sidenav__hold .sidenav__user .username {
        font-weight: 300; }
        .sidenav .sidenav__hold .sidenav__user .username .material-icons {
          font-size: 13px; }
      .sidenav .sidenav__hold .sidenav__user .user__menu {
        display: flex;
        justify-content: center;
        padding: 1rem 0; }

.sidenav-mobile .sidenav {
  width: 230px; }

.sidenav-mobile .sidenav__overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.54);
  z-index: -1; }

.navigation button.nav-item {
  padding: 0 20px; }

.navigation .nav-item {
  display: flex;
  height: 48px;
  justify-content: space-between; }
  .navigation .nav-item button {
    padding: 0 20px; }
  .navigation .nav-item:hover {
    background: rgba(0, 0, 0, 0.2);
    border-left: 4px solid #1ecf50; }
  .navigation .nav-item.active {
    border-left: 4px solid #1ecf50;
    background: rgba(0, 0, 0, 0.2); }
  .navigation .nav-item > button {
    justify-content: flex-start; }
  .navigation .nav-item .item-icon {
    font-size: 18px; }
  .navigation .nav-item .item-text {
    font-size: 0.875rem; }
  .navigation .nav-item .icon-text {
    font-size: 0.875rem;
    display: inline-block;
    height: 21px;
    width: 21px;
    line-height: 21px; }
  .navigation .nav-item .item-arrow span {
    font-size: 18px; }

.navigation .submenu {
  position: relative;
  background: rgba(0, 0, 0, 0.12); }
  .navigation .submenu:before, .navigation .submenu:after {
    content: "";
    left: 0;
    position: absolute;
    width: 100%;
    height: 2px;
    z-index: 3; }
  .navigation .submenu:before {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.1), transparent);
    top: 0; }
  .navigation .submenu:after {
    background: linear-gradient(-180deg, rgba(0, 0, 0, 0.06), transparent);
    bottom: 0; }
  .navigation .submenu .nav-item {
    height: 44px; }

.badge {
  height: 20px;
  padding: 0 8px;
  margin: 0 4px 0 auto;
  border-radius: 12px;
  line-height: 20px;
  font-weight: 500; }

.home__sidenav {
  width: 70vw;
  font-size: 15px;
  padding: 0px 30px 0px 30px;
  background-color: var(--bg-blue);
  color: #ffffff; }
  .home__sidenav .language-select {
    border: 1px solid #ffffff;
    border-radius: 5px;
    padding: 0 10px;
    margin: 10px 0; }
  .home__sidenav .color-white #select-language {
    color: #ffffff !important; }
  .home__sidenav .color-white svg {
    color: #ffffff !important; }
  .home__sidenav .home__sidenav-sign {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 20px; }
    .home__sidenav .home__sidenav-sign .home__sidenav-sign-wrap {
      display: flex;
      align-items: center; }
      .home__sidenav .home__sidenav-sign .home__sidenav-sign-wrap li {
        padding-left: 0;
        padding-right: 0; }
      .home__sidenav .home__sidenav-sign .home__sidenav-sign-wrap .seperate {
        margin: 0;
        border-right: 1px solid #ffffff;
        height: 40px;
        margin: 0 16px; }
  .home__sidenav .home__sidenav-list {
    border-top: 1px solid #ffffff;
    padding: 20px 0; }
    .home__sidenav .home__sidenav-list ul {
      list-style: none;
      margin: 0;
      padding: 0; }
      .home__sidenav .home__sidenav-list ul li {
        font-size: 16px; }
        .home__sidenav .home__sidenav-list ul li a {
          padding: 16px 0;
          display: block; }
  .home__sidenav .home__sidenav-contact {
    display: flex;
    align-items: center;
    margin-top: 20px; }
    .home__sidenav .home__sidenav-contact a {
      display: inline-block;
      margin-right: 20px; }
      .home__sidenav .home__sidenav-contact a img {
        display: block; }
  .home__sidenav .social_network_navbar {
    display: flex;
    list-style: none;
    border-top: 1px solid #ffffff;
    padding-top: 20px;
    padding-left: 0px; }

.sidenav,
.topbar,
.content-wrap {
  transition: all 0.3s ease; }

.sidenav .scrollable {
  width: 230px; }

.layout1 {
  height: 100%; }
  .layout1 .tab-container {
    max-width: 100vw !important; }
  .layout1 .content-wrap {
    flex: 1 1 auto;
    display: flex;
    overflow: hidden;
    position: relative;
    flex-direction: column;
    height: 100%; }
    .layout1 .content-wrap .topbar {
      top: 0;
      z-index: 96;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.95) 44%, rgba(247, 247, 247, 0.4) 50%, rgba(255, 255, 255, 0)); }
      .layout1 .content-wrap .topbar .topbar-hold {
        position: relative;
        box-shadow: var(--elevation-z8);
        height: 64px;
        border-radius: inherit;
        padding: 0 1rem; }
    .layout1 .content-wrap .scrollable-content {
      display: flex;
      flex-direction: column;
      position: relative;
      flex: 1 1;
      overflow-y: auto;
      overflow-x: hidden; }
      .layout1 .content-wrap .scrollable-content .content {
        position: relative; }
  .layout1.sidenav-full .sidenav {
    left: 0; }
  .layout1.sidenav-full .content-wrap {
    width: calc(100% - 230px);
    vertical-align: top;
    margin-left: 230px; }
  .layout1.sidenav-compact .sidenav {
    width: 60px;
    overflow: hidden; }
    .layout1.sidenav-compact .sidenav .brand-area .brand__text {
      display: none; }
    .layout1.sidenav-compact .sidenav .scrollable .sidenav__user .username-photo {
      align-items: flex-start; }
    .layout1.sidenav-compact .sidenav .scrollable .sidenav__user img {
      height: 1.8rem;
      width: 1.8rem; }
    .layout1.sidenav-compact .sidenav .scrollable .sidenav__user .username,
    .layout1.sidenav-compact .sidenav .scrollable .sidenav__user .user__menu {
      display: none; }
    .layout1.sidenav-compact .sidenav .scrollable .navigation .nav-item {
      padding: 0;
      width: 48px;
      overflow: hidden;
      justify-content: center;
      margin-left: 6px;
      border-radius: 2px; }
      .layout1.sidenav-compact .sidenav .scrollable .navigation .nav-item .item-text,
      .layout1.sidenav-compact .sidenav .scrollable .navigation .nav-item .item-arrow,
      .layout1.sidenav-compact .sidenav .scrollable .navigation .nav-item .badge {
        display: none; }
      .layout1.sidenav-compact .sidenav .scrollable .navigation .nav-item > button {
        justify-content: center; }
    .layout1.sidenav-compact .sidenav:hover {
      width: 230px; }
      .layout1.sidenav-compact .sidenav:hover .brand-area .brand__text {
        display: inline-block; }
      .layout1.sidenav-compact .sidenav:hover .scrollable {
        width: 230px; }
        .layout1.sidenav-compact .sidenav:hover .scrollable .sidenav__user .username-photo {
          align-items: center; }
        .layout1.sidenav-compact .sidenav:hover .scrollable .sidenav__user img {
          height: 4rem;
          width: 4rem; }
        .layout1.sidenav-compact .sidenav:hover .scrollable .sidenav__user .username,
        .layout1.sidenav-compact .sidenav:hover .scrollable .sidenav__user .user__menu {
          display: flex; }
        .layout1.sidenav-compact .sidenav:hover .scrollable .navigation button.nav-item {
          padding: 0 20px; }
        .layout1.sidenav-compact .sidenav:hover .scrollable .navigation .nav-item {
          width: 100%;
          justify-content: space-between;
          margin-left: 0;
          border-radius: 0; }
          .layout1.sidenav-compact .sidenav:hover .scrollable .navigation .nav-item .item-text,
          .layout1.sidenav-compact .sidenav:hover .scrollable .navigation .nav-item .item-arrow,
          .layout1.sidenav-compact .sidenav:hover .scrollable .navigation .nav-item .badge {
            display: inline-block; }
          .layout1.sidenav-compact .sidenav:hover .scrollable .navigation .nav-item > button {
            justify-content: flex-start; }
  .layout1.sidenav-compact .content-wrap {
    width: calc(100% - 60px);
    vertical-align: top;
    margin-left: 60px; }
  .layout1.sidenav-close .sidenav {
    left: -230px; }
  .layout1.sidenav-close .content-wrap {
    width: 100%;
    vertical-align: top;
    margin-left: 0px; }
    .layout1.sidenav-close .content-wrap .topbar {
      width: 100%; }
  .layout1.sidenav-mobile .sidenav {
    width: 230px; }
  .layout1.sidenav-mobile .content-wrap {
    width: 100%;
    vertical-align: top;
    margin-left: 0px; }
    .layout1.sidenav-mobile .content-wrap .topbar {
      width: 100%; }

.topbar-hold {
  background-image: linear-gradient(-45deg, #2196f3 0%, #2196f3 33%, #00bfa5 100%); }

.navigation {
  padding-right: 1rem; }
  .navigation button.nav-item {
    padding: 0 20px; }
  .navigation .nav-item {
    margin: 5px 0px;
    display: flex;
    height: 48px;
    justify-content: space-between; }
    .navigation .nav-item button {
      padding: 0 20px; }
    .navigation .nav-item:hover {
      background: #f5f5f5;
      border-radius: 0 24px 24px 0;
      border-left: none; }
    .navigation .nav-item.active {
      color: #1565c0;
      border-left: 4px solid #84c7f6;
      background: #e3f2fd !important;
      border-radius: 0 24px 24px 0; }
    .navigation .nav-item > button {
      justify-content: flex-start; }
    .navigation .nav-item .item-icon {
      font-size: 18px; }
    .navigation .nav-item .item-text {
      font-size: 0.875rem; }
    .navigation .nav-item .icon-text {
      font-size: 0.875rem;
      display: inline-block;
      height: 21px;
      width: 21px;
      line-height: 21px; }
    .navigation .nav-item .item-arrow span {
      font-size: 18px; }
  .navigation .submenu {
    position: relative;
    background: none !important; }
    .navigation .submenu:before, .navigation .submenu:after {
      content: none; }
    .navigation .submenu .nav-item {
      height: 44px;
      margin: 5px 0px; }

.toggle-btn {
  box-shadow: 0 2px 40px -5px #2196f3;
  background-color: #e3f2fd;
  border-radius: 50%; }
  .toggle-btn button {
    color: #2196f3;
    padding: 8px; }

.layout2 {
  flex: 1 1 auto;
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  transition: all 0.15s ease; }
  .layout2 .tab-container {
    max-width: 100vw !important; }
  .layout2 .scrollable-content {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    width: 100%;
    overflow-y: auto; }
  .layout2.sidenav-close .sidenav {
    left: -230px; }

.layout-contained .container {
  max-width: unset;
  margin: 0; }

.layout2 .topbar {
  position: relative;
  width: 100%;
  display: table;
  z-index: 98; }
  .layout2 .topbar .brand {
    height: 100%;
    cursor: pointer;
    align-items: center; }
    .layout2 .topbar .brand .brand__wrap-image img {
      position: absolute;
      left: 20px;
      bottom: 30px;
      top: 15px;
      display: block; }
    @media only screen and (max-width: 960px) {
      .layout2 .topbar .brand .brand__wrap-image img {
        height: 75%;
        position: absolute;
        left: 20px;
        bottom: 30px;
        top: 12px;
        display: block; } }
    .layout2 .topbar .brand .brand__title {
      color: #1e217e !important;
      margin: 0;
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 23px;
      /* identical to box height */
      color: var(--text-blue); }
    .layout2 .topbar .brand .brand__text {
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 17px;
      /* identical to box height */
      color: var(--text-blue);
      margin: 0; }
  .layout2 .topbar .MuiIconButton-root {
    color: #ffffff; }
  .layout2 .topbar .container {
    padding-left: 120px;
    padding-right: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .layout2 .topbar .container .sign {
      color: #1e217e;
      color: #00016d; }
  @media only screen and (min-width: 960px) and (max-width: 1279px) {
    .layout2 .topbar .container {
      padding-left: 60px;
      padding-right: 60px; }
      .layout2 .topbar .container .sign li,
      .layout2 .topbar .container .sign form {
        display: none; } }
  @media only screen and (min-width: 600px) and (max-width: 959px) {
    .layout2 .topbar .container {
      padding-left: 40px;
      padding-right: 40px; }
      .layout2 .topbar .container .sign li,
      .layout2 .topbar .container .sign form {
        display: none; } }
  @media only screen and (max-width: 599px) {
    .layout2 .topbar .container {
      padding-left: 20px;
      padding-right: 20px; }
      .layout2 .topbar .container .sign li,
      .layout2 .topbar .container .sign form {
        display: none; } }

.layout2 .navbar .container {
  padding-left: 120px;
  padding-right: 120px; }

.horizontal-nav {
  display: flex;
  justify-content: center;
  height: 100%; }
  .horizontal-nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
    position: relative; }
  .horizontal-nav ul.menu {
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center; }
    .horizontal-nav ul.menu > li {
      margin: 0 10px; }
      .horizontal-nav ul.menu > li > div > a,
      .horizontal-nav ul.menu > li > div > div {
        border-bottom: 2px solid;
        box-sizing: border-box;
        border-color: transparent;
        margin: 0 6px; }
  .horizontal-nav ul li {
    position: relative;
    margin: 0px;
    display: inline-block; }
    .horizontal-nav ul li ul a {
      padding: 8px 20px;
      height: 48px; }
  .horizontal-nav .nav-item,
  .horizontal-nav label {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 8px 4px 16px;
    font-size: 0.875rem;
    text-decoration: none;
    box-sizing: border-box; }
    .horizontal-nav .nav-item .material-icons,
    .horizontal-nav label .material-icons {
      font-size: 14px;
      margin: 0 4px; }
  .horizontal-nav .nav-item {
    padding: 10px 30px;
    font-size: 14px;
    text-transform: uppercase;
    color: #ffffff;
    transition: all 0.4s ease; }
  .horizontal-nav > ul > .nav-item:hover {
    color: #1e217e;
    border-radius: 5px;
    cursor: pointer; }
  .horizontal-nav .nav-item ul.submenu-overflow {
    border-radius: 5px; }
  .horizontal-nav .nav-item ul.submenu-overflow li a.nav-item {
    font-size: 12px; }
  .horizontal-nav .submenu-overflow {
    max-height: 60vh;
    overflow-y: auto; }
  .horizontal-nav .nav-item:hover {
    background: #f5f5f5;
    border-left: none; }
  .horizontal-nav ul ul.submenu-overflow {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: 0;
    box-shadow: var(--elevation-z8);
    top: 46px;
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
    transition: all 0.3s ease-in-out;
    z-index: -1; }
  .horizontal-nav .menu > li > .nav-item.active,
  .horizontal-nav > ul > .nav-item.active {
    background: #3e4199 !important;
    border-radius: 5px;
    color: #ffffff;
    border-left: none; }
  .horizontal-nav .menu > li > .nav-item:hover,
  .horizontal-nav > ul > .nav-item:hover {
    background: #f2f2f2;
    border-radius: 5px; }
  .horizontal-nav .nav-item.active {
    color: #1565c0;
    border-left: 4px solid #84c7f6;
    background: #e0f2f1 !important; }
  .horizontal-nav ul li:hover > div > div > ul,
  .horizontal-nav ul li:hover > div > ul,
  .horizontal-nav li:hover > ul {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  .horizontal-nav ul ul li {
    width: 170px;
    float: none;
    display: list-item;
    position: relative; }
  .horizontal-nav ul ul ul {
    top: 0;
    left: 170px; }
  .horizontal-nav ul ul ul li {
    position: relative;
    top: 0; }
  .horizontal-nav li > a:after {
    content: "arrow_drop_down";
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 14px;
    line-height: 1;
    margin-left: auto;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased; }
  .horizontal-nav li > a:only-child:after {
    content: ""; }
  @media only screen and (min-width: 960px) and (max-width: 1400px) {
    .horizontal-nav .nav-item {
      padding: 10px 15px;
      font-size: 14px; } }

.circular-image-small {
  height: 36px;
  width: 36px;
  border-radius: 50%; }

.bg-circle-primary {
  background: url("/assets/images/circles.png"), linear-gradient(90deg, var(--primary) -19.83%, var(--primary) 189.85%);
  background-size: cover;
  background-repeat: no-repeat; }

.bg-circle-secondary {
  background: url("/assets/images/circles.png"), linear-gradient(90deg, var(--secondary) -19.83%, var(--secondary) 189.85%);
  background-size: cover;
  background-repeat: no-repeat; }

.bg-circle-warn {
  background: url("/assets/images/circles.png"), linear-gradient(90deg, #e95455 -19.83%, #e95455 189.85%);
  background-size: cover;
  background-repeat: no-repeat; }

.signup {
  background: #1A2038; }
  .signup .signup-card {
    max-width: 800px;
    border-radius: 12px !important; }
    .signup .signup-card img {
      width: 200px; }
  .signup .signup-card-customs {
    max-width: 1000px;
    border-radius: 12px !important; }
    .signup .signup-card-customs img {
      width: 200px; }

.left-sidenav-card {
  position: relative; }
  .left-sidenav-card .header-bg {
    height: 200px;
    background: var(--primary);
    background-image: url("/assets/images/home-bg-black.png");
    background-size: contain; }
  .left-sidenav-card .left-sidenav-card__content {
    margin-top: -200px;
    margin-right: 24px; }
    @media screen and (max-width: 767px) {
      .left-sidenav-card .left-sidenav-card__content {
        margin-right: 0px; } }
  .left-sidenav-card .left-sidenav-card__sidenav .sidenav__header {
    color: white !important; }
    @media screen and (max-width: 767px) {
      .left-sidenav-card .left-sidenav-card__sidenav .sidenav__header {
        color: inherit !important; } }
  @media screen and (max-width: 767px) {
    .left-sidenav-card .left-sidenav-card__sidenav {
      background: var(--bg-default); } }
  .left-sidenav-card .content-card .card-header {
    height: 64px; }

.user-profile {
  position: relative; }
  .user-profile .bg-light-primary {
    background: rgba(var(--primary), 0.1); }
  .user-profile .bg-light-green {
    background: rgba(8, 173, 108, 0.15) !important; }
  .user-profile .bg-light-error {
    background: rgba(233, 84, 85, 0.15) !important; }
  .user-profile .header-bg {
    height: 345px; }
    @media screen and (max-width: 959px) {
      .user-profile .header-bg {
        height: 400px; } }
    @media screen and (max-width: 767px) {
      .user-profile .header-bg {
        height: 400px; } }
  .user-profile .user-profile__content {
    margin-top: -345px;
    padding-top: 74px;
    padding-right: 30px;
    padding-left: 4px; }
    .user-profile .user-profile__content .menu-button {
      display: none; }
    @media screen and (max-width: 959px) {
      .user-profile .user-profile__content {
        margin-top: -390px;
        padding-top: 24px;
        padding-right: 16px;
        padding-left: 16px; } }
    @media screen and (max-width: 767px) {
      .user-profile .user-profile__content {
        margin-top: -410px;
        padding-top: 16px;
        padding-right: 16px;
        padding-left: 16px; }
        .user-profile .user-profile__content .menu-button {
          display: flex; } }
    .user-profile .user-profile__content .content__top-card-holder .content__top-card {
      height: 95px;
      background-color: rgba(0, 0, 0, 0.12); }
    .user-profile .user-profile__content .content__top-card-holder .content__chart {
      width: 54px;
      height: 35px; }
    .user-profile .user-profile__content .user-profile__card {
      overflow: unset; }
      .user-profile .user-profile__content .user-profile__card .card__edge-button {
        position: relative;
        margin-top: -56px; }
      .user-profile .user-profile__content .user-profile__card .edge-vertical-line::after {
        content: " ";
        position: absolute;
        height: 35px;
        width: 5px;
        top: -30px;
        background: var(--primary); }
      .user-profile .user-profile__content .user-profile__card .card__button-holder {
        width: 100px;
        min-width: 100px; }
      .user-profile .user-profile__content .user-profile__card .card__gray-box {
        height: 128px;
        width: calc(100% - 16px);
        border-radius: 8px;
        background-color: #dfdfdf; }
    .user-profile .user-profile__content .bills .bills__icon {
      border-radius: 8px;
      height: 52px;
      width: 52px;
      overflow: hidden;
      background-color: rgba(24, 42, 136, 0.08); }
      .user-profile .user-profile__content .bills .bills__icon h4,
      .user-profile .user-profile__content .bills .bills__icon h5 {
        color: rgba(0, 0, 0, 0.87); }
      .user-profile .user-profile__content .bills .bills__icon img {
        height: 23px;
        width: 36.76px; }
  .user-profile .user-profile__sidenav {
    margin-top: -345px;
    padding-top: 74px; }
    .user-profile .user-profile__sidenav .avatar {
      height: 82px;
      width: 82px; }
    .user-profile .user-profile__sidenav .sidenav__square-card {
      height: 104px;
      width: 104px; }
    @media screen and (max-width: 767px) {
      .user-profile .user-profile__sidenav {
        margin-top: -410px; } }

.invoice-viewer h5 {
  font-size: 15px; }

@media print {
  body,
  *,
  html {
    visibility: hidden; }
  .ps {
    overflow: scroll !important;
    overflow-anchor: none;
    -ms-overflow-style: none;
    touch-action: auto;
    -ms-touch-action: auto; }
  #print-area {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100%; }
    #print-area * {
      visibility: visible; } }

.rbc-event.rbc-selected {
  background-color: var(--primary) !important; }

.rbc-calendar {
  height: auto;
  flex-grow: 1; }

.rbc-header {
  padding: 12px 16px !important; }
  .rbc-header a {
    padding-bottom: 8px !important; }
  .rbc-header span {
    font-size: 15px !important;
    font-weight: 500; }

.calendar-header {
  border-top-right-radius: 6px;
  border-top-left-radius: 6px; }

.crud-table thead tr th:first-child {
  padding-left: 16px !important; }

.crud-table tbody tr {
  transition: background 300ms ease; }
  .crud-table tbody tr:hover {
    background: rgba(0, 0, 0, 0.08); }
  .crud-table tbody tr td {
    border-bottom: none;
    text-transform: capitalize; }
  .crud-table tbody tr td:first-child {
    padding-left: 16px !important; }

.inbox .inbox__topbar {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px; }
  .inbox .inbox__topbar button {
    color: white !important; }

.ql-container {
  min-height: 250px; }
  .ql-container p,
  .ql-container code {
    font-size: 16px; }

.ql-toolbar {
  background: white;
  border-bottom: none; }

.chat-sidenav {
  border-right: 1px solid rgba(0, 0, 0, 0.08);
  height: 450px; }
  .chat-sidenav .chat-contact-list {
    height: 100%; }

.chat-container {
  background: rgba(0, 0, 0, 0.05);
  height: 450px; }
  .chat-container .chat-message-list .list__message {
    border-radius: 4px;
    overflow: hidden; }
  .chat-container .empty-message-circle {
    height: 220px;
    width: 220px;
    border-radius: 50%;
    box-shadow: var(--elevation-z6); }
    .chat-container .empty-message-circle .MuiIcon-root {
      font-size: 4rem !important; }

.todo .todo__search-box-holder {
  background: var(--primary);
  height: 220px; }
  .todo .todo__search-box-holder > div {
    height: calc(220px - 94px + 30px); }
    @media screen and (max-width: 767px) {
      .todo .todo__search-box-holder > div {
        height: calc(220px - 94px - 16px + 30px); } }
    .todo .todo__search-box-holder > div .todo__search-box {
      width: calc(100% - 60px);
      height: 48px;
      border-radius: 24px;
      overflow: hidden; }
      .todo .todo__search-box-holder > div .todo__search-box input[type="text"] {
        font-size: 18px;
        outline: none;
        border: none; }

.todo .todo__content {
  margin-top: -94px; }

.sales .bills .bills__icon {
  border-radius: 8px;
  height: 52px;
  width: 52px;
  overflow: hidden;
  background-color: rgba(24, 42, 136, 0.08); }
  .sales .bills .bills__icon h4,
  .sales .bills .bills__icon h5 {
    color: rgba(0, 0, 0, 0.87); }
  .sales .bills .bills__icon img {
    height: 23px;
    width: 36.76px; }

.sales .product-table {
  white-space: pre;
  min-width: 400px;
  overflow: auto; }
  .sales .product-table small {
    height: 15px;
    width: 50px;
    border-radius: 500px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24); }
  .sales .product-table tbody tr {
    transition: background 300ms ease; }
    .sales .product-table tbody tr:hover {
      background: rgba(0, 0, 0, 0.08); }
    .sales .product-table tbody tr td {
      border-bottom: none;
      text-transform: capitalize; }
    .sales .product-table tbody tr td:first-child {
      padding-left: 16px !important; }

.analytics .face-group .avatar {
  border: 2px solid white; }
  .analytics .face-group .avatar:not(:first-child) {
    margin-left: -14px; }

.analytics .face-group .number-avatar {
  background: #e95455; }

.analytics .small-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16px;
  width: 16px;
  border-radius: 50%; }
  .analytics .small-circle .small-icon {
    font-size: 8px; }

.analytics .project-card .card__roject-name {
  margin-left: 24px; }
  @media screen and (max-width: 767px) {
    .analytics .project-card .card__roject-name {
      margin-left: 4px; } }

.learning-management {
  position: relative; }
  .learning-management .welcome-card {
    position: relative;
    padding: 36px 50px !important;
    overflow: visible; }
    .learning-management .welcome-card img {
      margin-top: -82px;
      max-width: 230px; }
    @media screen and (max-width: 767px) {
      .learning-management .welcome-card img {
        display: none; } }
  .learning-management .play-card {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
    .learning-management .play-card small {
      line-height: 1; }
  .learning-management .product-table {
    white-space: pre;
    min-width: 400px;
    overflow: auto; }
    .learning-management .product-table small {
      height: 15px;
      width: 50px;
      border-radius: 500px;
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24); }
    .learning-management .product-table tbody tr {
      transition: background 300ms ease; }
      .learning-management .product-table tbody tr:hover {
        background: rgba(0, 0, 0, 0.08); }
      .learning-management .product-table tbody tr td {
        border-bottom: none;
        text-transform: capitalize; }
      .learning-management .product-table tbody tr td:first-child {
        padding-left: 16px !important; }
  .learning-management .upgrade-card {
    box-shadow: none;
    text-align: center;
    position: relative; }
    .learning-management .upgrade-card h6 {
      position: relative;
      left: 50%;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
      width: 150px; }

.list .list-view .list__card .project-image {
  height: 75px;
  width: 100px; }

.list .list-view .list__card .card__button-group {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  right: 0; }

.list .list-view .list__card:hover .card__button-group {
  display: flex; }

.list .grid-view .grid__card {
  position: relative; }
  .list .grid-view .grid__card:hover .grid__card-top::after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.54);
    z-index: 1;
    -webkit-animation: fade-in 250ms cubic-bezier(0.17, 0.67, 0.83, 0.67);
            animation: fade-in 250ms cubic-bezier(0.17, 0.67, 0.83, 0.67); }

@keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
  .list .grid-view .grid__card:hover .grid__card-top .grid__card-overlay {
    display: block; }
  .list .grid-view .grid__card:hover .grid__card-bottom .email {
    display: block; }
  .list .grid-view .grid__card:hover .grid__card-bottom .date {
    display: none; }
  .list .grid-view .grid__card .grid__card-top {
    position: relative; }
    .list .grid-view .grid__card .grid__card-top .grid__card-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: none;
      z-index: 2; }
      .list .grid-view .grid__card .grid__card-top .grid__card-overlay > div:nth-child(2) {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: -1; }
  .list .grid-view .grid__card .grid__card-bottom .email {
    display: none; }

html:focus-within {
  -webkit-scroll-behavior: auto !important;
  scroll-behavior: smooth !important; }

.landing a {
  text-decoration: none; }

.landing img {
  max-width: 100%; }

.landing div,
.landing section {
  box-sizing: border-box; }

.landing .light-gray {
  background: rgba(0, 0, 0, 0.024); }

.landing .bg-img-indigo {
  background: url(/assets/images/home-bg.jpg) center center/cover no-repeat !important; }

.landing .bg-img-black {
  background: url(/assets/images/home-bg-black.png) center center/cover no-repeat !important; }

.landing .container {
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px; }

.landing .text-center {
  text-align: center; }

.landing .text-sm {
  font-size: 0.813rem; }

.landing .fs-1 {
  font-size: 1rem !important; }

.landing .fw-400 {
  font-weight: 400; }

.landing .font-light {
  font-weight: 300 !important; }

.landing .text-capitalize {
  text-transform: capitalize; }

.landing .text-uppercase {
  text-transform: uppercase; }

.landing .text-primary {
  color: var(--primary); }

.landing .text-secondary {
  color: var(--secondary); }

.landing .relative {
  position: relative; }

.landing .elevation-3 {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12); }

.landing .card {
  transition: all 0.3s ease; }
  .landing .card:hover {
    box-shadow: 0 10px 16px rgba(0, 0, 0, 0.2); }

.landing .subscribe-input {
  display: inline-flex;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  padding: 0.25rem 0.25rem 0.25rem 1rem;
  border-radius: 2rem;
  overflow: hidden;
  background: #ffffff;
  min-width: 480px; }
  .landing .subscribe-input .email-input {
    border: 0;
    font-size: 1rem;
    flex-grow: 1;
    padding: 0;
    line-height: 1;
    margin: 0;
    background: inherit; }
    .landing .subscribe-input .email-input:focus {
      outline: 0; }
  .landing .subscribe-input .btn-action {
    margin: 0 !important; }
  @media only screen and (max-width: 767px) {
    .landing .subscribe-input {
      max-width: 90%;
      min-width: auto; } }

.landing .rounded-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 64px;
  border: 2px solid rgba(var(--primary), 0.8);
  color: var(--primary);
  background: rgba(var(--primary), 0.2); }
  .landing .rounded-icon .material-icons {
    font-size: 1.5rem; }
  .landing .rounded-icon.secondary {
    border: 2px solid rgba(var(--secondary), 0.8);
    color: var(--secondary);
    background: rgba(var(--secondary), 0.2); }

.landing .section {
  padding: 80px 0; }
  .landing .section .section__header {
    margin: 0 0 32px; }
    .landing .section .section__header h2 {
      font-size: 32px;
      font-weight: 700;
      margin: 0 0 16px; }
    .landing .section .section__header p {
      font-size: 16px;
      max-width: 36rem;
      margin: 0; }

.landing .btn-action {
  padding: 0 24px !important;
  font-size: 18px !important; }

.landing .btn-white {
  background: #ffffff !important;
  color: rgba(0, 0, 0, 0.87) !important; }
  .landing .btn-white:hover {
    background: #f2f2f2 !important; }

.header {
  position: fixed;
  width: 100%;
  background: #ffffff;
  top: 0;
  left: 0;
  right: 0;
  padding: 20px 0;
  transition: padding 0.3s linear;
  -webkit-transition: padding 0.3s linear;
  z-index: 2; }
  .header.header-fixed {
    padding: 10px 0;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28); }
  .header .header-container {
    display: flex;
    flex-direction: row;
    align-items: center; }
    .header .header-container .brand {
      display: flex;
      align-items: center; }
      .header .header-container .brand img {
        max-height: 34px; }
    .header .header-container ul.navigation {
      list-style: none;
      margin: 0 0 0 30px;
      padding: 0; }
      .header .header-container ul.navigation li {
        display: inline-block; }
        .header .header-container ul.navigation li a {
          display: flex;
          align-items: center;
          color: rgba(0, 0, 0, 0.87);
          padding: 8px 15px;
          font-weight: 700;
          border-radius: 4px;
          transition: all 0.3s ease-in; }
          .header .header-container ul.navigation li a:hover {
            background: #f0f0f0; }
    .header .header-container .header__toggle {
      display: none; }
  @media only screen and (max-width: 767px) {
    .header {
      width: 220px;
      height: 100vh;
      left: auto;
      right: 0;
      padding: 20px 0 !important;
      box-shadow: -2px 0 4px rgba(0, 0, 0, 0.14), -2px 4px 8px rgba(0, 0, 0, 0.28) !important;
      transition: all 0.3s ease-in-out; }
      .header.closed {
        right: calc(-220px - 5px); }
        .header.closed .header__toggle {
          color: rgba(0, 0, 0, 0.87);
          background: #ffffff;
          box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12); }
      .header .header__toggle {
        top: 10px;
        right: 15px;
        color: rgba(0, 0, 0, 0.87);
        position: fixed;
        display: inline-block !important;
        transition: all 0.3s ease-in-out; }
      .header .header-container {
        flex-direction: column;
        padding: 0;
        height: 100%; }
        .header .header-container .brand {
          padding: 0 20px 20px;
          width: 100%; }
        .header .header-container ul.navigation {
          margin: 0;
          width: 100%; }
          .header .header-container ul.navigation li {
            display: block; }
            .header .header-container ul.navigation li a {
              padding: 15px 20px;
              border-radius: 0; } }

.section-intro1 {
  padding: 180px 0 !important;
  color: #ffffff;
  overflow: hidden; }
  .section-intro1 h1,
  .section-intro1 h2,
  .section-intro1 h3,
  .section-intro1 h4,
  .section-intro1 h5,
  .section-intro1 h6 {
    color: #ffffff; }
  .section-intro1 .section-intro1__title {
    margin: 0 0 24px;
    font-size: 48px;
    font-weight: 900;
    line-height: 52px;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.22); }
  .section-intro1 .section-intro1__subtitle {
    margin: 0 0 40px;
    font-size: 22px;
    font-weight: 400;
    line-height: 32px; }
  .section-intro1 .section-intro1__list {
    margin: 0 0 30px; }
    .section-intro1 .section-intro1__list .section-intro1__list__item {
      display: flex;
      align-items: center;
      margin-bottom: 1rem; }
      .section-intro1 .section-intro1__list .section-intro1__list__item .material-icons {
        margin-right: 7px; }
  .section-intro1 .section-intro1__product {
    position: relative;
    top: 100px; }
    .section-intro1 .section-intro1__product img {
      width: 100%;
      border-radius: 0.5rem;
      box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12); }
    .section-intro1 .section-intro1__product .section-intro1__product__link {
      position: absolute;
      top: -100px;
      left: -30px;
      text-align: center;
      display: block;
      width: 160px;
      height: 160px;
      border-radius: 50%;
      background: #ffffff;
      color: rgba(0, 0, 0, 0.87);
      padding: 37px 20px;
      box-sizing: border-box;
      overflow: hidden;
      box-shadow: 0 14px 32px rgba(0, 0, 0, 0.2);
      transition: all 0.3s ease; }
      .section-intro1 .section-intro1__product .section-intro1__product__link:hover {
        box-shadow: 0 19px 32px rgba(0, 0, 0, 0.4); }
      .section-intro1 .section-intro1__product .section-intro1__product__link .price {
        font-size: 32px;
        font-weight: 700; }
      .section-intro1 .section-intro1__product .section-intro1__product__link .price__text {
        font-size: 14px; }
  .section-intro1 .intro1__description {
    max-width: 570px;
    margin: 0 auto 24px; }
  @media only screen and (max-width: 767px) {
    .section-intro1 {
      padding: 100px 0 !important; } }
  @media only screen and (max-width: 959px) {
    .section-intro1 {
      text-align: center; }
      .section-intro1 .section-intro1__list {
        display: flex;
        flex-direction: column;
        align-items: center; }
      .section-intro1 .section-intro1__product {
        top: 0;
        left: 0; }
      .section-intro1 .section-intro1__product__link {
        display: none !important; } }

.section-intro2 {
  padding: 180px 0 0 !important;
  overflow: visible !important; }
  .section-intro2 h1,
  .section-intro2 h2,
  .section-intro2 h3,
  .section-intro2 h4,
  .section-intro2 h5,
  .section-intro2 h6 {
    color: #ffffff; }
  .section-intro2 .section-intro2__product {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    margin: auto;
    bottom: -80px;
    max-width: 780px;
    margin-bottom: 5rem; }
    .section-intro2 .section-intro2__product img {
      border-radius: 0.5rem;
      box-shadow: 0 10px 16px rgba(0, 0, 0, 0.2); }
  @media screen and (max-width: 767px) {
    .section-intro2 {
      padding: 100px 0 0 !important; }
      .section-intro2 .section-intro2__product {
        bottom: -63px; } }
  @media screen and (max-width: 580px) {
    .section-intro2 .section-intro2__product {
      bottom: -39px; } }

.section-intro3 {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87); }
  .section-intro3 .section-intro1__title {
    color: var(--primary); }
  .section-intro3 .intro3__product {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2.5rem; }
    .section-intro3 .intro3__product img {
      max-width: 65%; }

.section-portfolio1 .portfolio1__card {
  position: relative;
  height: 100%; }
  .section-portfolio1 .portfolio1__card img {
    width: 100%;
    height: auto; }
  .section-portfolio1 .portfolio1__card h5 {
    margin: 0px;
    font-size: 1rem;
    font-weight: bold; }
  .section-portfolio1 .portfolio1__card p {
    margin-bottom: 16px; }
  .section-portfolio1 .portfolio1__card .portfolio1__card__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between; }

.card__icon-64 {
  font-size: 64px !important;
  height: 64px;
  width: 64px; }

.service__card:hover .card__icon-64 {
  color: var(--primary); }

.section-service1 .service1__card {
  border-top: 2px solid black;
  height: 100%; }
  .section-service1 .service1__card h3 {
    font-size: 1.5rem;
    font-weight: normal; }
  .section-service1 .service1__card .service1__card__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%; }
  .section-service1 .service1__card:hover {
    border-top: 2px solid var(--primary); }

.section-service4 {
  transition: -webkit-transform 400ms cubic-bezier(0.17, 0.67, 0.83, 0.67);
  transition: transform 400ms cubic-bezier(0.17, 0.67, 0.83, 0.67);
  transition: transform 400ms cubic-bezier(0.17, 0.67, 0.83, 0.67), -webkit-transform 400ms cubic-bezier(0.17, 0.67, 0.83, 0.67); }
  .section-service4 .service4__card {
    position: relative;
    overflow: hidden; }
    .section-service4 .service4__card .service4__card__icon {
      position: absolute;
      font-size: 200px;
      height: 200px;
      width: 200px;
      left: -80px;
      bottom: -80px;
      z-index: 1;
      opacity: 0.24;
      transition: -webkit-transform 400ms cubic-bezier(0.17, 0.67, 0.83, 0.67);
      transition: transform 400ms cubic-bezier(0.17, 0.67, 0.83, 0.67);
      transition: transform 400ms cubic-bezier(0.17, 0.67, 0.83, 0.67), -webkit-transform 400ms cubic-bezier(0.17, 0.67, 0.83, 0.67); }
    .section-service4 .service4__card .service4__card__description {
      max-width: 370px;
      text-align: left;
      vertical-align: middle;
      padding: 32px;
      margin-left: 96px;
      z-index: 2; }
    .section-service4 .service4__card:hover {
      -webkit-transform: translateY(-8px);
              transform: translateY(-8px); }
      .section-service4 .service4__card:hover .service4__card__icon {
        -webkit-transform: translateY(-8px);
                transform: translateY(-8px);
        color: var(--secondary);
        opacity: 0.6; }

.section-service5 .service5__image-holder img {
  border-radius: 4px;
  overflow: hidden; }

.section-service5 p {
  white-space: pre-line; }

.section-service5 .service5__link-icon {
  font-size: inherit;
  vertical-align: middle; }

.section-service6 .sevice6__circle {
  width: 112px;
  height: 112px;
  margin: 0px auto;
  background: #ffffff;
  border-radius: 50%;
  overflow: hidden; }

.section-service7 img {
  max-height: 280px; }

.section-service7 .service7__card__description {
  max-width: 370px;
  text-align: left; }
  .section-service7 .service7__card__description p {
    white-space: pre-line; }

.section-service7 .service7__card {
  background-image: url("/assets/images/illustrations/1.svg");
  background-size: 250px;
  background-repeat: no-repeat;
  background-position: center right; }
  .section-service7 .service7__card .service7__card__icon {
    font-size: 48px; }

.section-testimonial1 .card {
  border-radius: 0; }
  .section-testimonial1 .card .testimonial1__card-content {
    position: relative;
    padding: 0;
    cursor: default; }
    .section-testimonial1 .card .testimonial1__card-content .testimonial1__wrap-img img {
      display: block;
      margin: 0 auto; }
    .section-testimonial1 .card .testimonial1__card-content .testimonial1__content {
      position: absolute;
      padding-left: 120px;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0; }
      .section-testimonial1 .card .testimonial1__card-content .testimonial1__content .testimonial1__content-title {
        position: absolute;
        top: 28%; }
      .section-testimonial1 .card .testimonial1__card-content .testimonial1__content .testimonial1__content-link {
        position: absolute;
        bottom: 28%; }
      .section-testimonial1 .card .testimonial1__card-content .testimonial1__content .testimonial1__content-title,
      .section-testimonial1 .card .testimonial1__card-content .testimonial1__content .testimonial1__content-link {
        max-width: 450px; }
      .section-testimonial1 .card .testimonial1__card-content .testimonial1__content .testimonial1__content-title {
        padding-bottom: 16px; }
      .section-testimonial1 .card .testimonial1__card-content .testimonial1__content .testimonial1__content-appeal,
      .section-testimonial1 .card .testimonial1__card-content .testimonial1__content .testimonial1__content-comment {
        color: #d76e00; }
      .section-testimonial1 .card .testimonial1__card-content .testimonial1__content .testimonial1__content-appeal {
        font-size: 32px; }
      .section-testimonial1 .card .testimonial1__card-content .testimonial1__content .testimonial1__content-comment {
        font-size: 25px;
        font-weight: normal;
        line-height: 1.2;
        margin-top: 30px;
        margin-bottom: 0; }
      .section-testimonial1 .card .testimonial1__card-content .testimonial1__content .testimonial1__content-link a {
        font-family: "Roboto", sans-serif;
        display: inline-block;
        border: none;
        background-color: #3e4199;
        border-radius: 5px;
        color: #ffffff;
        padding: 8px 16px;
        font-size: 15px;
        box-shadow: 4px 3px 8px #999;
        cursor: pointer; }
  @media only screen and (max-width: 480px) {
    .section-testimonial1 .card .testimonial1__card-content .testimonial1__content {
      padding-left: 20px; }
      .section-testimonial1 .card .testimonial1__card-content .testimonial1__content .testimonial1__content-title {
        padding-bottom: 0;
        top: 20%; }
      .section-testimonial1 .card .testimonial1__card-content .testimonial1__content .testimonial1__content-appeal,
      .section-testimonial1 .card .testimonial1__card-content .testimonial1__content .testimonial1__content-comment {
        font-size: 14px;
        max-width: 250px; }
      .section-testimonial1 .card .testimonial1__card-content .testimonial1__content .testimonial1__content-comment {
        margin-top: 8px; }
      .section-testimonial1 .card .testimonial1__card-content .testimonial1__content .testimonial1__content-link {
        bottom: 15%; }
      .section-testimonial1 .card .testimonial1__card-content .testimonial1__content .testimonial1__content-link a {
        font-size: 13px;
        padding: 4px 8px; } }

.section-testimonial1 .testimonial1__card-content_cus {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%; }
  .section-testimonial1 .testimonial1__card-content_cus > div:first-child img {
    height: 36px !important;
    width: auto; }
  .section-testimonial1 .testimonial1__card-content_cus > div:first-child p {
    font-size: 1rem;
    padding-bottom: 65px; }
  .section-testimonial1 .testimonial1__card-content_cus .card__user {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center; }
    .section-testimonial1 .testimonial1__card-content_cus .card__user img {
      width: 100%;
      height: 100%;
      border-radius: 24px;
      overflow: hidden; }

.section-testimonial3 img {
  margin: 0px;
  padding: 0px;
  height: 48px;
  width: 48px;
  vertical-align: middle;
  border-radius: 50%;
  overflow: hidden; }

.section-testimonial3 .test {
  background: red; }

.section-cta1 {
  color: #ffffff;
  background: url(/assets/images/home-bg.jpg) center center/cover no-repeat; }
  .section-cta1 h1,
  .section-cta1 h2,
  .section-cta1 h3,
  .section-cta1 h4,
  .section-cta1 h5,
  .section-cta1 h6 {
    color: #ffffff; }
  .section-cta1 h2 {
    font-size: 1.75rem;
    margin: 0;
    padding-bottom: 0.5rem;
    font-weight: 400;
    line-height: 1.1; }
  .section-cta1 p {
    margin: 0; }

.section-pricing1 .pricing1__card-header {
  padding: 12px;
  background: rgba(0, 0, 0, 0.024); }
  .section-pricing1 .pricing1__card-header span:first-child {
    font-size: 1rem !important; }
  .section-pricing1 .pricing1__card-header span:last-child {
    font-size: 0.813rem; }

.section-pricing1 .pricing1__highlighted {
  background: var(--primary) !important; }
  .section-pricing1 .pricing1__highlighted span {
    color: #ffffff; }

.section-pricing1 .pricing1__card-content h1 {
  margin: 0;
  margin-bottom: 0.5rem;
  font-size: 2rem; }

.section-pricing1 .pricing1__card-content div {
  padding: 10px; }

.section-footer1 h4 {
  font-size: 1.25rem;
  margin: 0 0 24px;
  position: relative; }

.section-footer1 h4::after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 0;
  height: 2px;
  width: 64px;
  background-color: var(--secondary); }

.section-footer1 .footer1__contact h5 {
  font-size: 1rem; }

.section-footer1 .footer1__contact > div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center; }

.section-footer1 .footer1__contact .footer1__contact__icon {
  color: var(--secondary); }

.section-footer1 .footer1__disclaimer__link img {
  height: 24px;
  width: 24px; }

.section-footer1 .footer1__contact,
.section-footer1 .footer1__disclaimer,
.section-footer1 .footer1__about {
  padding: 30px;
  height: 100%;
  box-shadow: 3px 0 4px rgba(0, 0, 0, 0.2); }

.MuiPaper-elevation1 {
  box-shadow: none !important; }

.process {
  font-family: "Open Sans", sans-serif;
  padding-right: 120px;
  padding-left: 120px; }
  .process .process__work {
    border-radius: 8px; }
    .process .process__work .work__recruitment .textRight,
    .process .process__work .work__recruitment .textLeft,
    .process .process__work .work__produce .textRight,
    .process .process__work .work__produce .textLeft {
      padding: 8px;
      overflow: hidden; }
      .process .process__work .work__recruitment .textRight .img,
      .process .process__work .work__recruitment .textLeft .img,
      .process .process__work .work__produce .textRight .img,
      .process .process__work .work__produce .textLeft .img {
        width: 50%;
        text-align: center; }
      .process .process__work .work__recruitment .textRight .text,
      .process .process__work .work__recruitment .textLeft .text,
      .process .process__work .work__produce .textRight .text,
      .process .process__work .work__produce .textLeft .text {
        width: 50%;
        padding-left: 3%; }
        .process .process__work .work__recruitment .textRight .text .text__number,
        .process .process__work .work__recruitment .textLeft .text .text__number,
        .process .process__work .work__produce .textRight .text .text__number,
        .process .process__work .work__produce .textLeft .text .text__number {
          font-size: 56px;
          line-height: 1.2;
          font-weight: 500; }
        .process .process__work .work__recruitment .textRight .text .text__title,
        .process .process__work .work__recruitment .textLeft .text .text__title,
        .process .process__work .work__produce .textRight .text .text__title,
        .process .process__work .work__produce .textLeft .text .text__title {
          font-weight: 700;
          line-height: 1.5;
          letter-spacing: 2px; }
        .process .process__work .work__recruitment .textRight .text p,
        .process .process__work .work__recruitment .textLeft .text p,
        .process .process__work .work__produce .textRight .text p,
        .process .process__work .work__produce .textLeft .text p {
          color: black; }
    .process .process__work .work__recruitment {
      color: #f29e29;
      background-color: rgba(230, 145, 56, 0.2);
      border-bottom-left-radius: 8px; }
      .process .process__work .work__recruitment .orange div {
        border-radius: 5px 0px 0px 0px;
        color: #ffffff;
        line-height: 50px;
        margin-left: auto;
        background-color: #e69138; }
        .process .process__work .work__recruitment .orange div .title {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          padding-left: 5%;
          text-align: justify; }
        .process .process__work .work__recruitment .orange div .linkUpdateCV {
          float: right;
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          margin-right: 2%;
          text-align: justify;
          -webkit-text-decoration-line: underline;
                  text-decoration-line: underline; }
      .process .process__work .work__recruitment .textRight,
      .process .process__work .work__recruitment .textLeft {
        margin-left: auto; }
      @media only screen and (max-width: 480px) {
        .process .process__work .work__recruitment .work__recruitment {
          color: red; } }
    .process .process__work .work__produce {
      color: #3c9fb4;
      background-color: rgba(60, 159, 180, 0.1);
      border-bottom-right-radius: 8px; }
      .process .process__work .work__produce .blue div {
        border-radius: 0px 5px 0px 0px;
        color: #ffffff;
        line-height: 50px;
        margin-left: auto;
        background-color: #3c9fb4; }
        .process .process__work .work__produce .blue div .title {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          padding-left: 5%;
          text-align: justify; }
        .process .process__work .work__produce .blue div .linkFindACreat {
          float: right;
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          margin-right: 2%;
          text-align: justify;
          -webkit-text-decoration-line: underline;
                  text-decoration-line: underline; }
      .process .process__work .work__produce .textRight,
      .process .process__work .work__produce .textLeft {
        margin-right: auto; }

@media only screen and (min-width: 960px) and (max-width: 1279px) {
  .process {
    display: block;
    padding-left: 60px;
    padding-right: 60px; }
    .process .process__work {
      flex-direction: column; } }

@media only screen and (min-width: 600px) and (max-width: 959px) {
  .process {
    display: block;
    padding-left: 0;
    padding-right: 0; }
    .process .process__work {
      flex-direction: column; } }

@media only screen and (max-width: 599px) {
  .process {
    display: block;
    padding-left: 0;
    padding-right: 0; }
    .process .process__work {
      flex-direction: column; } }

.testimonial__home-background {
  position: absolute;
  top: calc(50% - 307px - 25px); }

.testimonial__home {
  position: relative; }
  .testimonial__home .testimonial__home-background .testimonial__home-wrap {
    position: relative;
    top: 0;
    z-index: 1; }
    .testimonial__home .testimonial__home-background .testimonial__home-wrap .testimonial1__window {
      display: block;
      height: 100%;
      margin-left: auto;
      -webkit-user-drag: none;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none; }
    .testimonial__home .testimonial__home-background .testimonial__home-wrap .testimonial1__content-wrap {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      z-index: -1; }
      .testimonial__home .testimonial__home-background .testimonial__home-wrap .testimonial1__content-wrap .testimonial1__content {
        position: absolute;
        padding-left: 120px;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: left; }
        .testimonial__home .testimonial__home-background .testimonial__home-wrap .testimonial1__content-wrap .testimonial1__content .testimonial1__content-title {
          position: absolute;
          top: 28%; }
        .testimonial__home .testimonial__home-background .testimonial__home-wrap .testimonial1__content-wrap .testimonial1__content .testimonial1__content-link {
          position: absolute;
          bottom: 28%; }
        .testimonial__home .testimonial__home-background .testimonial__home-wrap .testimonial1__content-wrap .testimonial1__content .testimonial1__content-title,
        .testimonial__home .testimonial__home-background .testimonial__home-wrap .testimonial1__content-wrap .testimonial1__content .testimonial1__content-link {
          max-width: 450px; }
        .testimonial__home .testimonial__home-background .testimonial__home-wrap .testimonial1__content-wrap .testimonial1__content .testimonial1__content-title {
          padding-bottom: 16px; }
        .testimonial__home .testimonial__home-background .testimonial__home-wrap .testimonial1__content-wrap .testimonial1__content .testimonial1__content-appeal,
        .testimonial__home .testimonial__home-background .testimonial__home-wrap .testimonial1__content-wrap .testimonial1__content .testimonial1__content-comment {
          color: #d76e00; }
        .testimonial__home .testimonial__home-background .testimonial__home-wrap .testimonial1__content-wrap .testimonial1__content .testimonial1__content-appeal {
          font-size: 32px; }
        .testimonial__home .testimonial__home-background .testimonial__home-wrap .testimonial1__content-wrap .testimonial1__content .testimonial1__content-comment {
          font-size: 25px;
          font-weight: normal;
          line-height: 1.2;
          margin-top: 30px;
          margin-bottom: 0; }
        .testimonial__home .testimonial__home-background .testimonial__home-wrap .testimonial1__content-wrap .testimonial1__content .testimonial1__content-link a {
          font-family: "Roboto", sans-serif;
          display: inline-block;
          border: none;
          background-color: #3e4199;
          border-radius: 5px;
          color: #ffffff;
          padding: 8px 16px;
          font-size: 15px;
          box-shadow: 4px 3px 8px #999;
          cursor: pointer; }
      @media only screen and (min-width: 960px) and (max-width: 1279px) {
        .testimonial__home .testimonial__home-background .testimonial__home-wrap .testimonial1__content-wrap .testimonial1__content {
          padding-left: 60px; }
          .testimonial__home .testimonial__home-background .testimonial__home-wrap .testimonial1__content-wrap .testimonial1__content .testimonial1__content-title {
            padding-bottom: 0;
            top: 22%; }
          .testimonial__home .testimonial__home-background .testimonial__home-wrap .testimonial1__content-wrap .testimonial1__content .testimonial1__content-appeal,
          .testimonial__home .testimonial__home-background .testimonial__home-wrap .testimonial1__content-wrap .testimonial1__content .testimonial1__content-comment {
            max-width: 400px; }
          .testimonial__home .testimonial__home-background .testimonial__home-wrap .testimonial1__content-wrap .testimonial1__content .testimonial1__content-appeal {
            font-size: 30px; }
          .testimonial__home .testimonial__home-background .testimonial__home-wrap .testimonial1__content-wrap .testimonial1__content .testimonial1__content-comment {
            font-size: 20px;
            margin-top: 22px; }
          .testimonial__home .testimonial__home-background .testimonial__home-wrap .testimonial1__content-wrap .testimonial1__content .testimonial1__content-link {
            bottom: 22%; } }
      @media only screen and (min-width: 600px) and (max-width: 959px) {
        .testimonial__home .testimonial__home-background .testimonial__home-wrap .testimonial1__content-wrap .testimonial1__content {
          padding-left: 40px; }
          .testimonial__home .testimonial__home-background .testimonial__home-wrap .testimonial1__content-wrap .testimonial1__content .testimonial1__content-title {
            padding-bottom: 0;
            top: 20%; }
          .testimonial__home .testimonial__home-background .testimonial__home-wrap .testimonial1__content-wrap .testimonial1__content .testimonial1__content-appeal,
          .testimonial__home .testimonial__home-background .testimonial__home-wrap .testimonial1__content-wrap .testimonial1__content .testimonial1__content-comment {
            max-width: 350px; }
          .testimonial__home .testimonial__home-background .testimonial__home-wrap .testimonial1__content-wrap .testimonial1__content .testimonial1__content-appeal {
            font-size: 25px; }
          .testimonial__home .testimonial__home-background .testimonial__home-wrap .testimonial1__content-wrap .testimonial1__content .testimonial1__content-comment {
            font-size: 20px;
            margin-top: 15px; }
          .testimonial__home .testimonial__home-background .testimonial__home-wrap .testimonial1__content-wrap .testimonial1__content .testimonial1__content-link {
            bottom: 20%; } }
      @media only screen and (max-width: 599px) {
        .testimonial__home .testimonial__home-background .testimonial__home-wrap .testimonial1__content-wrap .testimonial1__content {
          padding-left: 20px; }
          .testimonial__home .testimonial__home-background .testimonial__home-wrap .testimonial1__content-wrap .testimonial1__content .testimonial1__content-title {
            padding-bottom: 0;
            top: 20%; }
          .testimonial__home .testimonial__home-background .testimonial__home-wrap .testimonial1__content-wrap .testimonial1__content .testimonial1__content-appeal,
          .testimonial__home .testimonial__home-background .testimonial__home-wrap .testimonial1__content-wrap .testimonial1__content .testimonial1__content-comment {
            max-width: 250px; }
          .testimonial__home .testimonial__home-background .testimonial__home-wrap .testimonial1__content-wrap .testimonial1__content .testimonial1__content-appeal {
            font-size: 14px; }
          .testimonial__home .testimonial__home-background .testimonial__home-wrap .testimonial1__content-wrap .testimonial1__content .testimonial1__content-comment {
            font-size: 14px;
            margin-top: 8px; }
          .testimonial__home .testimonial__home-background .testimonial__home-wrap .testimonial1__content-wrap .testimonial1__content .testimonial1__content-link {
            bottom: 15%; }
          .testimonial__home .testimonial__home-background .testimonial__home-wrap .testimonial1__content-wrap .testimonial1__content .testimonial1__content-link a {
            font-size: 13px;
            padding: 4px 8px; } }

.swiper-slide {
  height: auto; }

.swiper-pagination {
  width: 50px !important;
  bottom: 10% !important;
  left: 120px !important; }
  @media only screen and (max-width: 480px) {
    .swiper-pagination {
      display: none;
      left: 20px !important; } }

.swiper-pagination-bullet {
  opacity: 1;
  background: #ffffff;
  transition: -webkit-transform 400ms cubic-bezier(0.17, 0.67, 0.83, 0.67);
  transition: transform 400ms cubic-bezier(0.17, 0.67, 0.83, 0.67);
  transition: transform 400ms cubic-bezier(0.17, 0.67, 0.83, 0.67), -webkit-transform 400ms cubic-bezier(0.17, 0.67, 0.83, 0.67); }

.bullet-active {
  -webkit-transform: scale(1.8);
          transform: scale(1.8); }

.carousel__button-next,
.carousel__button-prev {
  position: absolute !important;
  top: 50%;
  font-size: 40px !important;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  z-index: 1;
  background-color: transparent !important;
  box-shadow: none !important;
  height: 30px !important;
  width: 30px !important;
  min-height: 0 !important; }

.carousel__button-next-training {
  margin-left: 14px !important; }

.carousel__button-next-training,
.carousel__button-prev-training {
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  background-color: transparent !important;
  box-shadow: none !important;
  height: 30px !important;
  width: 30px !important;
  min-height: 0 !important; }

.carousel__button-next .MuiSvgIcon-root,
.carousel__button-prev .MuiSvgIcon-root {
  font-size: 40px !important; }

.carousel__button-prev {
  left: -30px; }

.carousel__button-next {
  right: -30px; }

@media (max-width: 1440px) {
  .carousel__button-prev {
    left: 0px; }
  .carousel__button-next {
    right: 0px; }
  .carousel__button-prev span img {
    width: 12px; }
  .carousel__button-next span img {
    width: 12px; } }

@media (max-width: 1024px) {
  .carousel__button-prev span img {
    width: 10px; }
  .carousel__button-next span img {
    width: 10px; } }

.carousel__button-prev.color-black,
.carousel__button-next.color-black {
  color: #000; }

.carousel__button-prev.color-black,
.carousel__button-next.color-black {
  right: #ffffff; }

.swiper-container.swiper-container-home {
  height: 100%; }
  .swiper-container.swiper-container-home .swiper-slide {
    text-align: right; }
  .swiper-container.swiper-container-home .swiper-pagination.swiper-pagination-home {
    width: 80px !important;
    bottom: 10% !important;
    left: 120px !important; }
    @media only screen and (max-width: 480px) {
      .swiper-container.swiper-container-home .swiper-pagination.swiper-pagination-home {
        display: none;
        left: 20px !important; } }
    .swiper-container.swiper-container-home .swiper-pagination.swiper-pagination-home .swiper-pagination-bullet {
      opacity: 1;
      background: #ffffff;
      transition: -webkit-transform 400ms cubic-bezier(0.17, 0.67, 0.83, 0.67);
      transition: transform 400ms cubic-bezier(0.17, 0.67, 0.83, 0.67);
      transition: transform 400ms cubic-bezier(0.17, 0.67, 0.83, 0.67), -webkit-transform 400ms cubic-bezier(0.17, 0.67, 0.83, 0.67);
      width: 14px;
      height: 14px; }
      .swiper-container.swiper-container-home .swiper-pagination.swiper-pagination-home .swiper-pagination-bullet.bullet-active {
        -webkit-transform: scale(1);
        transform: scale(1);
        background-color: #d76e00; }

.pricing .pricing__card {
  border-radius: 20px;
  overflow: hidden; }
  .pricing .pricing__card h1,
  .pricing .pricing__card h5 {
    margin: 0;
    color: var(--primary) !important;
    text-transform: uppercase; }
  .pricing .pricing__card h5 {
    font-weight: 400;
    letter-spacing: 3px; }
  .pricing .pricing__card h1 {
    line-height: 1;
    font-size: 3rem;
    padding-top: 8px;
    padding-bottom: 4px;
    font-weight: 500; }
  .pricing .pricing__card p {
    color: var(--text-muted);
    font-size: 1rem; }
  .pricing .pricing__card img {
    height: 150px;
    width: 150px; }

.upload-drop-box {
  height: 120px;
  width: 100%;
  border: 2px solid rgba(0, 0, 0, 0.08);
  border-radius: 4px; }

.drag-shadow {
  background: var(--primary);
  box-shadow: 3px 3px 10px rgba(var(--text-black), 0.2); }

.scrum-board .face-group .avatar,
.scrum-board .face-group-36 .avatar {
  border: 2px solid white;
  height: 24px;
  width: 24px; }
  .scrum-board .face-group .avatar:not(:first-child),
  .scrum-board .face-group-36 .avatar:not(:first-child) {
    margin-left: -8px; }

.scrum-board .face-group .number-avatar,
.scrum-board .face-group-36 .number-avatar {
  font-size: 12px;
  background: #e95455; }

.scrum-board .face-group-36 .avatar {
  height: 36px;
  width: 36px; }
  .scrum-board .face-group-36 .avatar:not(:first-child) {
    margin-left: -12px; }

.scrum-board .face-group-36 .number-avatar {
  font-size: 14px; }

.scrum-board .button-group button {
  min-width: 32px !important; }

.scrum-board .list-column {
  margin: 0px 12px; }
  .scrum-board .list-column .list-column__card {
    margin-bottom: 16px; }
  .scrum-board .list-column .list-column__card:last-child {
    margin-bottom: 0px; }

.scrum-board .list-column:first-child {
  margin: 0px 12px 0px 0px; }

.cart {
  min-width: 900px;
  overflow-x: scroll; }

.ecommerce__product-card {
  position: relative; }
  .ecommerce__product-card .product__image-box .product__price {
    position: absolute;
    font-weight: 500;
    background: var(--primary);
    color: white;
    padding: 4px 12px;
    right: 0;
    top: 24px;
    border-top-left-radius: 26px;
    border-bottom-left-radius: 26px;
    overflow: hidden;
    z-index: 4; }
  .ecommerce__product-card .product__image-box .image-box__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
    background: rgba(0, 0, 0, 0.74);
    z-index: 2; }
  .ecommerce__product-card:hover .image-box__overlay {
    display: flex;
    justify-content: center;
    align-items: center; }

.checkout .checkout__product-list hr:last-of-type {
  display: none !important; }

@font-face {
  font-family: UTM Cafeta;
  src: url("/assets/fonts/UTM Cafeta.ttf"); }

.btn.btn-light {
  padding: 12px 23px !important;
  background: rgba(255, 255, 255, 0.3) !important;
  border: 1px solid #ffffff !important;
  box-sizing: border-box !important;
  border-radius: 8px !important;
  color: #ffffff !important;
  -webkit-backdrop-filter: blur(4px) !important;
          backdrop-filter: blur(4px) !important; }

.clear-fix-150 {
  height: 120px; }

.mb-20px {
  margin-bottom: 20px !important; }

.mt-25px {
  margin-top: 25px !important; }

.ml-25px {
  margin-left: 25px !important; }

.fs-24px {
  font-size: 24px !important; }

.bgr-header {
  height: 420px;
  align-items: center;
  display: flex; }

.header-title {
  font-size: 64px;
  width: 608px;
  margin: auto;
  color: var(--orangy-yellow);
  line-height: 75px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.01em; }

.training-header-title {
  font-size: 64px;
  margin: auto;
  color: var(--orangy-yellow);
  line-height: 75px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.01em;
  text-shadow: 0px 2px 2px #000000; }

@media (min-width: 1280px) {
  .lg-pl-30 {
    padding-left: 30px; }
  .lg-pr-30 {
    padding-right: 30px; }
  .lg-h-94px {
    height: 94px !important; } }

::-webkit-scrollbar {
  width: 5px;
  height: 5px; }

::-webkit-scrollbar:hover {
  width: 12px !important; }

.MuiAutocomplete-option {
  font-size: 15px !important; }

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #007db3; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #007db3; }

.w-100px {
  width: 100px; }

.px-10px {
  padding: 10px 0px; }

@media (max-width: 1279.89px) {
  .lgd-h-62px {
    height: 62px !important; } }

.default-header-section {
  text-align: center; }

.mb-9px {
  margin-bottom: 9px; }

.default-header-section-h2 {
  font-family: 'UTM Cafeta';
  text-transform: uppercase;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  color: var(--text-blue);
  margin: 24px 0px;
  padding-bottom: 24px;
  position: relative;
  display: inline-block; }

.default-header-section-h2::after {
  content: "";
  position: absolute;
  width: 50%;
  bottom: 0;
  left: 25%;
  height: 5px;
  background-color: var(--orangy-yellow); }

.second-title-section-h3 {
  font-family: 'UTM Cafeta';
  text-transform: uppercase;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  color: var(--text-blue);
  padding: 30px 20px 10px 25px;
  margin: 24px 0px;
  padding-bottom: 24px;
  position: relative;
  display: inline-block; }

.second-title-section-h3::after {
  content: "";
  position: absolute;
  width: 50%;
  bottom: 0;
  left: 25%;
  height: 5px;
  background-color: var(--orangy-yellow); }

.about-title-section-h3 {
  font-family: 'UTM Cafeta';
  text-transform: uppercase;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  color: var(--text-blue);
  margin: 24px 0px;
  padding-bottom: 24px;
  position: relative;
  display: inline-block; }

.about-title-section-h3::after {
  content: "";
  position: absolute;
  width: 65%;
  bottom: 0;
  left: 14%;
  height: 5px;
  background-color: var(--orangy-yellow); }

.clear-both {
  clear: both; }

.layout2 .topbar {
  padding-top: 0;
  padding-bottom: 0;
  height: 150px; }

@media screen and (max-width: 960px) {
  .layout2 .topbar {
    height: 100px; }
  .training-header-title {
    font-size: 48px; } }

.align-item-end {
  align-items: flex-end; }

.MuiTableRow-root .MuiTableCell-head {
  padding-left: 20px;
  padding-right: 20px; }

.MuiTableRow-root .MuiTableCell-root {
  padding-left: 20px;
  padding-right: 20px; }

.MuiTableRow-root .MuiTableCell-paddingNone {
  padding: 0px; }

.MuiIconButton-root {
  cursor: pointer; }

.asset_department {
  background-color: #ffffff;
  padding: 8px;
  height: auto; }

.w-60 {
  width: 60%; }

.icon {
  display: flex; }

.fb-iconn {
  fill: white; }

.margin-auto {
  margin: auto; }

.none_wrap {
  white-space: nowrap !important; }

.validator-form-scroll-dialog {
  overflow-y: auto !important;
  display: flex !important;
  flex-direction: column !important; }

.logo-login {
  max-width: 400px !important; }

.logo-login img {
  width: 100% !important; }

.tb-charts-container .tb-charts-tab-label button {
  font-size: 0.6rem;
  max-width: 25%; }

.tb-charts-container .tb-charts-tab-label .MuiTab-root {
  min-width: unset; }

.map-show {
  position: relative;
  z-index: 9; }

.search-card {
  position: absolute;
  top: 90px;
  right: 5px;
  z-index: 10;
  border-radius: 5px;
  width: 250px; }

iframe {
  display: block;
  margin: 0 auto;
  padding: 0;
  /* iframes are inline by default */
  background: #ffffff;
  border: none;
  /* Reset default border */
  height: 331px;
  /* Viewport-relative units */
  width: 600px; }

.ol-popup {
  z-index: 0;
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-width: 280px; }

.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none; }

.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px; }

.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px; }

.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px; }

.ol-popup-closer:after {
  content: "?"; }

.styleColor {
  background-color: #358600;
  color: #ffffff; }

.close-style {
  position: absolute;
  right: 20px;
  top: 7px;
  font-size: small;
  width: 15px;
  height: 15px;
  color: #ebebeb; }

#atmospheric-c {
  height: 500;
  margin: 0 auto; }

.MuiSelect-select.MuiSelect-select {
  color: black !important; }

.MuiInputBase-input.Mui-disabled {
  color: black !important; }

.div-parent-chart {
  min-height: 400px; }

.div-child-chart {
  text-align: center; }

.lable-typeOrganization {
  font-size: 14px !important;
  -webkit-transform: translate(0, 1.5px) scale(0.75) !important;
          transform: translate(0, 1.5px) scale(0.75) !important;
  -webkit-transform-origin: top left !important;
          transform-origin: top left !important; }

.layout2-custom {
  overflow-y: visible !important; }

.full-screen-bg {
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh; }

.banner-item-description {
  max-width: 65%;
  padding: 50px 60px 37.5px 56px;
  text-align: left;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
  right: 23%;
  /* bottom: 40%; */ }

.banner-item-video {
  text-align: right;
  flex-grow: 1; }

.banner-item-video iframe {
  width: 509px !important;
  height: 517px !important; }

.banner-item {
  justify-content: center;
  display: flex;
  align-items: center !important;
  height: calc(100vh - 150px); }

.banner-item-description__header {
  font-size: 40px;
  color: #ffffff !important; }

.banner-item-description__content {
  font-size: 15px;
  color: #ffffff; }

.banner-item-description__submit .btn-light {
  padding: 12px 23px !important;
  font-weight: 700;
  font-size: 20px;
  line-height: 34px; }

.banner-item .banner-item-video img {
  display: inline;
  width: unset; }

.owl-carousel-banner {
  position: relative; }

.custom-prev-btn-banner {
  position: absolute;
  top: 50%;
  color: #ffffff;
  left: 20px;
  z-index: 999;
  font-size: 40px; }

.custom-next-btn-banner {
  color: #ffffff;
  position: absolute;
  z-index: 999;
  top: 50%;
  right: 20px;
  font-size: 40px; }

@media only screen and (max-width: 768px) {
  .banner-item-description {
    max-width: 100%; }
  .banner-item-video {
    display: none; }
  .owl-navigation-banner {
    display: none; }
  .carousel-home__button-prev {
    left: 0px; }
  .carousel-home__button-next {
    right: 0px; }
  .training-header-title {
    font-size: 36px; } }

#testimonial1 {
  background-image: url("/assets/images/home/bg-1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.25);
  background-blend-mode: color; }

.carousel-home__button-next,
.carousel-home__button-prev {
  color: #ffffff !important;
  position: absolute !important;
  top: calc(50% - 307px -25px) !important;
  -webkit-transform: translateY(calc(-50% - 50px));
          transform: translateY(calc(-50% - 50px));
  font-size: 70px !important;
  z-index: 1; }

.carousel-home__button-prev {
  left: 0px; }

.carousel-home__button-next {
  right: 0px; }

.carousel-home__button-next,
.carousel-home__button-prev {
  position: absolute !important;
  top: 50%;
  font-size: 40px !important;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  z-index: 1;
  background-color: transparent !important;
  box-shadow: none !important;
  height: 30px !important;
  width: 30px !important;
  min-height: 0 !important; }

.carousel-home__button-next .MuiSvgIcon-root,
.carousel-home__button-prev .MuiSvgIcon-root {
  font-size: 70px !important;
  bottom: 33%;
  position: absolute; }

.carousel-home__button-prev {
  left: -30px; }

.carousel-home__button-next {
  right: -30px; }

@media (max-width: 1440px) {
  .carousel-home__button-prev {
    left: 0px; }
  .carousel-home__button-next {
    right: 0px; } }

@media (max-width: 1024px) {
  .carousel-home__button-prev {
    display: none !important; }
  .carousel-home__button-next {
    display: none !important; } }

@media (max-width: 640px) {
  .carousel-home__button-prev {
    left: 0px; }
  .banner-item-description__header {
    font-size: 30px; }
  .carousel-home__button-next {
    right: 0px; }
  .training-header-title {
    font-size: 26px; } }

@media (max-width: 375px) {
  .banner-item-description__header {
    font-size: 20px;
    line-height: 30px; }
  .banner-item-description__content {
    font-size: 12px; }
  .training-header-title {
    font-size: 20px; } }

.carousel-home__button-prev.color-black,
.carousel-home__button-next.color-black {
  color: #000; }

.carousel-home__button-prev.color-black,
.carousel-home__button-next.color-black {
  right: #ffffff; }

@media screen and (min-width: 1900px) {
  .layout2 .container {
    margin-top: 0px; }
  .layout2 .horizontal-nav ul li ul a {
    white-space: nowrap; }
  .layout2 .horizontal-nav ul ul li {
    width: auto; }
  .layout2 .horizontal-nav ul ul ul {
    top: 0;
    position: absolute;
    left: 100%; } }

@media screen and (max-width: 1600px) and (min-width: 1400px) {
  .layout2 .container {
    margin-top: 0px; }
  .layout2 .horizontal-nav ul li ul a {
    white-space: nowrap; }
  .layout2 .horizontal-nav ul ul li {
    width: auto; }
  .layout2 .horizontal-nav ul ul ul {
    top: 0;
    position: absolute;
    left: 100%; } }

@media screen and (max-width: 959px) and (min-width: 600px) {
  .export_excel {
    padding-top: 60px !important;
    text-align: -webkit-left !important; }
  .default-header-section-h2 {
    font-size: 28px; } }

@media screen and (max-width: 960px) {
  .default-header-section-h2 {
    font-size: 28px; }
  .second-title-section-h3 {
    font-size: 28px; }
  .about-title-section-h3 {
    font-size: 28px; } }

@media screen and (max-width: 450px) {
  .second-title-section-h3 {
    width: 89%; }
  .second-title-section-h3::after {
    left: 135px; } }

@media screen and (max-width: 1400px) and (min-width: 1200px) {
  .layout2 .container {
    margin-top: 0px; }
  .layout2 .horizontal-nav ul li ul a {
    white-space: nowrap; }
  .layout2 .horizontal-nav ul ul li {
    width: auto; }
  .layout2 .horizontal-nav ul ul ul {
    top: 0;
    position: absolute;
    left: 100%; } }

.bt {
  float: right;
  margin: 0px; }

.plan_text {
  float: right;
  width: 50%;
  font-weight: bold;
  font-size: 1rem; }

.MuiOutlinedInput-adornedEnd {
  width: 120px; }

.body_content {
  margin-top: 20px; }

.h4_size {
  color: #ffffff !important; }

.text_inline {
  float: left; }

.btn_right {
  text-align: -webkit-right;
  margin-bottom: 10px !important; }

.btn_appro {
  margin-bottom: 5px !important; }

.tb_mt {
  margin-top: 30px !important; }

.export_excel {
  margin-top: 10px;
  text-align: right; }

.btn_exp_exc {
  margin-left: 8px !important;
  text-align: left; }

.planAp1 {
  margin-left: 0px !important; }

.planAp2 {
  margin-top: 48px !important; }

.planAppNoDup1 {
  padding-top: 48px !important; }

.planAppNoDup2 {
  text-align-last: center; }

.planAppNoDup3 {
  padding-top: 55px !important; }

.dialog_title {
  text-align: -webkit-center; }

.btn_actions {
  align-self: center; }

.title_dialog {
  text-align: -webkit-center; }

.inspection.label {
  color: white;
  padding: 5px;
  white-space: nowrap; }

.success {
  background-color: #04aa6d; }

/* Green */
.info {
  background-color: #2196f3; }

/* Blue */
.warning {
  background-color: #ff9800; }

/* Orange */
.danger {
  background-color: #f44336; }

/* Red */
.other {
  background-color: #e7e7e7;
  color: black !important; }

.btn_left {
  text-align: -webkit-left; }

.btn_add {
  color: #0ddd10; }

.btn_remv {
  color: red; }

.p-16 {
  padding: 8px; }

/* Gray */
.resultPerform {
  text-align: center !important; }

.resultPerform .MuiCheckbox-colorSecondary.Mui-checked {
  color: black; }

.inspection.checkBox {
  color: white;
  padding: 5px;
  min-width: 100px; }

.inspection.checkBox.follow {
  background-color: #00cc00; }

.info_mt_cent {
  padding-right: 10px;
  text-align: center;
  margin-top: 20px !important; }

.info_border1 {
  border: 1px solid green;
  padding: 5px; }

.info_border2 {
  border: 1px solid red;
  padding: 5px; }

.info_border3 {
  border: 1px solid cyan;
  padding: 5px; }

.info_border4 {
  border: 1px solid blue;
  padding: 5px; }

.info_border5 {
  border: 1px solid orange;
  padding: 5px; }

.btn_s_right {
  float: right; }

.btn-customer-base {
  padding: 10px; }

.excel_ico {
  width: 21px; }

.MuiTableCell-paddingNone {
  width: 30px !important;
  text-align: -webkit-center !important; }

.sub_table {
  min-height: 200px;
  width: 101%;
  margin-left: -5px;
  margin-top: -39px; }

.sub_header {
  background-color: gray !important; }

.MuiTableCell-alignLeft {
  text-align-last: left; }

#mui-99560 {
  display: none !important; }

.MuiToolbar-gutters {
  float: left !important; }

.MuiTablePagination-selectRoot {
  display: none !important; }

.status_div {
  padding: 5px;
  color: white;
  background-color: #0a6fff;
  text-align: -webkit-center; }

.enterprise_name {
  color: #2196f3; }

.time_span {
  font-weight: bold !important;
  margin-bottom: 10px !important; }

.update_dialog_title {
  background-color: #efefef !important; }

.inspectionsOrg-render {
  margin-top: 5px;
  color: "#4cd14b"; }

.inspectionsOrg-render > span:first-of-type {
  position: absolute; }

.inspectionsOrg-icon {
  position: relative; }

.inspectionsOrg-render .MuiSvgIcon-root {
  width: 18px;
  height: auto;
  color: #4cd14b; }

.inspectionsOrg-render .render-name {
  color: #08b8f1; }

.isUsing-render {
  margin-top: 5px;
  color: "#4cd14b"; }

.isUsing-render > span:first-of-type {
  position: absolute; }

.isUsing-icon {
  position: relative; }

.isUsing-render .MuiSvgIcon-root {
  width: 18px;
  height: auto;
  color: #08b8f1; }

.isUsing-render .render-name,
.inspectionsOrg-render .render-name {
  padding-left: 20px;
  font-weight: bold; }

.form-f {
  margin-top: 4px !important; }

.form-f1 {
  margin-top: 20px !important; }

.MuiTableCell-paddingNone:last-child {
  background-color: #e0e0e0; }

.align-center {
  text-align: center; }

.nowrap {
  white-space: nowrap; }

.table-customer-border .MuiTableRow-head,
.table-customer-border .MuiTableRow-root .MuiTableCell-head,
.table-customer-border .MuiTable-root,
.table-customer-border .MuiTableRow-root {
  border: 2px solid;
  font-weight: bold; }

.table-customer-border .MuiTableRow-root .MuiTableCell-root {
  text-align: center;
  padding-left: 5px;
  padding-right: 5px; }

.table-customer-border .MuiTableCell-body {
  border: 1px solid;
  font-weight: 400; }

.uppercase {
  text-transform: uppercase; }

.bold {
  font-weight: bold; }

.cus-label {
  color: white;
  padding: 5px;
  font-family: Arial; }

.cus-label.info {
  background-color: #2196f3; }

.btn-rlo {
  margin-top: 47px !important; }

.text-align-center {
  text-align: center; }

.text-align-end {
  text-align: end; }

.vertical-align-bottom {
  vertical-align: bottom !important; }
  .vertical-align-bottom .MuiInputBase-input {
    padding: 1px; }

.date {
  width: 30px; }

.year {
  width: 50px; }

.MuiTableCell-paddingNone > b {
  display: none !important; }

.cd-dialog5 {
  width: 300px; }

.cd-dialog6 {
  width: 700px; }

.cd-dialog7 {
  width: 700px; }

.cd-dialog98 {
  width: 647px; }

.cd-dialog9 {
  width: 600px; }

.cd-dialog1 {
  width: 700px; }

.cd-dialog2 {
  width: 620px; }

.cd-dialog3 {
  width: 700px; }

.cd-dialog {
  width: 800px; }

.cd-dialog5-1 {
  width: 545px; }

.cn-dialog-hr {
  width: 150px;
  font-weight: bold; }

.cn-dialog3 {
  width: 400px; }

.cn-dialog3-1 {
  width: 400px; }

.cn-dialog4 {
  width: 400px; }

.cn-dialog-ct {
  width: 450px; }

.cr-dialog-ct {
  width: 350px; }

.cr-dialog5 {
  width: 350px; }

.cr-dialog3 {
  width: 720px; }

.cr-dialog8 {
  width: 820px; }

.cr-dialog4 {
  width: 250px; }

.result-dialog {
  line-height: 2;
  padding: 0 30px; }

.result-dialog .MuiInput-root {
  padding-top: 5px; }

.result-dialog h5 .MuiInput-root {
  padding-top: 0; }

.result-dialog .MuiInputBase-input {
  padding: 0 0 0 5px; }

.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  -webkit-user-select: none;
  user-select: none; }

.btn.focus,
.btn:focus,
.btn:hover {
  color: #333;
  text-decoration: none; }

.btn-primary {
  color: #ffffff;
  background-color: #337ab7;
  border-color: #2e6da4; }

.btn-primary:hover {
  color: #ffffff;
  background-color: #286090;
  border-color: #204d74; }

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary:active.focus,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover {
  color: #ffffff;
  background-color: #204d74;
  border-color: #122b40; }

.btn-success {
  color: #ffffff;
  background-color: #5cb85c;
  border-color: #4cae4c; }

.btn-success:hover {
  color: #ffffff;
  background-color: #449d44;
  border-color: #398439; }

.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success:active.focus,
.btn-success:active:focus,
.btn-success:active:hover,
.open > .dropdown-toggle.btn-success.focus,
.open > .dropdown-toggle.btn-success:focus,
.open > .dropdown-toggle.btn-success:hover {
  color: #ffffff;
  background-color: #398439;
  border-color: #255625; }

.btn-danger {
  color: #ffffff;
  background-color: #d9534f;
  border-color: #d43f3a; }

.btn-danger:hover {
  color: #ffffff;
  background-color: #c9302c;
  border-color: #ac2925; }

.MuiGrid-container {
  margin-bottom: 20px; }

.object-report .MuiDialog-paperWidthLg {
  max-width: calc(100% - 64px); }

.mb-5 {
  margin-bottom: 5px !important; }

#date-picker-dialog-helper-text {
  display: none; }

.count-result {
  margin-left: 5px;
  font-size: 15px; }

.dialog-result-admin {
  margin-bottom: 25px !important;
  padding-bottom: 25px !important;
  border-bottom: 1px solid black; }

.title-dialog-follow {
  margin-top: 20px; }

.follow-up-filter {
  margin-left: 1px !important; }

.table-css {
  border: 1px #000 solid !important; }

.errorMesseges {
  color: red; }

.insFile {
  cursor: pointer; }

.recruitment .recruitment-position {
  padding-left: 120px;
  padding-right: 120px; }
  @media only screen and (max-width: 599px) {
    .recruitment .recruitment-position {
      padding-left: 20px;
      padding-right: 20px; } }
  @media only screen and (min-width: 600px) and (max-width: 959px) {
    .recruitment .recruitment-position {
      padding-left: 40px;
      padding-right: 40px; } }
  @media only screen and (min-width: 960px) and (max-width: 1279px) {
    .recruitment .recruitment-position {
      padding-left: 60px;
      padding-right: 60px; } }

.recruitment .title {
  padding-top: 30px;
  margin-bottom: 60px;
  font-size: 30px;
  color: #3e4199; }

.recruitment .job {
  margin-bottom: 20px; }
  .recruitment .job h4 {
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    margin: 0 0 10px;
    cursor: pointer; }
  .recruitment .job h4:hover {
    text-decoration: underline; }
  .recruitment .job .vessel {
    font-weight: bold;
    font-size: 13px; }
  .recruitment .job .salary {
    color: #e59a4e;
    margin-top: 5px; }
  .recruitment .job .dates {
    color: #6d797d; }

.recruitment button {
  font-family: "Roboto", sans-serif;
  border: none;
  background-color: #3e4199;
  border-radius: 5px;
  color: #ffffff;
  padding: 12px;
  font-size: 15px;
  box-shadow: 4px 3px 8px #999;
  cursor: pointer; }

.recruitment .link {
  text-decoration: underline;
  margin-left: 30px;
  font-size: 16px; }

.recruitment .recruitment-reputation {
  background-color: #d8d9ea; }
  .recruitment .recruitment-reputation .title {
    font-weight: 400;
    color: #1e217e;
    padding: 25px 0 50px 0;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around; }
    .recruitment .recruitment-reputation .title span {
      line-height: 40px; }
    .recruitment .recruitment-reputation .title .reputation__bold {
      line-height: 40px;
      text-transform: uppercase;
      color: #be6100;
      font-weight: 500;
      margin: 0; }
    .recruitment .recruitment-reputation .title .line {
      text-align: center;
      display: inline-block;
      width: 200px;
      border-bottom: 2px solid #be6100; }
  .recruitment .recruitment-reputation .reputation {
    padding-left: 120px;
    padding-right: 120px;
    flex-direction: column;
    margin: auto;
    align-items: center; }
    .recruitment .recruitment-reputation .reputation .title {
      align-items: center; }
      .recruitment .recruitment-reputation .reputation .title .line {
        align-items: center;
        margin-top: 15px; }
    @media only screen and (min-width: 960px) and (max-width: 1279px) {
      .recruitment .recruitment-reputation .reputation {
        padding-left: 60px;
        padding-right: 60px;
        flex-direction: column;
        margin: auto;
        align-items: center; }
        .recruitment .recruitment-reputation .reputation .about {
          padding: 0; }
        .recruitment .recruitment-reputation .reputation .title {
          align-items: center; }
          .recruitment .recruitment-reputation .reputation .title span {
            display: flex;
            justify-content: center; }
          .recruitment .recruitment-reputation .reputation .title .reputation__bold {
            display: flex;
            justify-content: center; }
          .recruitment .recruitment-reputation .reputation .title .line {
            align-items: center; } }
    @media only screen and (min-width: 600px) and (max-width: 959px) {
      .recruitment .recruitment-reputation .reputation {
        padding-left: 40px;
        padding-right: 40px;
        flex-direction: column;
        margin: auto;
        align-items: center; }
        .recruitment .recruitment-reputation .reputation .about {
          padding: 0; }
        .recruitment .recruitment-reputation .reputation .title {
          align-items: center; }
          .recruitment .recruitment-reputation .reputation .title span {
            display: flex;
            justify-content: center; }
          .recruitment .recruitment-reputation .reputation .title .reputation__bold {
            display: flex;
            justify-content: center; }
          .recruitment .recruitment-reputation .reputation .title .line {
            align-items: center; } }
    @media only screen and (max-width: 730px) {
      .recruitment .recruitment-reputation .reputation {
        padding-left: 20px;
        padding-right: 20px;
        flex-direction: column;
        margin: auto;
        align-items: center; }
        .recruitment .recruitment-reputation .reputation .about {
          padding: 0; }
        .recruitment .recruitment-reputation .reputation .title {
          align-items: center; }
          .recruitment .recruitment-reputation .reputation .title span {
            font-size: 30px;
            display: flex;
            justify-content: center; }
          .recruitment .recruitment-reputation .reputation .title .reputation__bold {
            display: flex;
            justify-content: center;
            line-height: 30px; }
          .recruitment .recruitment-reputation .reputation .title .line {
            align-items: center; } }
    @media only screen and (max-width: 599px) {
      .recruitment .recruitment-reputation .reputation {
        padding-left: 20px;
        padding-right: 20px;
        flex-direction: column;
        margin: auto;
        align-items: center; }
        .recruitment .recruitment-reputation .reputation .about {
          padding: 0; }
        .recruitment .recruitment-reputation .reputation .title {
          align-items: center; }
          .recruitment .recruitment-reputation .reputation .title span {
            font-size: 20px;
            display: flex;
            justify-content: center; }
          .recruitment .recruitment-reputation .reputation .title .reputation__bold {
            display: flex;
            justify-content: center;
            line-height: 20px; }
          .recruitment .recruitment-reputation .reputation .title .line {
            align-items: center; } }
    .recruitment .recruitment-reputation .reputation .about {
      margin: 0;
      list-style: none; }
      .recruitment .recruitment-reputation .reputation .about li {
        line-height: 1.4;
        margin-bottom: 10px;
        font-size: 18px;
        display: flex; }
        .recruitment .recruitment-reputation .reputation .about li svg {
          color: #be6100;
          margin-right: 15px;
          font-size: 22px; }
        .recruitment .recruitment-reputation .reputation .about li p {
          margin: 0;
          text-align: justify; }

.member {
  list-style: none;
  padding-left: 120px;
  padding-right: 120px;
  margin-bottom: 30px; }
  .member .title {
    padding-top: 30px;
    margin-bottom: 30px;
    font-size: 28px;
    color: #3e4199; }
  .member .member_content {
    flex-direction: row-reverse; }
  .member li {
    font-size: 18px;
    line-height: 1.3;
    margin-bottom: 10px; }
  .member button {
    font-family: "Roboto", sans-serif;
    border: none;
    background-color: #3e4199;
    border-radius: 5px;
    color: #ffffff;
    padding: 12px 30px;
    font-size: 15px;
    box-shadow: 4px 3px 8px #999;
    margin-top: 30px;
    cursor: pointer; }
  .member img {
    border-radius: 5px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 75%; }

@media only screen and (min-width: 960px) and (max-width: 1279px) {
  .member {
    padding-left: 60px;
    padding-right: 60px; } }

@media only screen and (min-width: 600px) and (max-width: 959px) {
  .member {
    padding-left: 40px;
    padding-right: 40px; } }

@media only screen and (max-width: 599px) {
  .member {
    padding-left: 20px;
    padding-right: 20px; } }

.aboutus {
  padding-left: 120px;
  padding-right: 120px; }
  .aboutus .aboutus__review {
    border-radius: 8px; }
    .aboutus .aboutus__review .aboutus__intro {
      padding-right: 10px; }
    .aboutus .aboutus__review .title {
      margin-bottom: 10px;
      font-size: 28px;
      color: #3e4199; }
    .aboutus .aboutus__review .text {
      font-size: 18px;
      text-align: justify; }
      @media only screen and (min-width: 1280px) {
        .aboutus .aboutus__review .text {
          padding-right: 15px; } }
    .aboutus .aboutus__review .aboutus__video {
      height: 400px;
      border-radius: 8px; }
  .aboutus .static {
    background-color: #d8d9ea;
    min-height: 100px;
    padding: 8px 16px;
    border-top-left-radius: 15px;
    border-bottom-right-radius: 15px;
    min-height: 100px;
    padding: 8px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px; }
  .aboutus .static.reverse {
    border-radius: 0;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 15px; }
  .aboutus .static_box {
    margin-top: 20px; }
  .aboutus .static p {
    margin: 0; }
  .aboutus .static h6.static__title {
    padding-top: 8px;
    color: #e59a4e; }
  .aboutus .static .static__owner {
    text-align: right; }
  .aboutus .reponsive_order {
    position: relative; }
    .aboutus .reponsive_order .reponsive_order_img {
      padding: 0 5px;
      text-align: center; }
    .aboutus .reponsive_order .reponsive_order_content {
      width: 300px;
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
      .aboutus .reponsive_order .reponsive_order_content p {
        font-size: 20px;
        color: #d76e00;
        text-align: center; }
        .aboutus .reponsive_order .reponsive_order_content p b {
          font-size: 20px;
          color: #d76e00;
          text-align: center; }
  .aboutus .aboutus__leader {
    background-color: rgba(197, 197, 223, 0.3);
    padding: 50px; }
    .aboutus .aboutus__leader .aboutus__leader-slogan {
      width: 100%;
      color: #d76e00;
      font-size: 24px;
      font-weight: 500;
      text-align: center;
      margin-bottom: 50px;
      text-transform: uppercase; }
      .aboutus .aboutus__leader .aboutus__leader-slogan p {
        text-shadow: 0 5px 2px rgba(215, 110, 0, 0.15);
        margin: 0; }
    .aboutus .aboutus__leader .aboutus__leader-ceo {
      background-color: #ffffff;
      padding: 30px;
      margin-bottom: 50px; }
      .aboutus .aboutus__leader .aboutus__leader-ceo .ceo__content-wrap .ceo__content.ceo__content-text.ceo__content-text-1 {
        text-align: right; }
      .aboutus .aboutus__leader .aboutus__leader-ceo .ceo__content-wrap .ceo__content.ceo__content-text.ceo__content-text-2 {
        text-align: left; }
      .aboutus .aboutus__leader .aboutus__leader-ceo .ceo__content-wrap .ceo__content h6 {
        color: #d76e00;
        font-size: 16px;
        line-height: 22px; }
      .aboutus .aboutus__leader .aboutus__leader-ceo .ceo__content-wrap .ceo__content p {
        color: #00016d;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px; }
      .aboutus .aboutus__leader .aboutus__leader-ceo .ceo__content-wrap .ceo__content .ceo__content-img {
        margin-bottom: 15px; }
        .aboutus .aboutus__leader .aboutus__leader-ceo .ceo__content-wrap .ceo__content .ceo__content-img img {
          vertical-align: middle; }
      .aboutus .aboutus__leader .aboutus__leader-ceo .ceo__info {
        text-align: center; }
        .aboutus .aboutus__leader .aboutus__leader-ceo .ceo__info div {
          display: inline-block;
          padding: 10px 0;
          max-width: 260px;
          width: 260px;
          background-color: #3e3f95;
          color: #ffffff;
          border-radius: 8px; }
          .aboutus .aboutus__leader .aboutus__leader-ceo .ceo__info div h6 {
            margin: 0;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            text-transform: uppercase; }
          .aboutus .aboutus__leader .aboutus__leader-ceo .ceo__info div p {
            margin: 0;
            line-height: 22px; }
    .aboutus .aboutus__leader .aboutus__leader-viceceo .viceceo__member {
      padding: 0 15px; }
      .aboutus .aboutus__leader .aboutus__leader-viceceo .viceceo__member .viceceo__member-content {
        width: 100%;
        overflow: hidden; }
        .aboutus .aboutus__leader .aboutus__leader-viceceo .viceceo__member .viceceo__member-content .content__name {
          width: 100%;
          background-color: #3e3f95;
          color: #ffffff;
          padding: 10px 0;
          text-align: center; }
          .aboutus .aboutus__leader .aboutus__leader-viceceo .viceceo__member .viceceo__member-content .content__name h6 {
            margin: 0;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            text-transform: uppercase; }
          .aboutus .aboutus__leader .aboutus__leader-viceceo .viceceo__member .viceceo__member-content .content__name p {
            margin: 0;
            line-height: 22px;
            font-size: 14px; }
        .aboutus .aboutus__leader .aboutus__leader-viceceo .viceceo__member .viceceo__member-content .content__slogan-and-avt {
          width: 100%;
          padding: 10px; }
          .aboutus .aboutus__leader .aboutus__leader-viceceo .viceceo__member .viceceo__member-content .content__slogan-and-avt .content__avt {
            width: 100%;
            padding: 15px 0;
            text-align: center; }
            .aboutus .aboutus__leader .aboutus__leader-viceceo .viceceo__member .viceceo__member-content .content__slogan-and-avt .content__avt img {
              vertical-align: middle; }
          .aboutus .aboutus__leader .aboutus__leader-viceceo .viceceo__member .viceceo__member-content .content__slogan-and-avt .content__slogan {
            width: 100%;
            margin-bottom: 15px; }
            .aboutus .aboutus__leader .aboutus__leader-viceceo .viceceo__member .viceceo__member-content .content__slogan-and-avt .content__slogan:last-child {
              margin-bottom: 0; }
            .aboutus .aboutus__leader .aboutus__leader-viceceo .viceceo__member .viceceo__member-content .content__slogan-and-avt .content__slogan h6 {
              color: #d76e00;
              font-size: 16px;
              line-height: 22px; }
            .aboutus .aboutus__leader .aboutus__leader-viceceo .viceceo__member .viceceo__member-content .content__slogan-and-avt .content__slogan p {
              color: #00016d;
              font-size: 16px;
              font-weight: 400;
              line-height: 22px;
              margin: 0; }
    @media only screen and (max-width: 1279px) {
      .aboutus .aboutus__leader .aboutus__leader-viceceo .viceceo__member {
        margin-bottom: 20px; }
        .aboutus .aboutus__leader .aboutus__leader-viceceo .viceceo__member:last-child {
          margin-bottom: 0; }
        .aboutus .aboutus__leader .aboutus__leader-viceceo .viceceo__member .viceceo__member-content .content__slogan-and-avt {
          padding: 15px; } }
    @media only screen and (min-width: 960px) and (max-width: 1279px) {
      .aboutus .aboutus__leader .aboutus__leader-ceo .ceo__content.ceo__content-text {
        padding: 0 10px; } }
    @media only screen and (min-width: 600px) and (max-width: 959px) {
      .aboutus .aboutus__leader .aboutus__leader-ceo .ceo__content-wrap.ceo__content-wrap-img {
        order: -1; }
      .aboutus .aboutus__leader .aboutus__leader-ceo .ceo__content-wrap .ceo__content.ceo__content-text.ceo__content-text-1, .aboutus .aboutus__leader .aboutus__leader-ceo .ceo__content-wrap .ceo__content.ceo__content-text.ceo__content-text-2 {
        text-align: left; } }
    @media only screen and (max-width: 599px) {
      .aboutus .aboutus__leader {
        background-color: #ffffff;
        padding: 0; }
        .aboutus .aboutus__leader .aboutus__leader-slogan {
          margin-bottom: 30px; }
          .aboutus .aboutus__leader .aboutus__leader-slogan p {
            max-width: 250px;
            margin-left: auto;
            margin-right: auto; }
        .aboutus .aboutus__leader .aboutus__leader-viceceo .viceceo__member {
          padding: 0; } }
  @media only screen and (max-width: 1279px) {
    .aboutus {
      padding-left: 20px;
      padding-right: 20px; }
      .aboutus .aboutus__review {
        width: 400px; }
      .aboutus .reponsive_order {
        order: -1; }
      .aboutus .reposive_layout {
        margin-top: 20px; }
      .aboutus .aboutus__video {
        order: -1; }
      .aboutus .reponsive_order_title {
        order: -2; }
      .aboutus .static1,
      .aboutus .static2,
      .aboutus .static3,
      .aboutus .static4,
      .aboutus .static5,
      .aboutus .static6 {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
        width: 100%; }
        .aboutus .static1 .static__text,
        .aboutus .static2 .static__text,
        .aboutus .static3 .static__text,
        .aboutus .static4 .static__text,
        .aboutus .static5 .static__text,
        .aboutus .static6 .static__text {
          margin-bottom: 0; } }

.certificates .certificates__background {
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px; }
  @media only screen and (max-width: 1280px) {
    .certificates .certificates__background .mfp-arrow {
      display: none; } }
  .certificates .certificates__background .title {
    margin-bottom: 40px;
    font-size: 28px;
    color: #3e4199; }
  .certificates .certificates__background .text {
    font-size: 18px;
    text-align: justify; }
  .certificates .certificates__background .mfp-arrow {
    position: absolute !important;
    top: 63%;
    z-index: 99; }
  .certificates .certificates__background .mfp-arrow-left {
    left: 50px; }
  .certificates .certificates__background .mfp-arrow-right {
    right: 50px; }

.h-50vh {
  height: 50vh; }

.footer {
  box-sizing: border-box; }
  .footer .footer__wrapper {
    padding-left: 120px;
    padding-right: 120px;
    padding-top: 35px;
    padding-bottom: 35px; }
    .footer .footer__wrapper .footer__contact .footer__contact-wrapper p {
      margin: 0 0 5px; }
      .footer .footer__wrapper .footer__contact .footer__contact-wrapper p:last-child {
        margin-bottom: 0; }
    .footer .footer__wrapper .footer__contact .footer__contact-wrapper .footer__contact-icons {
      margin-top: 20px; }
      .footer .footer__wrapper .footer__contact .footer__contact-wrapper .footer__contact-icons img {
        display: inline-block;
        width: 30px;
        margin-right: 10px; }
    .footer .footer__wrapper .footer__contact .footer__grd_logo .footer__logo {
      display: block; }
    .footer .footer__wrapper .footer__menu a {
      display: flex; }
      .footer .footer__wrapper .footer__menu a p {
        font-weight: bold;
        font-size: 16px;
        margin-top: 0;
        margin-bottom: 16px; }
  .footer .footer__copyright {
    font-size: 15px;
    padding: 10px 0;
    margin: 0;
    text-align: center;
    border-top: 1px solid rgba(255, 255, 255, 0.7);
    color: rgba(255, 255, 255, 0.7); }
  @media only screen and (min-width: 600px) and (max-width: 959px) {
    .footer .footer__wrapper {
      padding: 20px 20px 20px 20px; }
      .footer .footer__wrapper .footer__menu {
        float: right; }
        .footer .footer__wrapper .footer__menu a p {
          font-size: 15px; }
      .footer .footer__wrapper .footer__contact .footer__contact-wrapper p {
        font-size: 15px; } }
  @media only screen and (max-width: 599px) {
    .footer .footer__wrapper {
      padding: 20px;
      position: relative; }
      .footer .footer__wrapper .footer__content {
        flex-direction: column-reverse; }
        .footer .footer__wrapper .footer__content .footer__menu {
          margin: 0 auto;
          display: block;
          width: 180px; }
          .footer .footer__wrapper .footer__content .footer__menu div {
            margin: 0 !important; }
          .footer .footer__wrapper .footer__content .footer__menu a p {
            width: 100%;
            text-align: center; }
      .footer .footer__wrapper .footer__grd_logo {
        position: absolute; } }
  @media only screen and (max-width: 450px) {
    .footer .footer__wrapper .footer__grd_logo {
      position: absolute; }
      .footer .footer__wrapper .footer__grd_logo .footer__logo {
        width: 60px; } }
  @media only screen and (max-width: 350px) {
    .footer .footer__wrapper .footer__grd_logo {
      position: absolute; }
      .footer .footer__wrapper .footer__grd_logo .footer__logo {
        width: 60px; } }

.btn-primary {
  background: #f16920 !important;
  background: linear-gradient(to bottom, #f3821c 0%, #f05322 100%) !important;
  border-color: #f16920 !important;
  font-weight: bold !important;
  text-shadow: 0 1px 2px black; }

.link {
  color: #08c;
  text-decoration: none; }

.layout2 .topbar {
  padding-top: 0;
  padding-bottom: 0;
  background-image: url("/assets/images/home/background-header.svg"); }

.MuiTableRow-root .MuiTableCell-head {
  padding-left: 20px;
  padding-right: 20px; }

.MuiTableRow-root .MuiTableCell-root {
  padding-left: 20px;
  padding-right: 20px; }

.MuiTableRow-root .MuiTableCell-paddingNone {
  padding: 0px; }

.MuiIconButton-root {
  cursor: pointer; }

.asset_department {
  background-color: #ffffff;
  padding: 8px;
  height: auto; }

.w-60 {
  width: 60%; }

.margin-auto {
  margin: auto; }

.none_wrap {
  white-space: nowrap !important; }

.validator-form-scroll-dialog {
  overflow-y: auto !important;
  display: flex !important;
  flex-direction: column !important; }

.logo-login {
  max-width: 400px !important; }

.logo-login img {
  width: 100% !important; }

.tb-charts-container .tb-charts-tab-label button {
  font-size: 0.6rem;
  max-width: 25%; }

.tb-charts-container .tb-charts-tab-label .MuiTab-root {
  min-width: unset; }

.map-show {
  position: relative;
  z-index: 9; }

.search-card {
  position: absolute;
  top: 90px;
  right: 5px;
  z-index: 10;
  border-radius: 5px;
  width: 250px; }

iframe {
  display: block;
  margin: 0 auto;
  padding: 0;
  /* iframes are inline by default */
  background: #ffffff;
  border: none;
  /* Reset default border */
  height: 250px;
  /* Viewport-relative units */
  width: 600px;
  min-width: 300px; }

.ol-popup {
  z-index: 0;
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-width: 280px; }

.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none; }

.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px; }

.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px; }

.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px; }

.ol-popup-closer:after {
  content: "?"; }

.styleColor {
  background-color: #358600;
  color: #ffffff; }

.close-style {
  position: absolute;
  right: 20px;
  top: 7px;
  font-size: small;
  width: 15px;
  height: 15px;
  color: #ebebeb; }

#atmospheric-c {
  height: 500;
  margin: 0 auto; }

.MuiSelect-select.MuiSelect-select {
  color: black !important; }

.color-white {
  color: #ffffff !important; }

.MuiInputBase-input.Mui-disabled {
  color: black !important; }

.div-parent-chart {
  min-height: 400px; }

.div-child-chart {
  text-align: center; }

.lable-typeOrganization {
  font-size: 14px !important;
  -webkit-transform: translate(0, 1.5px) scale(0.75) !important;
          transform: translate(0, 1.5px) scale(0.75) !important;
  -webkit-transform-origin: top left !important;
          transform-origin: top left !important; }

@media screen and (min-width: 1900px) {
  .layout2 .container {
    margin-top: 0px; }
  .layout2 .horizontal-nav ul li ul a {
    white-space: nowrap; }
  .layout2 .horizontal-nav ul ul li {
    width: auto; }
  .layout2 .horizontal-nav ul ul ul {
    top: 0;
    position: absolute;
    left: 100%; } }

@media screen and (max-width: 1600px) and (min-width: 1400px) {
  .layout2 .container {
    margin-top: 0px; }
  .layout2 .horizontal-nav ul li ul a {
    white-space: nowrap; }
  .layout2 .horizontal-nav ul ul li {
    width: auto; }
  .layout2 .horizontal-nav ul ul ul {
    top: 0;
    position: absolute;
    left: 100%; } }

@media screen and (max-width: 959px) and (min-width: 600px) {
  .export_excel {
    padding-top: 60px !important;
    text-align: -webkit-left !important; } }

@media screen and (max-width: 1400px) and (min-width: 1200px) {
  .layout2 .container {
    margin-top: 0px; }
  .layout2 .horizontal-nav ul li ul a {
    white-space: nowrap; }
  .layout2 .horizontal-nav ul ul li {
    width: auto; }
  .layout2 .horizontal-nav ul ul ul {
    top: 0;
    position: absolute;
    left: 100%; } }

.plan_text {
  float: right;
  width: 50%;
  font-weight: bold;
  font-size: 1rem; }

.MuiOutlinedInput-adornedEnd {
  width: 120px; }

.body_content {
  margin-top: 20px; }

.static_1 {
  background-color: #72b1db;
  padding: 15px;
  border-radius: 10px;
  color: #ffffff;
  min-height: 170px; }

.static_2 {
  background-color: #cc5757;
  padding: 15px;
  border-radius: 10px;
  color: #ffffff;
  min-height: 170px; }

.static_3 {
  background-color: #8b61b5;
  padding: 15px;
  border-radius: 10px;
  color: #ffffff;
  min-height: 170px; }

.static_4 {
  background-color: #af7d56;
  padding: 15px;
  border-radius: 10px;
  color: #ffffff;
  min-height: 170px; }

.h4_size {
  color: #ffffff !important; }

.text_inline {
  float: left; }

.btn_right {
  text-align: -webkit-right;
  margin-bottom: 10px !important; }

.btn_appro {
  margin-bottom: 5px !important; }

.tb_mt {
  margin-top: 30px !important; }

.export_excel {
  margin-top: 10px;
  text-align: right; }

.btn_exp_exc {
  margin-left: 8px !important;
  text-align: left; }

.planAp1 {
  margin-left: 0px !important; }

.planAp2 {
  margin-top: 48px !important; }

.planAppNoDup1 {
  padding-top: 48px !important; }

.planAppNoDup2 {
  text-align-last: center; }

.planAppNoDup3 {
  padding-top: 55px !important; }

.dialog_title {
  text-align: -webkit-center; }

.btn_actions {
  align-self: center; }

.title_dialog {
  text-align: -webkit-center; }

.inspection.label {
  color: white;
  padding: 5px;
  white-space: nowrap; }

.success {
  background-color: #04aa6d; }

/* Green */
.info {
  background-color: #2196f3; }

/* Blue */
.warning {
  background-color: #ff9800; }

/* Orange */
.danger {
  background-color: #f44336; }

/* Red */
.other {
  background-color: #e7e7e7;
  color: black !important; }

.btn_left {
  text-align: -webkit-left; }

.btn_add {
  color: #0ddd10; }

.btn_remv {
  color: red; }

.p-16 {
  padding: 8px; }

/* Gray */
.resultPerform {
  text-align: center !important; }

.resultPerform .MuiCheckbox-colorSecondary.Mui-checked {
  color: black; }

.inspection.checkBox {
  color: white;
  padding: 5px;
  min-width: 100px; }

.inspection.checkBox.follow {
  background-color: #00cc00; }

.info_mt_cent {
  padding-right: 10px;
  text-align: center;
  margin-top: 20px !important; }

.info_border1 {
  border: 1px solid green;
  padding: 5px; }

.info_border2 {
  border: 1px solid red;
  padding: 5px; }

.info_border3 {
  border: 1px solid cyan;
  padding: 5px; }

.info_border4 {
  border: 1px solid blue;
  padding: 5px; }

.info_border5 {
  border: 1px solid orange;
  padding: 5px; }

.btn_s_right {
  float: right; }

.btn-customer-base {
  padding: 10px; }

.excel_ico {
  width: 21px; }

.MuiTableCell-paddingNone {
  width: 30px !important;
  text-align: -webkit-center !important; }

.sub_table {
  min-height: 200px;
  width: 101%;
  margin-left: -5px;
  margin-top: -39px; }

.sub_header {
  background-color: gray !important; }

.MuiTableCell-alignLeft {
  text-align-last: left; }

#mui-99560 {
  display: none !important; }

.MuiToolbar-gutters {
  float: left !important; }

.MuiTablePagination-selectRoot {
  display: none !important; }

.status_div {
  padding: 5px;
  color: white;
  background-color: #0a6fff;
  text-align: -webkit-center; }

.enterprise_name {
  color: #2196f3; }

.time_span {
  font-weight: bold !important;
  margin-bottom: 10px !important; }

.update_dialog_title {
  background-color: #efefef !important; }

.inspectionsOrg-render {
  margin-top: 5px;
  color: "#4cd14b"; }

.inspectionsOrg-render > span:first-of-type {
  position: absolute; }

.inspectionsOrg-icon {
  position: relative; }

.inspectionsOrg-render .MuiSvgIcon-root {
  width: 18px;
  height: auto;
  color: #4cd14b; }

.inspectionsOrg-render .render-name {
  color: #08b8f1; }

.isUsing-render {
  margin-top: 5px;
  color: "#4cd14b"; }

.isUsing-render > span:first-of-type {
  position: absolute; }

.isUsing-icon {
  position: relative; }

.isUsing-render .MuiSvgIcon-root {
  width: 18px;
  height: auto;
  color: #08b8f1; }

.isUsing-render .render-name,
.inspectionsOrg-render .render-name {
  padding-left: 20px;
  font-weight: bold; }

.form-f {
  margin-top: 4px !important; }

.form-f1 {
  margin-top: 20px !important; }

.MuiTableCell-paddingNone:last-child {
  background-color: #e0e0e0; }

.align-center {
  text-align: center; }

.nowrap {
  white-space: nowrap; }

.table-customer-border .MuiTableRow-head,
.table-customer-border .MuiTableRow-root .MuiTableCell-head,
.table-customer-border .MuiTable-root,
.table-customer-border .MuiTableRow-root {
  border: 2px solid;
  font-weight: bold; }

.table-customer-border .MuiTableRow-root .MuiTableCell-root {
  text-align: center;
  padding-left: 5px;
  padding-right: 5px; }

.table-customer-border .MuiTableCell-body {
  border: 1px solid;
  font-weight: 400; }

.uppercase {
  text-transform: uppercase; }

.bold {
  font-weight: bold; }

.cus-label {
  color: white;
  padding: 5px;
  font-family: Arial; }

.cus-label.info {
  background-color: #2196f3; }

.btn-rlo {
  margin-top: 47px !important; }

.text-align-center {
  text-align: center; }

.text-align-end {
  text-align: end; }

.vertical-align-bottom {
  vertical-align: bottom !important; }
  .vertical-align-bottom .MuiInputBase-input {
    padding: 1px; }

.date {
  width: 30px; }

.year {
  width: 50px; }

.MuiTableCell-paddingNone > b {
  display: none !important; }

.cd-dialog5 {
  width: 300px; }

.cd-dialog6 {
  width: 700px; }

.cd-dialog7 {
  width: 700px; }

.cd-dialog98 {
  width: 647px; }

.cd-dialog9 {
  width: 600px; }

.cd-dialog1 {
  width: 700px; }

.cd-dialog2 {
  width: 620px; }

.cd-dialog3 {
  width: 700px; }

.cd-dialog {
  width: 800px; }

.cd-dialog5-1 {
  width: 545px; }

.cn-dialog-hr {
  width: 150px;
  font-weight: bold; }

.cn-dialog3 {
  width: 400px; }

.cn-dialog3-1 {
  width: 400px; }

.cn-dialog4 {
  width: 400px; }

.cn-dialog-ct {
  width: 450px; }

.cr-dialog-ct {
  width: 350px; }

.cr-dialog5 {
  width: 350px; }

.cr-dialog3 {
  width: 720px; }

.cr-dialog8 {
  width: 820px; }

.cr-dialog4 {
  width: 250px; }

.result-dialog {
  line-height: 2;
  padding: 0 30px; }

.result-dialog .MuiInput-root {
  padding-top: 5px; }

.result-dialog h5 .MuiInput-root {
  padding-top: 0; }

.result-dialog .MuiInputBase-input {
  padding: 0 0 0 5px; }

.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  -webkit-user-select: none;
  user-select: none; }

.btn.focus,
.btn:focus,
.btn:hover {
  color: #333;
  text-decoration: none; }

.btn-primary {
  color: #ffffff;
  background-color: #337ab7;
  border-color: #2e6da4; }

.btn-primary:hover {
  color: #ffffff;
  background-color: #286090;
  border-color: #204d74; }

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary:active.focus,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover {
  color: #ffffff;
  background-color: #204d74;
  border-color: #122b40; }

.btn-success {
  color: #ffffff;
  background-color: #5cb85c;
  border-color: #4cae4c; }

.btn-success:hover {
  color: #ffffff;
  background-color: #449d44;
  border-color: #398439; }

.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success:active.focus,
.btn-success:active:focus,
.btn-success:active:hover,
.open > .dropdown-toggle.btn-success.focus,
.open > .dropdown-toggle.btn-success:focus,
.open > .dropdown-toggle.btn-success:hover {
  color: #ffffff;
  background-color: #398439;
  border-color: #255625; }

.btn-danger {
  color: #ffffff;
  background-color: #d9534f;
  border-color: #d43f3a; }

.btn-danger:hover {
  color: #ffffff;
  background-color: #c9302c;
  border-color: #ac2925; }

.MuiGrid-container {
  margin-bottom: 20px; }

.object-report .MuiDialog-paperWidthLg {
  max-width: calc(100% - 64px); }

.mb-5 {
  margin-bottom: 5px !important; }

#date-picker-dialog-helper-text {
  display: none; }

.count-result {
  margin-left: 5px;
  font-size: 15px; }

.dialog-result-admin {
  margin-bottom: 25px !important;
  padding-bottom: 25px !important;
  border-bottom: 1px solid black; }

.title-dialog-follow {
  margin-top: 20px; }

.follow-up-filter {
  margin-left: 1px !important; }

.table-css {
  border: 1px #000 solid !important; }

.errorMesseges {
  color: red; }

.insFile {
  cursor: pointer; }

.certificates__list-img {
  vertical-align: middle; }

.certificate__wrapper-img {
  position: relative; }

.certificate__wrapper-img:hover .certificate__overlay-img {
  visibility: visible;
  display: block;
  opacity: 1;
  -webkit-transform: scale(1) !important;
  transform: scale(1) !important;
  cursor: pointer; }

.language-select {
  display: flex;
  flex-wrap: wrap;
  margin-left: 25px; }

.language-select div,
.language-select svg {
  color: var(--text-blue) !important; }

.language-select > div > div::before {
  display: none !important; }

.certificate__overlay-img {
  background-color: rgba(99, 178, 195, 0.7);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  color: #ffffff;
  opacity: 0;
  display: block;
  -webkit-transform: scale(0.15);
  transform: scale(0.15);
  transition: all 0.15s ease-out 0s; }

.certificate__overlay-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -20px;
  margin-top: -25px;
  font-size: 45px !important; }

.certificate__overlay-img span {
  position: absolute;
  font-size: 15px;
  top: 60%;
  left: 50%;
  margin-left: -20px; }

.certificates__zoom-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1043;
  cursor: pointer; }
  .certificates__zoom-wrapper .certificates__zoom-container {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0 8px;
    box-sizing: border-box; }
    .certificates__zoom-wrapper .certificates__zoom-container .certificates__zoom-button {
      width: 100%;
      text-align: right; }
  .certificates__zoom-wrapper .mfp-arrow {
    position: absolute !important;
    top: 50%; }
  .certificates__zoom-wrapper .mfp-arrow-left {
    left: 10px; }
  .certificates__zoom-wrapper .mfp-arrow-right {
    right: 10px; }
  .certificates__zoom-wrapper .certificates__zoom-icon-color {
    color: #ffffff; }

.dialog-fixed-color label.Mui-focused {
  color: #7467ef !important; }

.dialog-fixed-color .Mui-focused::after {
  border-color: #7467ef !important; }

.breadcrumb__home {
  font-size: 0.875rem;
  line-height: 1rem;
  font-weight: 400;
  padding-left: 8px; }

.breadcrumb__home_title {
  margin-left: 2px;
  padding: 2px; }

.breadcrumb__title {
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 700; }

@media screen and (max-width: 425px) {
  .breadcrumb__home_icon {
    width: 25px; }
  .breadcrumb__title:last-child {
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; } }

.bg-certificate-our-teams {
  background-image: url("/assets/images/home/bg-frame.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 30px; }

.w-100 .MuiInputBase-root {
  width: 100%; }

.align-items-baseline {
  align-items: baseline; }

@media screen and (max-width: 450px) {
  .bgr-header {
    height: 250px; } }

.btn {
  display: inline-block !important;
  font-weight: 400 !important;
  text-align: center !important;
  white-space: nowrap !important;
  vertical-align: middle !important;
  -webkit-user-select: none !important;
  user-select: none !important;
  border: 1px solid transparent !important;
  padding: 6px 16px !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  border-radius: 20px !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important; }

.btn-primary {
  color: #ffffff !important;
  background-color: #2196f3 !important;
  border-color: #2196f3 !important; }

.btn-primary-d {
  color: #ffffff !important;
  background-color: #2a80c8 !important;
  border-color: #2a80c8 !important; }

.btn-secondary {
  color: #ffffff !important;
  background-color: #00bfa5 !important;
  border-color: #00bfa5 !important; }

.btn-success {
  color: #ffffff !important;
  background-color: #3f923f !important;
  border-color: #3f923f !important; }

.btn-danger {
  color: #ffffff !important;
  background-color: #dc3545 !important;
  border-color: #dc3545 !important; }

.btn-warning {
  color: #ffffff !important;
  background-color: #ffbe50 !important;
  border-color: #ffbe50 !important; }

.btn-info {
  color: #ffffff !important;
  background-color: #17a2b8 !important;
  border-color: #17a2b8 !important; }

.btn-dark {
  color: #ffffff !important;
  background-color: #343a40 !important;
  border-color: #343a40 !important; }

.btn-danger:hover {
  color: #ffffff !important;
  background-color: #c82333 !important;
  border-color: #bd2130 !important; }

.btn-warning:hover {
  color: #ffffff !important;
  background-color: #e0a800 !important;
  border-color: #d39e00 !important; }

.btn-info:hover {
  color: #ffffff !important;
  background-color: #138496 !important;
  border-color: #117a8b !important; }

.btn-primary:hover {
  color: #ffffff !important;
  background-color: #1565c0 !important;
  border-color: #1565c0 !important; }

.btn-primary-d:hover {
  color: #ffffff !important;
  background-color: #1f5f94;
  border-color: #1f5f94; }

.btn-secondary:hover {
  color: #ffffff !important;
  background-color: #00796b !important;
  border-color: #00796b !important; }

.btn-success:hover {
  color: #ffffff !important;
  background-color: #218838 !important;
  border-color: #1e7e34 !important; }

.btn-light:hover {
  color: #212529 !important;
  background-color: #e2e6ea !important;
  border-color: #dae0e5 !important; }

.btn-dark:hover {
  color: #ffffff !important;
  background-color: #23272b !important;
  border-color: #1d2124 !important; }

.d-inline-flex {
  display: inline-flex !important; }

.MuiTypography-colorPrimary {
  color: #2196f3 !important; }

.MuiIcon-colorPrimary {
  color: #2196f3 !important; }

.text-primary-d2 {
  color: #2564a9 !important; }

.bgc-primary-d1 {
  background-color: #2a80c8 !important; }

.brc-primary-l2 {
  border-color: #d2e6f6 !important; }

.bgc-green-d1 {
  background-color: #36ad82 !important; }

.bgc-primary {
  background-color: #2c87d3 !important; }

.brc-green-l2 {
  border-color: #d8f0e8 !important; }

.bgc-warning-d1 {
  background-color: #e19e2e !important; }

.text-orange-l2 {
  color: #ffc277 !important; }

.brc-warning-l2 {
  border-color: #fbeacd !important; }

.bgc-danger-tp1 {
  background-color: rgba(218, 78, 53, 0.925) !important; }

.dialog-footer {
  padding: 16px 24px !important;
  border-top: 1px solid rgba(0, 0, 0, 0.125) !important; }

.text-white {
  color: #ffffff !important; }

.MuiIcon-colorDisabled {
  color: #ffffff !important; }

.MuiIcon-colorError {
  color: #ff5722 !important; }

.MuiPickersToolbar-toolbar {
  background-color: #2196f3 !important; }

.MuiPickersDay-daySelected:hover {
  background-color: #2a80c8 !important; }

.MuiPickersDay-daySelected {
  background-color: #2a80c8 !important; }

.MuiButton-textPrimary {
  color: #2a80c8 !important; }

.input-description {
  font-style: italic !important;
  color: red !important; }

.sidenav__user .username-photo img {
  width: 80px !important;
  height: 80px !important;
  box-shadow: 0 2px 20px -5px #2196f3 !important; }

.sidenav__user .username-photo .username {
  font-weight: 600 !important;
  color: #ffc277 !important;
  font-size: 0.95em !important; }

.highcharts-container {
  /* border-radius: 8px!important; */
  /* box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 10%); */
  border-radius: 8px;
  border-width: 0;
  background-color: #ffffff;
  position: relative; }

.MuiPaginationItem-textPrimary.Mui-selected {
  background-color: #2196f3 !important; }

.MuiPaginationItem-textPrimary.Mui-selected:hover {
  background-color: #1565c0 !important; }

.input-popup-container {
  position: relative !important; }

.input-popup-container button {
  min-width: 100px !important;
  position: absolute !important;
  top: 0 !important;
  right: 0 !important; }

.content-index {
  padding: 10px 30px;
  background-size: 100% 400px;
  background-repeat: no-repeat;
  background-image: linear-gradient(-45deg, #2196f3 0%, #2196f3 33%, #00bfa5 100%);
  background-attachment: fixed; }
  .content-index .index-breadcrumb {
    margin-bottom: 30px; }
  .content-index .index-card.tab {
    background: #ffffff;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 8px; }
    .content-index .index-card.tab .tab-item {
      background: #ffffff !important;
      border-radius: 8px !important; }
      .content-index .index-card.tab .tab-item .tab-container {
        max-width: 100vw !important; }

.dialog-header {
  border-bottom: 1px solid #e3f2fd !important; }

@media screen and (max-width: 767px) {
  .MuiButton-startIcon {
    margin: 0 !important; } }

.filter-card {
  background: #ffffff;
  border-radius: 8px;
  padding: 24px; }

