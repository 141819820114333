.rounded {
    border-radius: 5px;
}
.card {
    padding: 5%;
    background-color: bisque;
    margin-bottom: 12px;
    padding: 0px;
    /* background-color: blanchedalmond !important; */
}
.head {
    font-size: 20px;
}
li {
    font-size: 20px;
}
h1 {
    color: blanchedalmond;
}
