.layout2 {
    .topbar {
        position: relative;
        width: 100%;
        display: table;
        z-index: 98;
        .brand {
            height: 100%;
            cursor: pointer;
            align-items: center;
            .brand__wrap-image {
                img {
                    position: absolute;
                    left: 20px;
                    bottom: 30px;
                    top: 15px;
                    display: block;
                }
            }

            // Mobile
            @media only screen and (max-width: 960px) {
                .brand__wrap-image {
                    img {
                        height: 75%;
                        position: absolute;
                        left: 20px;
                        bottom: 30px;
                        top: 12px;
                        display: block;
                    }
                }
            }

            .brand__title {
                color: #1e217e !important;
                margin: 0;
                font-style: normal;
                font-weight: 800;
                font-size: 16px;
                line-height: 23px;
                /* identical to box height */

                color: var(--text-blue);
            }

            .brand__text {
                font-style: normal;
                font-weight: 700;
                font-size: 12px;
                line-height: 17px;
                /* identical to box height */

                color: var(--text-blue);
                margin: 0;
            }
        }

        .MuiIconButton-root {
            color: $white;
        }

        .container {
            padding-left: $padding-pc;
            padding-right: $padding-pc;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .sign {
                color: $primary-color;
                color: #00016d;
            }
        }

        // Super tabler
        @media only screen and (min-width: 960px) and (max-width: 1279px) {
            .container {
                padding-left: $padding-super-tablet;
                padding-right: $padding-super-tablet;

                .sign {

                    li,
                    form {
                        display: none;
                    }
                }
            }
        }

        // Tablet
        @media only screen and (min-width: 600px) and (max-width: 959px) {
            .container {
                padding-left: $padding-tablet;
                padding-right: $padding-tablet;

                .sign {

                    li,
                    form {
                        display: none;
                    }
                }
            }
        }

        // Mobile
        @media only screen and (max-width: 599px) {
            .container {
                padding-left: $padding-mobile;
                padding-right: $padding-mobile;

                .sign {

                    li,
                    form {
                        display: none;
                    }
                }
            }
        }
    }
}