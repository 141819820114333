$item-x-padding: 20px;

.layout2 {
    .navbar {
        .container {
            padding-left: $padding-pc;
            padding-right: $padding-pc;
        }
    }

    @media only screen and (min-width: 960px) and (max-width: 1400px) {
        // .navbar {
        //     .container {
        //         padding-left: 60px;
        //         padding-right: 60px;
        //     }
        // }
    }
}

.horizontal-nav {
    display: flex;
    justify-content: center;
    height: 100%;
    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        position: relative;
    }
    ul.menu {
        // margin-left: -#{$item-x-padding};
        z-index: 99;
        display: flex;
        justify-content: center;
        align-items: center;
        > li {
            margin: 0 10px;
            > div {
                > a,
                > div {
                    border-bottom: 2px solid;
                    box-sizing: border-box;
                    border-color: transparent;
                    margin: 0 6px;
                }
            }
        }
    }
    ul li {
        position: relative;
        margin: 0px;
        display: inline-block;
        ul a {
            padding: 8px $item-x-padding;
            height: 48px;
        }
    }

    .nav-item,
    label {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 4px 8px 4px 16px;
        font-size: 0.875rem;
        text-decoration: none;
        box-sizing: border-box;
        .material-icons {
            font-size: 14px;
            margin: 0 4px;
        }
    }

    .nav-item {
        padding: 10px 30px;
        font-size: 14px;
        text-transform: uppercase;
        color: $white;
        transition: all 0.4s ease;
    }

    > ul > .nav-item:hover {
        color: $primary-color;
        border-radius: 5px;
        cursor: pointer;
    }

    .nav-item ul.submenu-overflow {
        border-radius: 5px;
    }

    .nav-item ul.submenu-overflow li a.nav-item {
        font-size: 12px;
    }

    .submenu-overflow {
        max-height: 60vh;
        overflow-y: auto;
    }

    .nav-item:hover {
        background: #f5f5f5;
        border-left: none;
    }

    ul ul.submenu-overflow {
        opacity: 0;
        visibility: hidden;
        position: absolute;

        left: 0;
        box-shadow: $elevation-z8;
        top: 46px;
        transform: translateY(-10px);
        transition: all 0.3s ease-in-out;
        z-index: -1;
    }

    .menu > li > .nav-item.active,
    > ul > .nav-item.active {
        // background: #2196f3 !important;
        background: $secondary-color !important;
        border-radius: 5px;
        color: $white;
        border-left: none;
    }

    .menu > li > .nav-item:hover,
    > ul > .nav-item:hover {
        background: #f2f2f2;
        border-radius: 5px;
    }

    .nav-item.active {
        color: #1565c0;
        border-left: 4px solid #84c7f6;
        background: #e0f2f1 !important;
        // border-radius: 0 24px 24px 0;
    }

    ul li:hover > div > div > ul,
    ul li:hover > div > ul,
    li:hover > ul {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }

    ul ul li {
        width: 170px;
        float: none;
        display: list-item;
        position: relative;
    }
    ul ul ul {
        top: 0;
        left: 170px;
    }
    ul ul ul li {
        position: relative;
        top: 0;
    }

    li > a:after {
        content: "arrow_drop_down";
        font-family: "Material Icons";
        font-weight: normal;
        font-style: normal;
        font-size: 14px;
        line-height: 1;
        margin-left: auto;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        -webkit-font-feature-settings: "liga";
        -webkit-font-smoothing: antialiased;
    }
    li > a:only-child:after {
        content: "";
    }

    @media only screen and (min-width: 960px) and (max-width: 1400px) {
        .nav-item {
            padding: 10px 15px;
            font-size: 14px;
        }
    }
}
