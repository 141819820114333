.swiper-slide {
    height: auto;
}

.swiper-pagination {
    width: 50px !important;
    bottom: 10% !important;
    left: $padding-pc !important;
    @media only screen and (max-width: 480px) {
        display: none;
        left: $padding-mobile !important;
    }
}

.swiper-pagination-bullet {
    opacity: 1;
    background: $white;
    transition: transform 400ms cubic-bezier(0.17, 0.67, 0.83, 0.67);
}

.bullet-active {
    transform: scale(1.8);
}

.carousel__button-next,
.carousel__button-prev {
    position: absolute !important;
    top: 50%;
    font-size: 40px !important;
    transform: translate(0, -50%);
    z-index: 1;
    background-color: transparent !important;
    box-shadow: none !important;
    height: 30px !important;
    width: 30px !important;
    min-height: 0 !important;
}

.carousel__button-next-training {
    margin-left: 14px !important;
}

.carousel__button-next-training,
.carousel__button-prev-training {
    transform: translate(0, -50%);
    background-color: transparent !important;
    box-shadow: none !important;
    height: 30px !important;
    width: 30px !important;
    min-height: 0 !important;
}

.carousel__button-next  .MuiSvgIcon-root,
.carousel__button-prev .MuiSvgIcon-root{
    font-size: 40px !important;
}
.carousel__button-prev {
    left: -30px;
}

.carousel__button-next {
    right: -30px;
}

@media (max-width: 1440px) {
    .carousel__button-prev {
        left: 0px;
    }

    .carousel__button-next {
        right: 0px;
    }
    .carousel__button-prev span img {
        width: 12px
     }
 
     .carousel__button-next span img {
         width: 12px
     }
}
@media (max-width: 1024px) {
    .carousel__button-prev span img {
       width: 10px
    }

    .carousel__button-next span img {
        width: 10px
    }
}
.carousel__button-prev.color-black,
.carousel__button-next.color-black {
    color: #000;
}

.carousel__button-prev.color-black,
.carousel__button-next.color-black {
    right: $white;
}

// Home swiper
.swiper-container.swiper-container-home {
    height: 100%;
    .swiper-slide {
        text-align: right;
    }
    // pagination
    .swiper-pagination.swiper-pagination-home {
        width: 80px !important;
        bottom: 10% !important;
        left: $padding-pc !important;
        @media only screen and (max-width: 480px) {
            display: none;
            left: $padding-mobile !important;
        }
        .swiper-pagination-bullet {
            opacity: 1;
            background: $white;
            transition: transform 400ms cubic-bezier(0.17, 0.67, 0.83, 0.67);
            width: 14px;
            height: 14px;
            &.bullet-active {
                -webkit-transform: scale(1);
                transform: scale(1);
                background-color: #d76e00;
            }
        }
    }
}