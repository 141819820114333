
/* Margin & Padding */
.m-0 {
    margin: 0 !important;
}

.mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
}

.mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.mx-10 {
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.mx-15 {
    margin-left: 15px !important;
    margin-right: 15px !important;
}

.my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.my-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.my-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.mt-0 {
    margin-top: 0 !important; 
}

.mt-10 {
    margin-top: 10px !important; 
}

.mt-15 {
    margin-top: 15px !important; 
}

.mt-16 {
    margin-top: 16px !important; 
}

.mt-20 {
    margin-top: 20px !important; 
}

.mt-30 {
    margin-top: 30px !important; 
}

.mt-50 {
    margin-top: 50px !important; 
}

.mt-170 {
    margin-top: 170px !important; 
}

.mb-0 {
    margin-bottom: 0 !important; 
}

.mb-10 {
    margin-bottom: 10px !important; 
}

.mb-15 {
    margin-bottom: 15px !important; 
}

.mb-30 {
    margin-bottom: 30px !important; 
}

.mb-50 {
    margin-bottom: 50px !important; 
}

.mr-0 {
    margin-right: 0 !important;
}

.mr-5 {
    margin-right: 5px !important;
}

.mr-10 {
    margin-right: 10px !important;
}

.mr-15 {
    margin-right: 15px !important;
}

.mr-20 {
    margin-right: 20px !important;
}

.mr-25 {
    margin-right: 25px !important;
}

.mr-40 {
    margin-right: 40px !important;
}

.ml-0 {
    margin-left: 0 !important;
}

.ml-10 {
    margin-left: 10px !important;
}

.ml-15 {
    margin-left: 15px !important;
}

.ml-20 {
    margin-left: 20px !important;
}

.ml-55 {
    margin-left: 55px !important;
}

.ml-100 {
    margin-left: 100px !important;
}

.p-0 {
    padding: 0 !important;
}

.p-20 {
    padding: 20px !important;
}

.px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.pt-0 {
    padding-top: 0 !important; 
}

.pt-10 {
    padding-top: 10px !important;
}

.pb-0 {
    padding-bottom: 0 !important; 
}

.pr-0 {
    padding-right: 0 !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.p-10 {
    padding: 10px !important;
}

.p-20 {
    padding: 20px !important;
}

.px-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
}
