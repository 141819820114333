.upload-drop-box {
  height: 120px;
  width: 100%;
  border: 2px solid $light-gray;
  border-radius: 4px;
}
.drag-shadow {
  background: $primary;
  box-shadow: 3px 3px 10px rgba($color: var(--text-black), $alpha: 0.2);
}
