@font-face {
    font-family: UTM Cafeta;
    src: url("/assets/fonts/UTM Cafeta.ttf");
  }

.btn.btn-light {
    padding: 12px 23px !important;
    background: rgba(255, 255, 255, 0.3) !important;
    border: 1px solid $white !important;
    box-sizing: border-box !important;
    border-radius: 8px !important;
    color: $white !important;
    backdrop-filter: blur(4px) !important;
}
.clear-fix-150 {
    height: 120px;
}
.mb-20px {
    margin-bottom: 20px !important;
}

.mt-25px {
    margin-top: 25px !important;
}
.ml-25px {
    margin-left: 25px !important;
}
.fs-24px {
    font-size: 24px !important;
}

.bgr-header{
    height: 420px;
    align-items: center;
    display: flex;
}

.header-title{
    font-size: 64px;
    width: 608px;
    margin: auto;
    color: var(--orangy-yellow);
    line-height: 75px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.01em;
}
.training-header-title{
    font-size: 64px;
    margin: auto;
    color: var(--orangy-yellow);
    line-height: 75px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.01em;
    text-shadow: 0px 2px 2px #000000;
}

@media (min-width: 1280px) {
    .lg-pl-30 {
        padding-left: 30px;
    }
    .lg-pr-30 {
        padding-right: 30px;
    }
    .lg-h-94px {
        height: 94px !important;
    }
}

::-webkit-scrollbar {
    width:5px;
    height: 5px;
}
::-webkit-scrollbar:hover{
    width: 12px !important;
}
.MuiAutocomplete-option{
    font-size: 15px !important
}
/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #007db3;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #007db3;
}
.w-100px {
    width: 100px;
}
.px-10px {
    padding: 10px 0px;
}
@media (max-width: 1279.89px) {
    .lgd-h-62px {
        height: 62px !important;
    }
}
.default-header-section {
    text-align: center;
}
.mb-9px {
    margin-bottom: 9px;
}
.default-header-section-h2 {
    font-family: 'UTM Cafeta';
    text-transform: uppercase;
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 44px;
    text-align: center;
    color: var(--text-blue);
    margin: 24px 0px;
    padding-bottom: 24px;
    position: relative;
    display: inline-block;
}
.default-header-section-h2::after {
    content: "";
    position: absolute;
    width: 50%;
    bottom: 0;
    left: 25%;
    height: 5px;
    background-color: var(--orangy-yellow);
}
.second-title-section-h3 {
    font-family: 'UTM Cafeta';
    text-transform: uppercase;
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 44px;
    text-align: center;
    color: var(--text-blue);
    padding: 30px 20px 10px 25px;
    margin: 24px 0px;
    padding-bottom: 24px;
    position: relative;
    display: inline-block;
}
.second-title-section-h3::after {
    content: "";
    position: absolute;
    width: 50%;
    bottom: 0;
    left: 25%;
    height: 5px;
    background-color: var(--orangy-yellow);
}
.about-title-section-h3 {
    font-family: 'UTM Cafeta';
    text-transform: uppercase;
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 44px;
    text-align: center;
    color: var(--text-blue);
    margin: 24px 0px;
    padding-bottom: 24px;
    position: relative;
    display: inline-block;
}
.about-title-section-h3::after {
    content: "";
    position: absolute;
    width: 65%;
    bottom: 0;
    left: 14%;
    height: 5px;
    background-color: var(--orangy-yellow);
}
.clear-both {
    clear: both;
}

.layout2 .topbar {
    padding-top: 0;
    padding-bottom: 0;
    height: 150px;
}

@media screen and (max-width: 960px) {
    .layout2 .topbar {
        height: 100px;
    }
    .training-header-title {
        font-size: 48px;
    }
}
.align-item-end {
    align-items: flex-end;
}

.MuiTableRow-root .MuiTableCell-head {
    padding-left: 20px;
    padding-right: 20px;
}

.MuiTableRow-root .MuiTableCell-root {
    padding-left: 20px;
    padding-right: 20px;
}

.MuiTableRow-root .MuiTableCell-paddingNone {
    padding: 0px;
}

.MuiIconButton-root {
    cursor: pointer;
}

.asset_department {
    background-color: $white;
    padding: 8px;
    height: auto;
}

.w-60 {
    width: 60%;
}

.icon {
    display: flex;
}

.fb-iconn {
    fill: white;
}

.margin-auto {
    margin: auto;
}

.none_wrap {
    white-space: nowrap !important;
}

.validator-form-scroll-dialog {
    overflow-y: auto !important;
    display: flex !important;
    flex-direction: column !important;
}

.logo-login {
    max-width: 400px !important;
}

.logo-login img {
    width: 100% !important;
}

.tb-charts-container .tb-charts-tab-label button {
    font-size: 0.6rem;
    max-width: 25%;
}

.tb-charts-container .tb-charts-tab-label .MuiTab-root {
    min-width: unset;
}

.map-show {
    position: relative;
    z-index: 9;
}

.search-card {
    position: absolute;
    top: 90px;
    right: 5px;
    z-index: 10;
    border-radius: 5px;
    width: 250px;
}

iframe {
    display: block;
    margin: 0 auto;
    padding: 0;
    /* iframes are inline by default */
    background: $white;
    border: none;
    /* Reset default border */
    height: 331px;
    /* Viewport-relative units */
    width: 600px;
}

// }
// Openlayer popup
.ol-popup {
    z-index: 0;
    position: absolute;
    background-color: white;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #cccccc;
    bottom: 12px;
    left: -50px;
    min-width: 280px;
}

.ol-popup:after,
.ol-popup:before {
    top: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.ol-popup:after {
    border-top-color: white;
    border-width: 10px;
    left: 48px;
    margin-left: -10px;
}

.ol-popup:before {
    border-top-color: #cccccc;
    border-width: 11px;
    left: 48px;
    margin-left: -11px;
}

.ol-popup-closer {
    text-decoration: none;
    position: absolute;
    top: 2px;
    right: 8px;
}

.ol-popup-closer:after {
    content: "?";
}

.styleColor {
    background-color: #358600;
    color: $white;
}

.close-style {
    position: absolute;
    right: 20px;
    top: 7px;
    font-size: small;
    width: 15px;
    height: 15px;
    color: #ebebeb;
}

#atmospheric-c {
    height: 500;
    margin: 0 auto;
}

.MuiSelect-select.MuiSelect-select {
    color: black !important;
}

.MuiInputBase-input.Mui-disabled {
    color: black !important;
}

.div-parent-chart {
    min-height: 400px;
}

.div-child-chart {
    text-align: center;
}

.lable-typeOrganization {
    font-size: 14px !important;
    transform: translate(0, 1.5px) scale(0.75) !important;
    transform-origin: top left !important;
}
.layout2-custom {
    overflow-y: visible !important;
}



// home css
.full-screen-bg {
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
}
.banner-item-description {
    max-width: 65%;
    padding: 50px 60px 37.5px 56px;
    text-align: left;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.4);
    right: 23%;
    /* bottom: 40%; */
}
.banner-item-video {
    text-align: right;
    flex-grow: 1;
}
.banner-item-video iframe {
    width: 509px !important;
    height: 517px !important;
}
.banner-item {
    justify-content: center;
    display: flex;
    align-items: center !important;
    height: calc(100vh - 150px);
}
.banner-item-description__header {
    font-size: 40px;
    color: $white !important;
}
.banner-item-description__content {
    font-size: 15px;
    color: $white;
}
.banner-item-description__submit .btn-light {
    padding: 12px 23px !important;
    font-weight: 700;
    font-size: 20px;
    line-height: 34px;
}

.banner-item .banner-item-video img {
    display: inline;
    width: unset;
}
.owl-carousel-banner {
    position: relative;
}
.custom-prev-btn-banner {
    position: absolute;
    top: 50%;
    color: $white;
    left: 20px;
    z-index: 999;
    font-size: 40px;
}
.custom-next-btn-banner {
    color: $white;
    position: absolute;
    z-index: 999;
    top: 50%;
    right: 20px;
    font-size: 40px;
}
@media only screen and (max-width: 768px) {
    .banner-item-description {
        max-width: 100%;
    }
    .banner-item-video {
        display: none;
    }
    .owl-navigation-banner {
        display: none;
    }
    .carousel-home__button-prev {
        left: 0px;
    }

    .carousel-home__button-next {
        right: 0px;
    }
    .training-header-title {
        font-size: 36px;
    }
}
#testimonial1 {
    background-image: url("/assets/images/home/bg-1.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.25);
    background-blend-mode: color;
}
.carousel-home__button-next,
.carousel-home__button-prev {
    color: $white !important;
    position: absolute !important;
    top: calc(50% - 307px -25px) !important;
    transform: translateY(calc(-50% - 50px));
    font-size: 70px !important;
    z-index: 1;
}

.carousel-home__button-prev {
    left: 0px;
}

.carousel-home__button-next {
    right: 0px;
}
.carousel-home__button-next,
.carousel-home__button-prev {
    position: absolute !important;
    top: 50%;
    font-size: 40px !important;
    transform: translate(0, -50%);
    z-index: 1;
    background-color: transparent !important;
    box-shadow: none !important;
    height: 30px !important;
    width: 30px !important;
    min-height: 0 !important;
}
.carousel-home__button-next  .MuiSvgIcon-root,
.carousel-home__button-prev .MuiSvgIcon-root{
    font-size: 70px !important;
    bottom: 33%;
    position: absolute;
}
.carousel-home__button-prev {
    left: -30px;
}

.carousel-home__button-next {
    right: -30px;
}
@media (max-width: 1440px) {
    .carousel-home__button-prev {
        left: 0px;
    } 

    .carousel-home__button-next {
        right: 0px;
    }
}
@media (max-width: 1024px) {
    .carousel-home__button-prev {
       display: none !important
    }

    .carousel-home__button-next {
        display: none !important
    }
}
@media (max-width: 640px) {
    .carousel-home__button-prev {
        left: 0px;
    }
    .banner-item-description__header {
        font-size: 30px;
    }
    .carousel-home__button-next {
        right: 0px;
    }
    .training-header-title {
        font-size: 26px;
    }
}
@media (max-width: 375px) {
    .banner-item-description__header {
        font-size: 20px;
        line-height: 30px;
    }
    .banner-item-description__content {
        font-size: 12px;
    }
    .training-header-title {
        font-size: 20px;
    }
}
.carousel-home__button-prev.color-black,
.carousel-home__button-next.color-black {
    color: #000;
}

.carousel-home__button-prev.color-black,
.carousel-home__button-next.color-black {
    right: $white;
}


//luôn để cuối cùng của file css
// ******
@media screen and (min-width: 1900px) {
    .layout2 .container {
        margin-top: 0px;
    }
    .layout2 .horizontal-nav ul li ul a {
        white-space: nowrap;
    }
    .layout2 .horizontal-nav ul ul li {
        width: auto;
    }
    .layout2 .horizontal-nav ul ul ul {
        top: 0;
        position: absolute;
        left: 100%;
    }

}

// *****
@media screen and (max-width: 1600px) and (min-width: 1400px) {
    .layout2 .container {
        margin-top: 0px;
    }
    .layout2 .horizontal-nav ul li ul a {
        white-space: nowrap;
    }
    .layout2 .horizontal-nav ul ul li {
        width: auto;
    }
    .layout2 .horizontal-nav ul ul ul {
        top: 0;
        position: absolute;
        left: 100%;
    }
}

@media screen and (max-width: 959px) and (min-width: 600px) {
    .export_excel {
        padding-top: 60px !important;
        text-align: -webkit-left !important;
    }
    .default-header-section-h2 {
        font-size: 28px;
    }
}
@media screen and (max-width: 960px) {
    .default-header-section-h2 {
        font-size: 28px;
    }
    .second-title-section-h3 {
        font-size: 28px;
    }   
    .about-title-section-h3 {
        font-size: 28px;
    }
}

@media screen and (max-width: 450px) {
    .second-title-section-h3 {
       width: 89%;
    }  
    .second-title-section-h3::after {
        left: 135px;
    }
}

@media screen and (max-width: 1400px) and (min-width: 1200px) {
    .layout2 .container {
        margin-top: 0px;
    }
    .layout2 .horizontal-nav ul li ul a {
        white-space: nowrap;
    }
    .layout2 .horizontal-nav ul ul li {
        width: auto;
    }
    .layout2 .horizontal-nav ul ul ul {
        top: 0;
        position: absolute;
        left: 100%;
    }
}

.bt {
    float: right;
    margin: 0px;
}

.plan_text {
    float: right;
    width: 50%;
    font-weight: bold;
    font-size: 1rem;
}

.MuiOutlinedInput-adornedEnd {
    width: 120px;
}

.body_content {
    margin-top: 20px;
}

.h4_size {
    color: $white !important;
}

.text_inline {
    float: left;
}

.btn_right {
    text-align: -webkit-right;
    margin-bottom: 10px !important;
}

.btn_appro {
    margin-bottom: 5px !important;
}

.tb_mt {
    margin-top: 30px !important;
}

.export_excel {
    margin-top: 10px;
    text-align: right;
}

.btn_exp_exc {
    margin-left: 8px !important;
    text-align: left;
}

.planAp1 {
    margin-left: 0px !important;
}

.planAp2 {
    margin-top: 48px !important;
}

.planAppNoDup1 {
    padding-top: 48px !important;
}

.planAppNoDup2 {
    text-align-last: center;
}

.planAppNoDup3 {
    padding-top: 55px !important;
}

.dialog_title {
    text-align: -webkit-center;
}

.btn_actions {
    align-self: center;
}

.title_dialog {
    text-align: -webkit-center;
}

.inspection.label {
    color: white;
    padding: 5px;
    white-space: nowrap;
}

.success {
    background-color: #04aa6d;
}

/* Green */

.info {
    background-color: #2196f3;
}

/* Blue */

.warning {
    background-color: #ff9800;
}

/* Orange */

.danger {
    background-color: #f44336;
}

/* Red */

.other {
    background-color: #e7e7e7;
    color: black !important;
}

.btn_left {
    text-align: -webkit-left;
}

.btn_add {
    color: #0ddd10;
}

.btn_remv {
    color: red;
}

.p-16 {
    padding: 8px;
}

/* Gray */

.resultPerform {
    text-align: center !important;
}

.resultPerform .MuiCheckbox-colorSecondary.Mui-checked {
    color: black;
}

.inspection.checkBox {
    color: white;
    padding: 5px;
    min-width: 100px;
}

.inspection.checkBox.follow {
    background-color: rgb(0, 204, 0);
}

.info_mt_cent {
    padding-right: 10px;
    text-align: center;
    margin-top: 20px !important;
}

.info_border1 {
    border: 1px solid green;
    padding: 5px;
}

.info_border2 {
    border: 1px solid red;
    padding: 5px;
}

.info_border3 {
    border: 1px solid cyan;
    padding: 5px;
}

.info_border4 {
    border: 1px solid blue;
    padding: 5px;
}

.info_border5 {
    border: 1px solid orange;
    padding: 5px;
}

.btn_s_right {
    float: right;
}

.btn-customer-base {
    padding: 10px;
}

.excel_ico {
    width: 21px;
}

.MuiTableCell-paddingNone {
    width: 30px !important;
    text-align: -webkit-center !important;
}

.sub_table {
    min-height: 200px;
    width: 101%;
    margin-left: -5px;
    margin-top: -39px;
}

.sub_header {
    background-color: gray !important;
}

.MuiTableCell-alignLeft {
    text-align-last: left;
}

#mui-99560 {
    display: none !important;
}

.MuiToolbar-gutters {
    float: left !important;
}

.MuiTablePagination-selectRoot {
    display: none !important;
}

.status_div {
    padding: 5px;
    color: white;
    background-color: #0a6fff;
    text-align: -webkit-center;
}

.enterprise_name {
    color: #2196f3;
}

.time_span {
    font-weight: bold !important;
    margin-bottom: 10px !important;
}

.update_dialog_title {
    background-color: #efefef !important;
}

.inspectionsOrg-render {
    margin-top: 5px;
    color: "#4cd14b";
}

.inspectionsOrg-render > span:first-of-type {
    position: absolute;
}

.inspectionsOrg-icon {
    position: relative;
}

.inspectionsOrg-render .MuiSvgIcon-root {
    width: 18px;
    height: auto;
    color: #4cd14b;
}

.inspectionsOrg-render .render-name {
    color: #08b8f1;
}

.isUsing-render {
    margin-top: 5px;
    color: "#4cd14b";
}

.isUsing-render > span:first-of-type {
    position: absolute;
}

.isUsing-icon {
    position: relative;
}

.isUsing-render .MuiSvgIcon-root {
    width: 18px;
    height: auto;
    color: #08b8f1;
}

.isUsing-render .render-name,
.inspectionsOrg-render .render-name {
    padding-left: 20px;
    font-weight: bold;
}

.form-f {
    margin-top: 4px !important;
}

.form-f1 {
    margin-top: 20px !important;
}

.MuiTableCell-paddingNone:last-child {
    background-color: #e0e0e0;
}

.align-center {
    text-align: center;
}

.nowrap {
    white-space: nowrap;
}

.table-customer-border .MuiTableRow-head,
.table-customer-border .MuiTableRow-root .MuiTableCell-head,
.table-customer-border .MuiTable-root,
.table-customer-border .MuiTableRow-root {
    border: 2px solid;
    font-weight: bold;
}

.table-customer-border .MuiTableRow-root .MuiTableCell-root {
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
}

.table-customer-border .MuiTableCell-body {
    border: 1px solid;
    font-weight: 400;
}

.uppercase {
    text-transform: uppercase;
}

.bold {
    font-weight: bold;
}

.cus-label {
    color: white;
    padding: 5px;
    font-family: Arial;
}

.cus-label.info {
    background-color: #2196f3;
}

.btn-rlo {
    margin-top: 47px !important;
}

.text-align-center {
    text-align: center;
}

.text-align-end {
    text-align: end;
}

.vertical-align-bottom {
    .MuiInputBase-input {
        padding: 1px;
    }
    vertical-align: bottom !important;
}

.date {
    width: 30px;
}

.year {
    width: 50px;
}

.MuiTableCell-paddingNone > b {
    display: none !important;
}

.cd-dialog5 {
    width: 300px;
}

.cd-dialog6 {
    width: 700px;
}

.cd-dialog7 {
    width: 700px;
}

.cd-dialog98 {
    width: 647px;
}

.cd-dialog9 {
    width: 600px;
}

.cd-dialog1 {
    width: 700px;
}

.cd-dialog2 {
    width: 620px;
}

.cd-dialog3 {
    width: 700px;
}

.cd-dialog {
    width: 800px;
}

.cd-dialog5-1 {
    width: 545px;
}

.cn-dialog-hr {
    width: 150px;
    font-weight: bold;
}

.cn-dialog3 {
    width: 400px;
}

.cn-dialog3-1 {
    width: 400px;
}

.cn-dialog4 {
    width: 400px;
}

.cn-dialog-ct {
    width: 450px;
}

.cr-dialog-ct {
    width: 350px;
}

.cr-dialog5 {
    width: 350px;
}

.cr-dialog3 {
    width: 720px;
}

.cr-dialog8 {
    width: 820px;
}

.cr-dialog4 {
    width: 250px;
}

//result-dialog
.result-dialog {
    line-height: 2;
    padding: 0 30px;
}

.result-dialog .line-heigt-cus {
}

.result-dialog .MuiInput-root {
    padding-top: 5px;
}

.result-dialog h5 .MuiInput-root {
    padding-top: 0;
}

.result-dialog .MuiInputBase-input {
    padding: 0 0 0 5px;
}

.btn {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.btn.focus,
.btn:focus,
.btn:hover {
    color: #333;
    text-decoration: none;
}

.btn-primary {
    color: $white;
    background-color: #337ab7;
    border-color: #2e6da4;
}

.btn-primary:hover {
    color: $white;
    background-color: #286090;
    border-color: #204d74;
}

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary:active.focus,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover {
    color: $white;
    background-color: #204d74;
    border-color: #122b40;
}

.btn-success {
    color: $white;
    background-color: #5cb85c;
    border-color: #4cae4c;
}

.btn-success:hover {
    color: $white;
    background-color: #449d44;
    border-color: #398439;
}

.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success:active.focus,
.btn-success:active:focus,
.btn-success:active:hover,
.open > .dropdown-toggle.btn-success.focus,
.open > .dropdown-toggle.btn-success:focus,
.open > .dropdown-toggle.btn-success:hover {
    color: $white;
    background-color: #398439;
    border-color: #255625;
}

.btn-danger {
    color: $white;
    background-color: #d9534f;
    border-color: #d43f3a;
}

.btn-danger:hover {
    color: $white;
    background-color: #c9302c;
    border-color: #ac2925;
}

//end-result-dialog
.MuiGrid-container {
    margin-bottom: 20px;
}

.object-report .MuiDialog-paperWidthLg {
    max-width: calc(100% - 64px);
}

.mb-5 {
    margin-bottom: 5px !important;
}

#date-picker-dialog-helper-text {
    display: none;
}

.count-result {
    margin-left: 5px;
    font-size: 15px;
}

.dialog-result-admin {
    margin-bottom: 25px !important;
    padding-bottom: 25px !important;
    border-bottom: 1px solid black;
}

.title-dialog-follow {
    margin-top: 20px;
}

.follow-up-filter {
    margin-left: 1px !important;
}

.table-css {
    border: 1px #000 solid !important;
}

.errorMesseges {
    color: red;
}

.insFile {
    cursor: pointer;
}

.recruitment {
    .recruitment-position {
        padding-left: $padding-pc;
        padding-right: $padding-pc;
        @media only screen and (max-width: 599px) {
            padding-left: $padding-mobile;
            padding-right: $padding-mobile;
        }
        @media only screen and (min-width: 600px) and (max-width: 959px) {
            padding-left: $padding-tablet;
            padding-right: $padding-tablet;
        }
        @media only screen and (min-width: 960px) and (max-width: 1279px) {
            padding-left: $padding-super-tablet;
            padding-right: $padding-super-tablet;
        }
    }
    .title {
        padding-top: 30px;
        margin-bottom: 60px;
        font-size: 30px;
        color: $secondary-color;
    }
    .job {
        margin-bottom: 20px;
        h4 {
            font-weight: bold;
            font-size: 18px;
            line-height: 20px;
            margin: 0 0 10px;
            cursor: pointer;
        }
        h4:hover {
            text-decoration: underline;
        }
        .vessel {
            font-weight: bold;
            font-size: 13px;
        }
        .salary {
            color: #e59a4e;
            margin-top: 5px;
        }
        .dates {
            color: #6d797d;
        }
    }
    button {
        font-family: "Roboto", sans-serif;
        border: none;
        background-color: $secondary-color;
        border-radius: 5px;
        color: $white;
        padding: 12px;
        font-size: 15px;
        box-shadow: 4px 3px 8px #999;
        cursor: pointer;
    }
    .link {
        text-decoration: underline;
        margin-left: 30px;
        font-size: 16px;
    }
    .recruitment-reputation {
        background-color: #d8d9ea;
        .title {
            font-weight: 400;
            color: $primary-color;
            padding: 25px 0 50px 0;
            margin-bottom: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            span {
                line-height: 40px;
            }
            .reputation__bold {
                line-height: 40px;
                text-transform: uppercase;
                color: #be6100;
                font-weight: 500;
                margin: 0;
            }
            .line {
                text-align: center;
                display: inline-block;
                width: 200px;
                border-bottom: 2px solid #be6100;
            }
        }
        .reputation {
            padding-left: $padding-pc;
            padding-right: $padding-pc;
            flex-direction: column;
            margin: auto;
            align-items: center;
            .title {
                align-items: center;
                .line {
                    align-items: center;
                    margin-top: 15px;
                }
            }
            // Super tablet
            @media only screen and (min-width: 960px) and (max-width: 1279px) {
                padding-left: $padding-super-tablet;
                padding-right: $padding-super-tablet;
                flex-direction: column;
                margin: auto;
                align-items: center;
                .about {
                    padding: 0;
                }
                .title {
                    align-items: center;
                    span {
                        display: flex;
                        justify-content: center;
                    }
                    .reputation__bold {
                        display: flex;
                        justify-content: center;
                    }
                    .line {
                        align-items: center;
                    }
                }
            }
            // Tablet
            @media only screen and (min-width: 600px) and (max-width: 959px) {
                padding-left: $padding-tablet;
                padding-right: $padding-tablet;
                flex-direction: column;
                margin: auto;
                align-items: center;
                .about {
                    padding: 0;
                }
                .title {
                    align-items: center;
                    span {
                        display: flex;
                        justify-content: center;
                    }
                    .reputation__bold {
                        display: flex;
                        justify-content: center;
                    }
                    .line {
                        align-items: center;
                    }
                }
            }
            @media only screen and (max-width: 730px) {
                padding-left: $padding-mobile;
                padding-right: $padding-mobile;
                flex-direction: column;
                margin: auto;
                align-items: center;
                .about {
                    padding: 0;
                }
                .title {
                    align-items: center;
                    span {
                        font-size: 30px;
                        display: flex;
                        justify-content: center;
                    }
                    .reputation__bold {
                        display: flex;
                        justify-content: center;
                        line-height: 30px;
                    }
                    .line {
                        align-items: center;
                    }
                }
            }
            // Mobile
            @media only screen and (max-width: 599px) {
                padding-left: $padding-mobile;
                padding-right: $padding-mobile;
                flex-direction: column;
                margin: auto;
                align-items: center;
                .about {
                    padding: 0;
                }
                .title {
                    align-items: center;
                    span {
                        font-size: 20px;
                        display: flex;
                        justify-content: center;
                    }
                    .reputation__bold {
                        display: flex;
                        justify-content: center;
                        line-height: 20px;
                    }
                    .line {
                        align-items: center;
                    }
                }
            }
            .about {
                margin: 0;
                list-style: none;
                li {
                    line-height: 1.4;
                    margin-bottom: 10px;
                    font-size: 18px;
                    display: flex;
                    svg {
                        color: #be6100;
                        margin-right: 15px;
                        font-size: 22px;
                    }
                    p {
                        margin: 0;
                        text-align: justify;
                    }
                }
            }
        }
    }
}

.member {
    list-style: none;
    padding-left: $padding-pc;
    padding-right: $padding-pc;
    margin-bottom: 30px;
    .title {
        padding-top: 30px;
        margin-bottom: 30px;
        font-size: 28px;
        color: $secondary-color;
    }
    .member_content {
        flex-direction: row-reverse;
    }
    .member_image {
    }
    li {
        font-size: 18px;
        line-height: 1.3;
        margin-bottom: 10px;
    }
    button {
        font-family: "Roboto", sans-serif;
        border: none;
        background-color: $secondary-color;
        border-radius: 5px;
        color: $white;
        padding: 12px 30px;
        font-size: 15px;
        box-shadow: 4px 3px 8px #999;
        margin-top: 30px;
        cursor: pointer;
    }
    img {
        border-radius: 5px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 75%;
    }
}
// Super tablet
@media only screen and (min-width: 960px) and (max-width: 1279px) {
    .member {
        padding-left: $padding-super-tablet;
        padding-right: $padding-super-tablet;
    }
}

// Tablet
@media only screen and (min-width: 600px) and (max-width: 959px) {
    .member {
        padding-left: $padding-tablet;
        padding-right: $padding-tablet;
    }
}
// Mobile
@media only screen and (max-width: 599px) {
    .member {
        padding-left: $padding-mobile;
        padding-right: $padding-mobile;
    }
}
.aboutus {
    padding-left: $padding-pc;
    padding-right: $padding-pc;
    .aboutus__review {
        border-radius: 8px;
        .aboutus__intro {
            padding-right: 10px;
        }
        .title {
            margin-bottom: 10px;
            font-size: 28px;
            color: $secondary-color;
        }
        .text {
            font-size: 18px;
            text-align: justify;
            @media only screen and (min-width: 1280px) {
                padding-right: 15px;
            }
        }
        .aboutus__video {
            height: 400px;
            border-radius: 8px;
        }
    }
    .static {
        background-color: #d8d9ea;
        min-height: 100px;
        padding: 8px 16px;
        border-top-left-radius: 15px;
        border-bottom-right-radius: 15px;
        min-height: 100px;
        padding: 8px 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 20px;
    }
    .static.reverse {
        border-radius: 0;
        border-top-right-radius: 15px;
        border-bottom-left-radius: 15px;
    }
    .static_box {
        margin-top: 20px;
    }
    .static p {
        margin: 0;
    }
    .static h6.static__title {
        padding-top: 8px;
        color: #e59a4e;
    }
    .static .static__owner {
        text-align: right;
    }
    .reponsive_order {
        position: relative;
        .reponsive_order_img {
            padding: 0 5px;
            text-align: center;
            img {
            }
        }
        .reponsive_order_content {
            width: 300px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            p {
                font-size: 20px;
                color: #d76e00;
                text-align: center;
                b {
                    font-size: 20px;
                    color: #d76e00;
                    text-align: center;
                }
            }
        }
    }
    .aboutus__leader {
        background-color: rgba(197, 197, 223, 0.3);
        padding: 50px;

        .aboutus__leader-slogan {
            width: 100%;
            color: #d76e00;
            font-size: 24px;
            font-weight: 500;
            text-align: center;
            margin-bottom: 50px;
            text-transform: uppercase;

            p {
                text-shadow: 0 5px 2px rgba(215, 110, 0, 0.15);
                margin: 0;
            }
        }

        .aboutus__leader-ceo {
            background-color: $white;
            padding: 30px;
            margin-bottom: 50px;

            .ceo__content-wrap {
                .ceo__content {
                    &.ceo__content-text.ceo__content-text-1 {
                        text-align: right;
                    }
                    &.ceo__content-text.ceo__content-text-2 {
                        text-align: left;
                    }

                    h6 {
                        color: #d76e00;
                        font-size: 16px;
                        line-height: 22px;
                    }
                    p {
                        color: #00016d;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 22px;
                    }
                    .ceo__content-img {
                        margin-bottom: 15px;

                        img {
                            vertical-align: middle;
                        }
                    }
                }
            }

            .ceo__info {
                text-align: center;

                div {
                    display: inline-block;
                    padding: 10px 0;
                    max-width: 260px;
                    width: 260px;
                    background-color: #3e3f95;
                    color: $white;
                    border-radius: 8px;

                    h6 {
                        margin: 0;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 22px;
                        text-transform: uppercase;
                    }
                    p {
                        margin: 0;
                        line-height: 22px;
                    }
                }
            }
        }

        .aboutus__leader-viceceo {
            .viceceo__member {
                padding: 0 15px;
                .viceceo__member-content {
                    width: 100%;
                    overflow: hidden;
                    .content__name {
                        width: 100%;
                        background-color: #3e3f95;
                        color: $white;
                        padding: 10px 0;
                        text-align: center;

                        h6 {
                            margin: 0;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 22px;
                            text-transform: uppercase;
                        }
                        p {
                            margin: 0;
                            line-height: 22px;
                            font-size: 14px;
                        }
                    }
                    .content__slogan-and-avt {
                        width: 100%;
                        padding: 10px;

                        .content__avt {
                            width: 100%;
                            padding: 15px 0;
                            text-align: center;

                            img {
                                vertical-align: middle;
                            }
                        }
                        .content__slogan {
                            width: 100%;
                            margin-bottom: 15px;
                            &:last-child {
                                margin-bottom: 0;
                            }

                            h6 {
                                color: #d76e00;
                                font-size: 16px;
                                line-height: 22px;
                            }
                            p {
                                color: #00016d;
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 22px;
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }

        // Super tablet, Tablet, Mobile
        @media only screen and (max-width: 1279px) {
            .aboutus__leader-viceceo {
                .viceceo__member {
                    margin-bottom: 20px;
                    &:last-child {
                        margin-bottom: 0;
                    }

                    .viceceo__member-content {
                        .content__slogan-and-avt {
                            padding: 15px;
                        }
                    }
                }
            }
        }

        // Super tablet
        @media only screen and (min-width: 960px) and (max-width: 1279px) {
            .aboutus__leader-ceo {
                .ceo__content.ceo__content-text {
                    padding: 0 10px;
                }
            }

            .aboutus__leader-viceceo {
                .viceceo__member {
                }
            }
        }

        // Tablet
        @media only screen and (min-width: 600px) and (max-width: 959px) {
            .aboutus__leader-ceo {
                .ceo__content-wrap {
                    &.ceo__content-wrap-img {
                        order: -1;
                    }
                    .ceo__content {
                        &.ceo__content-text {
                            &.ceo__content-text-1,
                            &.ceo__content-text-2 {
                                text-align: left;
                            }
                        }
                    }
                }
            }

            .aboutus__leader-viceceo {
                .viceceo__member {
                }
            }
        }

        // Mobile
        @media only screen and (max-width: 599px) {
            & {
                background-color: $white;
                padding: 0;
                .aboutus__leader-slogan {
                    margin-bottom: 30px;
                    p {
                        max-width: 250px;
                        margin-left: auto;
                        margin-right: auto;
                    }
                }

                .aboutus__leader-viceceo {
                    .viceceo__member {
                        padding: 0;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 1279px) {
        padding-left: $padding-mobile;
        padding-right: $padding-mobile;
        .aboutus__review {
            width: 400px;
        }
        .reponsive_order {
            order: -1;
        }
        .reposive_layout {
            margin-top: 20px;
        }
        .aboutus__video {
            order: -1;
        }
        .reponsive_order_title {
            order: -2;
        }
        .static1,
        .static2,
        .static3,
        .static4,
        .static5,
        .static6 {
            transform: translate(0, 0);
            width: 100%;
            .static__text {
                margin-bottom: 0;
            }
        }
    }
}

.certificates {
    .certificates__background {
        position: relative;
        padding-top: 50px;
        padding-bottom: 50px;
        @media only screen and (max-width: 1280px) {
            .mfp-arrow {
                display: none;
            }
        }
        .title {
            margin-bottom: 40px;
            font-size: 28px;
            color: $secondary-color;
        }
        .text {
            font-size: 18px;
            text-align: justify;
        }
        .mfp-arrow {
            position: absolute !important;
            top: 63%;
            z-index: 99;
        }
        .mfp-arrow-left {
            left: 50px;
        }
        .mfp-arrow-right {
            right: 50px;
        }
    }
}
.h-50vh {
    height: 50vh;
}
.footer {
    box-sizing: border-box;
    .footer__wrapper {
        padding-left: $padding-pc;
        padding-right: $padding-pc;
        padding-top: 35px;
        padding-bottom: 35px;
        .footer__contact {
            .footer__contact-wrapper {
                p {
                    margin: 0 0 5px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                .footer__contact-icons {
                    margin-top: 20px;
                    img {
                        display: inline-block;
                        width: 30px;
                        margin-right: 10px;
                    }
                }
            }
            .footer__grd_logo {
                .footer__logo {
                    display: block;
                }
            }
        }
        .footer__menu {
            a {
                display: flex;
                p {
                    font-weight: bold;
                    font-size: 16px;
                    margin-top: 0;
                    margin-bottom: 16px;
                }
            }
        }
    }
    .footer__copyright {
        font-size: 15px;
        padding: 10px 0;
        margin: 0;
        text-align: center;
        border-top: 1px solid rgba(255, 255, 255, 0.7);
        color: rgba(255, 255, 255, 0.7);
    }
    // Super tablet
    @media only screen and (min-width: 960px) and (max-width: 1279px) {
    }
    // Tablet
    @media only screen and (min-width: 600px) and (max-width: 959px) {
        .footer__wrapper {
            padding: $padding-mobile $padding-mobile $padding-mobile $padding-mobile;
            .footer__menu {
                float: right;
                a {
                    p {
                        font-size: 15px;
                    }
                }
            }
            .footer__contact {
                .footer__contact-wrapper {
                    p {
                        font-size: 15px;
                    }
                }
            }
        }
    }
    // Mobile
    @media only screen and (max-width: 599px) {
        .footer__wrapper {
            padding: $padding-mobile;
            position: relative;
            .footer__content {
                flex-direction: column-reverse;
                .footer__menu {
                    margin: 0 auto;
                    div {
                        margin: 0 !important;
                    }
                    display: block;
                    width: 180px;
                    a {
                        p {
                            width: 100%;
                            text-align: center;
                        }
                    }
                }
            }
            .footer__grd_logo {
                position: absolute;
            }
        }
    }
    @media only screen and (max-width: 450px) {
        .footer__wrapper {
            .footer__grd_logo {
                position: absolute;
                .footer__logo {
                    width: 60px;
                }
            }
        }
    }
    @media only screen and (max-width: 350px) {
        .footer__wrapper {
            .footer__grd_logo {
                position: absolute;
                .footer__logo {
                    width: 60px;
                }
            }
        }
    }
}

.btn-primary {
    background: #f16920 !important;
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0%, #f3821c),
        color-stop(100%, #f05322)
    ) !important;
    background: -webkit-linear-gradient(top, #f3821c 0%, #f05322 100%) !important;
    background: -webkit-gradient(linear, left top, left bottom, from(#f3821c), to(#f05322)) !important;
    background: linear-gradient(to bottom, #f3821c 0%, #f05322 100%) !important;
    border-color: #f16920 !important;
    font-weight: bold !important;
    text-shadow: 0 1px 2px rgb(0, 0, 0);
}

.link {
    color: #08c;
    text-decoration: none;
}

// ----------------------------------
.layout2 .topbar {
    padding-top: 0; 
    padding-bottom: 0;
    background-image: url("/assets/images/home/background-header.svg");
}

.MuiTableRow-root .MuiTableCell-head {
    padding-left: 20px;
    padding-right: 20px;
}

.MuiTableRow-root .MuiTableCell-root {
    padding-left: 20px;
    padding-right: 20px;
}

.MuiTableRow-root .MuiTableCell-paddingNone {
    padding: 0px;
}

.MuiIconButton-root {
    cursor: pointer;
}

.asset_department {
    background-color: $white;
    padding: 8px;
    height: auto;
}

.w-60 {
    width: 60%;
}

.margin-auto {
    margin: auto;
}

.none_wrap {
    white-space: nowrap !important;
}

.validator-form-scroll-dialog {
    overflow-y: auto !important;
    display: flex !important;
    flex-direction: column !important;
}

.logo-login {
    max-width: 400px !important;
}

.logo-login img {
    width: 100% !important;
}

.tb-charts-container .tb-charts-tab-label button {
    font-size: 0.6rem;
    max-width: 25%;
}

.tb-charts-container .tb-charts-tab-label .MuiTab-root {
    min-width: unset;
}



.map-show {
    position: relative;
    z-index: 9;
}

.search-card {
    position: absolute;
    top: 90px;
    right: 5px;
    z-index: 10;
    border-radius: 5px;
    width: 250px;
}

iframe {
    display: block;
    margin: 0 auto;
    padding: 0;
    /* iframes are inline by default */
    background: $white;
    border: none;
    /* Reset default border */
    height: 250px;
    /* Viewport-relative units */
    width: 600px;
    min-width: 300px;
}

// }
// Openlayer popup
.ol-popup {
    z-index: 0;
    position: absolute;
    background-color: white;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #cccccc;
    bottom: 12px;
    left: -50px;
    min-width: 280px;
}

.ol-popup:after,
.ol-popup:before {
    top: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.ol-popup:after {
    border-top-color: white;
    border-width: 10px;
    left: 48px;
    margin-left: -10px;
}

.ol-popup:before {
    border-top-color: #cccccc;
    border-width: 11px;
    left: 48px;
    margin-left: -11px;
}

.ol-popup-closer {
    text-decoration: none;
    position: absolute;
    top: 2px;
    right: 8px;
}

.ol-popup-closer:after {
    content: "?";
}

.styleColor {
    background-color: #358600;
    color: $white;
}

.close-style {
    position: absolute;
    right: 20px;
    top: 7px;
    font-size: small;
    width: 15px;
    height: 15px;
    color: #ebebeb;
}

#atmospheric-c {
    height: 500;
    margin: 0 auto;
}

.MuiSelect-select.MuiSelect-select {
    color: black !important;
}
.color-white {
    color: $white !important
}

.MuiInputBase-input.Mui-disabled {
    color: black !important;
}

.div-parent-chart {
    min-height: 400px;
}

.div-child-chart {
    text-align: center;
}

.lable-typeOrganization {
    font-size: 14px !important;
    transform: translate(0, 1.5px) scale(0.75) !important;
    transform-origin: top left !important;
}



//luôn để cuối cùng của file css
// *****
@media screen and (min-width: 1900px) {
    .layout2 .container {
        margin-top: 0px;
    }
    .layout2 .horizontal-nav ul li ul a {
        white-space: nowrap;
    }
    .layout2 .horizontal-nav ul ul li {
        width: auto;
    }
    .layout2 .horizontal-nav ul ul ul {
        top: 0;
        position: absolute;
        left: 100%;
    }
}

// *****
@media screen and (max-width: 1600px) and (min-width: 1400px) {
    .layout2 .container {
        margin-top: 0px;
    }
    .layout2 .horizontal-nav ul li ul a {
        white-space: nowrap;
    }
    .layout2 .horizontal-nav ul ul li {
        width: auto;
    }
    .layout2 .horizontal-nav ul ul ul {
        top: 0;
        position: absolute;
        left: 100%;
    }
}

@media screen and (max-width: 959px) and (min-width: 600px) {
    .export_excel {
        padding-top: 60px !important;
        text-align: -webkit-left !important;
    }
}

@media screen and (max-width: 1400px) and (min-width: 1200px) {
    .layout2 .container {
        margin-top: 0px;
    }
    .layout2 .horizontal-nav ul li ul a {
        white-space: nowrap;
    }
    .layout2 .horizontal-nav ul ul li {
        width: auto;
    }
    .layout2 .horizontal-nav ul ul ul {
        top: 0;
        position: absolute;
        left: 100%;
    }
}

.plan_text {
    float: right;
    width: 50%;
    font-weight: bold;
    font-size: 1rem;
}

.MuiOutlinedInput-adornedEnd {
    width: 120px;
}

.body_content {
    margin-top: 20px;
}

.static_1 {
    background-color: #72b1db;
    padding: 15px;
    border-radius: 10px;
    color: $white;
    min-height: 170px;
}

.static_2 {
    background-color: #cc5757;
    padding: 15px;
    border-radius: 10px;
    color: $white;
    min-height: 170px;
}

.static_3 {
    background-color: #8b61b5;
    padding: 15px;
    border-radius: 10px;
    color: $white;
    min-height: 170px;
}

.static_4 {
    background-color: #af7d56;
    padding: 15px;
    border-radius: 10px;
    color: $white;
    min-height: 170px;
}

.h4_size {
    color: $white !important;
}

.text_inline {
    float: left;
}

.btn_right {
    text-align: -webkit-right;
    margin-bottom: 10px !important;
}

.btn_appro {
    margin-bottom: 5px !important;
}

.tb_mt {
    margin-top: 30px !important;
}

.export_excel {
    margin-top: 10px;
    text-align: right;
}

.btn_exp_exc {
    margin-left: 8px !important;
    text-align: left;
}

.planAp1 {
    margin-left: 0px !important;
}

.planAp2 {
    margin-top: 48px !important;
}

.planAppNoDup1 {
    padding-top: 48px !important;
}

.planAppNoDup2 {
    text-align-last: center;
}

.planAppNoDup3 {
    padding-top: 55px !important;
}

.dialog_title {
    text-align: -webkit-center;
}

.btn_actions {
    align-self: center;
}

.title_dialog {
    text-align: -webkit-center;
}

.inspection.label {
    color: white;
    padding: 5px;
    white-space: nowrap;
}

.success {
    background-color: #04aa6d;
}

/* Green */

.info {
    background-color: #2196f3;
}

/* Blue */

.warning {
    background-color: #ff9800;
}

/* Orange */

.danger {
    background-color: #f44336;
}

/* Red */

.other {
    background-color: #e7e7e7;
    color: black !important;
}

.btn_left {
    text-align: -webkit-left;
}

.btn_add {
    color: #0ddd10;
}

.btn_remv {
    color: red;
}

.p-16 {
    padding: 8px;
}

/* Gray */

.resultPerform {
    text-align: center !important;
}

.resultPerform .MuiCheckbox-colorSecondary.Mui-checked {
    color: black;
}

.inspection.checkBox {
    color: white;
    padding: 5px;
    min-width: 100px;
}

.inspection.checkBox.follow {
    background-color: rgb(0, 204, 0);
}

.info_mt_cent {
    padding-right: 10px;
    text-align: center;
    margin-top: 20px !important;
}

.info_border1 {
    border: 1px solid green;
    padding: 5px;
}

.info_border2 {
    border: 1px solid red;
    padding: 5px;
}

.info_border3 {
    border: 1px solid cyan;
    padding: 5px;
}

.info_border4 {
    border: 1px solid blue;
    padding: 5px;
}

.info_border5 {
    border: 1px solid orange;
    padding: 5px;
}

.btn_s_right {
    float: right;
}

.btn-customer-base {
    padding: 10px;
}

.excel_ico {
    width: 21px;
}

.MuiTableCell-paddingNone {
    width: 30px !important;
    text-align: -webkit-center !important;
}

.sub_table {
    min-height: 200px;
    width: 101%;
    margin-left: -5px;
    margin-top: -39px;
}

.sub_header {
    background-color: gray !important;
}

.MuiTableCell-alignLeft {
    text-align-last: left;
}


#mui-99560 {
    display: none !important;
}

.MuiToolbar-gutters {
    float: left !important;
}

.MuiTablePagination-selectRoot {
    display: none !important;
}

.status_div {
    padding: 5px;
    color: white;
    background-color: #0a6fff;
    text-align: -webkit-center;
}

.enterprise_name {
    color: #2196f3;
}

.time_span {
    font-weight: bold !important;
    margin-bottom: 10px !important;
}

.update_dialog_title {
    background-color: #efefef !important;
}

.inspectionsOrg-render {
    margin-top: 5px;
    color: "#4cd14b";
}

.inspectionsOrg-render > span:first-of-type {
    position: absolute;
}

.inspectionsOrg-icon {
    position: relative;
}

.inspectionsOrg-render .MuiSvgIcon-root {
    width: 18px;
    height: auto;
    color: #4cd14b;
}

.inspectionsOrg-render .render-name {
    color: #08b8f1;
}

.isUsing-render {
    margin-top: 5px;
    color: "#4cd14b";
}

.isUsing-render > span:first-of-type {
    position: absolute;
}

.isUsing-icon {
    position: relative;
}

.isUsing-render .MuiSvgIcon-root {
    width: 18px;
    height: auto;
    color: #08b8f1;
}

.isUsing-render .render-name,
.inspectionsOrg-render .render-name {
    padding-left: 20px;
    font-weight: bold;
}

.form-f {
    margin-top: 4px !important;
}

.form-f1 {
    margin-top: 20px !important;
}

.MuiTableCell-paddingNone:last-child {
    background-color: #e0e0e0;
}

.align-center {
    text-align: center;
}

.nowrap {
    white-space: nowrap;
}

.table-customer-border .MuiTableRow-head,
.table-customer-border .MuiTableRow-root .MuiTableCell-head,
.table-customer-border .MuiTable-root,
.table-customer-border .MuiTableRow-root {
    border: 2px solid;
    font-weight: bold;
}

.table-customer-border .MuiTableRow-root .MuiTableCell-root {
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
}

.table-customer-border .MuiTableCell-body {
    border: 1px solid;
    font-weight: 400;
}

.uppercase {
    text-transform: uppercase;
}

.bold {
    font-weight: bold;
}

.cus-label {
    color: white;
    padding: 5px;
    font-family: Arial;
}

.cus-label.info {
    background-color: #2196f3;
}

.btn-rlo {
    margin-top: 47px !important;
}

.text-align-center {
    text-align: center;
}

.text-align-end {
    text-align: end;
}

.vertical-align-bottom {
    .MuiInputBase-input {
        padding: 1px;
    }
    vertical-align: bottom !important;
}

.date {
    width: 30px;
}

.year {
    width: 50px;
}

.MuiTableCell-paddingNone > b {
    display: none !important;
}

.cd-dialog5 {
    width: 300px;
}

.cd-dialog6 {
    width: 700px;
}

.cd-dialog7 {
    width: 700px;
}

.cd-dialog98 {
    width: 647px;
}

.cd-dialog9 {
    width: 600px;
}

.cd-dialog1 {
    width: 700px;
}

.cd-dialog2 {
    width: 620px;
}

.cd-dialog3 {
    width: 700px;
}

.cd-dialog {
    width: 800px;
}

.cd-dialog5-1 {
    width: 545px;
}

.cn-dialog-hr {
    width: 150px;
    font-weight: bold;
}

.cn-dialog3 {
    width: 400px;
}

.cn-dialog3-1 {
    width: 400px;
}

.cn-dialog4 {
    width: 400px;
}

.cn-dialog-ct {
    width: 450px;
}

.cr-dialog-ct {
    width: 350px;
}

.cr-dialog5 {
    width: 350px;
}

.cr-dialog3 {
    width: 720px;
}

.cr-dialog8 {
    width: 820px;
}

.cr-dialog4 {
    width: 250px;
}

//result-dialog
.result-dialog {
    line-height: 2;
    padding: 0 30px;
}

.result-dialog .line-heigt-cus {
}

.result-dialog .MuiInput-root {
    padding-top: 5px;
}

.result-dialog h5 .MuiInput-root {
    padding-top: 0;
}

.result-dialog .MuiInputBase-input {
    padding: 0 0 0 5px;
}

.btn {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.btn.focus,
.btn:focus,
.btn:hover {
    color: #333;
    text-decoration: none;
}

.btn-primary {
    color: $white;
    background-color: #337ab7;
    border-color: #2e6da4;
}

.btn-primary:hover {
    color: $white;
    background-color: #286090;
    border-color: #204d74;
}

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary:active.focus,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover {
    color: $white;
    background-color: #204d74;
    border-color: #122b40;
}

.btn-success {
    color: $white;
    background-color: #5cb85c;
    border-color: #4cae4c;
}

.btn-success:hover {
    color: $white;
    background-color: #449d44;
    border-color: #398439;
}

.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success:active.focus,
.btn-success:active:focus,
.btn-success:active:hover,
.open > .dropdown-toggle.btn-success.focus,
.open > .dropdown-toggle.btn-success:focus,
.open > .dropdown-toggle.btn-success:hover {
    color: $white;
    background-color: #398439;
    border-color: #255625;
}

.btn-danger {
    color: $white;
    background-color: #d9534f;
    border-color: #d43f3a;
}

.btn-danger:hover {
    color: $white;
    background-color: #c9302c;
    border-color: #ac2925;
}

//end-result-dialog
.MuiGrid-container {
    margin-bottom: 20px;
}

.object-report .MuiDialog-paperWidthLg {
    max-width: calc(100% - 64px);
}

.mb-5 {
    margin-bottom: 5px !important;
}

#date-picker-dialog-helper-text {
    display: none;
}

.count-result {
    margin-left: 5px;
    font-size: 15px;
}

.dialog-result-admin {
    margin-bottom: 25px !important;
    padding-bottom: 25px !important;
    border-bottom: 1px solid black;
}

.title-dialog-follow {
    margin-top: 20px;
}

.follow-up-filter {
    margin-left: 1px !important;
}

.table-css {
    border: 1px #000 solid !important;
}

.errorMesseges {
    color: red;
}

.insFile {
    cursor: pointer;
}

.certificates__list-img {
    vertical-align: middle;
}

.certificate__wrapper-img {
    position: relative;
}

.certificate__wrapper-img:hover .certificate__overlay-img {
    visibility: visible;
    display: block;
    opacity: 1;
    -webkit-transform: scale(1) !important;
    -moz-transform: scale(1) !important;
    -o-transform: scale(1) !important;
    -ms-transform: scale(1) !important;
    transform: scale(1) !important;
    cursor: pointer;
}

.language-select {
    display: flex;
    flex-wrap: wrap;
    margin-left: 25px;
}
.language-select div,
.language-select svg {
    color: var(--text-blue) !important;
}
.language-select > div > div::before {
    display: none !important;
}
.certificate__overlay-img {
    background-color: rgba(99, 178, 195, 0.7);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    color: $white;
    opacity: 0;
    display: block;
    -webkit-transform: scale(0.15);
    -moz-transform: scale(0.15);
    -o-transform: scale(0.15);
    -ms-transform: scale(0.15);
    transform: scale(0.15);
    -webkit-transition: all 0.15s ease-out 0s;
    -moz-transition: all 0.15s ease-out 0s;
    -o-transition: all 0.15s ease-out 0s;
    -ms-transition: all 0.15s ease-out 0s;
    transition: all 0.15s ease-out 0s;
}

.certificate__overlay-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -20px;
    margin-top: -25px;
    font-size: 45px !important;
}

.certificate__overlay-img span {
    position: absolute;
    font-size: 15px;
    top: 60%;
    left: 50%;
    margin-left: -20px;
}

.certificates__zoom-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1043;
    cursor: pointer;
    .certificates__zoom-container {
        text-align: center;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        padding: 0 8px;
        box-sizing: border-box;
        .certificates__zoom-button {
            width: 100%;
            text-align: right;
        }
    }
    .mfp-arrow {
        position: absolute !important;
        top: 50%;
    }
    .mfp-arrow-left {
        left: 10px;
    }
    .mfp-arrow-right {
        right: 10px;
    }
    .certificates__zoom-icon-color {
        color: $white;
    }
}

.dialog-fixed-color {
    label.Mui-focused {
        color: #7467ef !important;
    }
    .Mui-focused::after {
        border-color: #7467ef !important;
    }
}

.breadcrumb__home {
    font-size: 0.875rem;
    line-height: 1rem;
    font-weight: 400;
    padding-left: 8px;
}

.breadcrumb__home_title{
    margin-left: 2px;
    padding: 2px;
}

.breadcrumb__title {
    font-size: 1rem;
    line-height: 1rem;
    font-weight: 700;
}

@media screen and(max-width: 425px) {
    .breadcrumb__home_icon{
        width: 25px;
    }
    .breadcrumb__title:last-child{
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}

// home

.bg-certificate-our-teams {
    background-image: url("/assets/images/home/bg-frame.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 30px;
}

.w-100 .MuiInputBase-root {
    width: 100%;
}

.align-items-baseline {
    align-items: baseline;
}

@media screen and (max-width: 450px) {
    .bgr-header {
        height: 250px;
    }
}