.layout2 {
  flex: 1 1 auto;
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  transition: all 0.15s ease;
  .tab-container {
    max-width: 100vw !important;
  }
  .scrollable-content {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    width: 100%;
    overflow-y: auto;
  }
  &.sidenav-close {
    .sidenav {
      left: -#{$sidenav-width};
    }
  }
}
.layout-contained .container {
  max-width: unset;
  margin: 0;
}
