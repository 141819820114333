.btn {
  display: inline-block !important;
  font-weight: 400 !important;
  text-align: center !important;
  white-space: nowrap !important;
  vertical-align: middle !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  border: 1px solid transparent !important;
  padding: 6px 16px !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  border-radius: 20px !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out !important;
}

.btn-primary {
  color: $white !important;
  background-color: #2196f3 !important;
  border-color: #2196f3 !important;
}

.btn-primary-d {
  color: $white !important;
  background-color: #2a80c8 !important;
  border-color: #2a80c8 !important;
}

.btn-secondary {
  color: $white !important;
  background-color: #00bfa5 !important;
  border-color: #00bfa5 !important;
}

.btn-success {
  color: $white !important;
  background-color: #3f923f !important;
  border-color: #3f923f !important;
}

.btn-danger {
  color: $white !important;
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
}

.btn-warning {
  color: $white !important;
  background-color: #ffbe50 !important;
  border-color: #ffbe50 !important;
}

.btn-info {
  color: $white !important;
  background-color: #17a2b8 !important;
  border-color: #17a2b8 !important;
}


.btn-dark {
  color: $white !important;
  background-color: #343a40 !important;
  border-color: #343a40 !important;
}

.btn-danger:hover {
  color: $white !important;
  background-color: #c82333 !important;
  border-color: #bd2130 !important;
}

.btn-warning:hover {
  color: $white !important;
  background-color: #e0a800 !important;
  border-color: #d39e00 !important;
}

.btn-info:hover {
  color: $white !important;
  background-color: #138496 !important;
  border-color: #117a8b !important;
}

.btn-primary:hover {
  color: $white !important;
  background-color: #1565c0 !important;
  border-color: #1565c0 !important;
}

.btn-primary-d:hover {
  color: $white !important;
  background-color: #1f5f94;
  border-color: #1f5f94;
}

.btn-secondary:hover {
  color: $white !important;
  background-color: #00796b !important;
  border-color: #00796b !important;
}

.btn-success:hover {
  color: $white !important;
  background-color: #218838 !important;
  border-color: #1e7e34 !important;
}

.btn-light:hover {
  color: #212529 !important;
  background-color: #e2e6ea !important;
  border-color: #dae0e5 !important;
}

.btn-dark:hover {
  color: $white !important;
  background-color: #23272b !important;
  border-color: #1d2124 !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.MuiTypography-colorPrimary {
  color: #2196f3 !important;
}

.MuiIcon-colorPrimary {
  color: #2196f3 !important;
}

.text-primary-d2 {
  color: #2564a9 !important;
}

.bgc-primary-d1 {
  background-color: #2a80c8 !important;
}

.brc-primary-l2 {
  border-color: #d2e6f6 !important;
}

.bgc-green-d1 {
  background-color: #36ad82 !important;
}

.bgc-primary {
  background-color: #2c87d3 !important;
}

.brc-green-l2 {
  border-color: #d8f0e8 !important;
}

.bgc-warning-d1 {
  background-color: #e19e2e !important;
}

.text-orange-l2 {
  color: #ffc277 !important;
}

.brc-warning-l2 {
  border-color: #fbeacd !important;
}

.bgc-danger-tp1 {
  background-color: rgba(218, 78, 53, 0.925) !important;
}

// .dialog-container .MuiDialog-paperScrollPaper {
//   overflow: hidden !important;
// }

// .dialog-body {
//   overflow: auto;
//   height: 100%;
//   max-height: 500px;
// }

// .o-hidden {
//   overflow: hidden !important;
// }

.dialog-footer {
  padding: 16px 24px !important;
  border-top: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.text-white {
  color: $white !important;
}

.MuiIcon-colorDisabled {
  color: $white !important;
}
.MuiIcon-colorError {
  color: rgb(255, 87, 34) !important;
}

.MuiPickersToolbar-toolbar {
  background-color: #2196f3 !important;
}

.MuiPickersDay-daySelected:hover {
  background-color: #2a80c8 !important;
}

.MuiPickersDay-daySelected {
  background-color: #2a80c8 !important;
}

.MuiButton-textPrimary {
  color: #2a80c8 !important;
}

.input-description {
  font-style: italic !important;
  color: red !important;
}

.sidenav__user .username-photo img {
  width: 80px !important;
  height: 80px !important;
  box-shadow: 0 2px 20px -5px #2196f3 !important;
}

.sidenav__user .username-photo .username {
  font-weight: 600 !important;
  color: #ffc277 !important;
  font-size: 0.95em !important;
}

.highcharts-container {
  /* border-radius: 8px!important; */
  /* box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 10%); */
  border-radius: 8px;
  border-width: 0;
  background-color: $white;
  position: relative;
}

.MuiPaginationItem-textPrimary.Mui-selected {
  background-color: #2196f3 !important;
}

.MuiPaginationItem-textPrimary.Mui-selected:hover {
  background-color: #1565c0 !important;
}
.input-popup-container {
  position: relative !important;
}

.input-popup-container button {
  min-width: 100px !important;
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
}

.content-index {
  // height: 400px;
  padding: 10px 30px;
  background-size: 100% 400px;
  background-repeat: no-repeat;
  background-image: linear-gradient(-45deg, #2196f3 0%, #2196f3 33%, #00bfa5 100%);
  background-attachment: fixed;
  .index-breadcrumb {
    margin-bottom: 30px;
  }
  .index-card.tab {
    background: $white;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 8px;
    .tab-item {
      background: $white !important;
      border-radius: 8px !important;
      .tab-container {
        max-width: 100vw !important;
      }
    }
  }
}

.dialog-header {
  border-bottom: 1px solid #e3f2fd !important;
}

.footer {
  // z-index: 1 !important;
  // margin-top: 24px;
}

.MuiButton-startIcon {
  @media screen and (max-width: 767px) {
    margin: 0 !important;
  }
}
.filter-card {
  background: $white;
  border-radius: 8px;
  padding: 24px;
}
